//router/index.js
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MainLayout from '@/layouts/Main.vue'
import store from "@/store";
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import online from "./online"
import menu from "./menu"

Vue.use(VueRouter)

const routes = [{
    path: '',
    name: 'Main',
    component: MainLayout,
    children: [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    // =============================================================================
    // SYSTEM
    // =============================================================================
    {
        name: "System",
        path: "/system",
        component: () => import(/* webpackChunkName: "system-basic" */ "../views/system/Index.vue"),
        children: [
            {
                name: "SystemCompany",
                path: "companies",
                component: () =>
                    import(/* webpackChunkName: "system-basic" */"../views/system/SystemCompany.vue"),
                meta: {
                },
            },
            {
                name: "SystemCompanyUser",
                path: "companies/:systemCompanyId?/users",
                component: () =>
                    import(/* webpackChunkName: "system-basic" */"../views/company/CompanyUser.vue"),
                meta: {
                },
            },
            {
                name: "SystemCompanyMenu",
                path: "companies/:systemCompanyId?/menu",
                component: () =>
                    import(/* webpackChunkName: "system-basic" */"../views/system/SystemCompanyMenu.vue"),
                meta: {
                },
            },

            {
                name: "SystemCompanyAccounts",
                path: "companies/:systemCompanyId/accounts",
                component: () =>
                    import(/* webpackChunkName: "system-basic" */"../views/system/SystemCompanyAccounts.vue"),
                meta: {
                },
                children: [
                    {
                        name: "SystemCompanyAccountsSubscriptions",
                        path: ":accountId?/subscriptions",
                        component: () =>
                            import(/* webpackChunkName: "system-basic" */"../views/system/accounts/AccountSubscriptions.vue"),
                        meta: {
                        },
                        children: [
                            {
                                name: "SystemCompanyAccountsSubscriptionsLicenses",
                                path: ":subscriptionId/licenses",
                                component: () =>
                                    import(/* webpackChunkName: "system-basic" */"../views/system/accounts/SubscriptionPlansAndLicenses.vue"),
                                meta: {
                                },
                            }
                        ]
                    },
                ]
            },
            {
                name: "SystemUser",
                path: "users",
                component: () =>
                    import(/* webpackChunkName: "system-basic" */"../views/system/SystemUser.vue"),
                meta: {
                },
            },
            {
                name: "SystemUserAccounts",
                path: "users/:systemUserId?/accounts",
                component: () =>
                    import(/* webpackChunkName: "system-basic" */"../views/system/SystemUserAccounts.vue"),
                meta: {
                },
                children: [
                    {
                        name: "SystemUserAccountsSubscriptions",
                        path: ":accountId?/subscriptions",
                        component: () =>
                            import(/* webpackChunkName: "system-basic" */"../views/system/accounts/AccountSubscriptions.vue"),
                        meta: {
                        },
                        children: [
                            {
                                name: "SystemUserAccountsSubscriptionsLicenses",
                                path: ":subscriptionId/licenses",
                                component: () =>
                                    import(/* webpackChunkName: "system-basic" */"../views/system/accounts/SubscriptionPlansAndLicenses.vue"),
                                meta: {
                                },
                            }
                        ]
                    },
                ]
            },
            {
                name: "SystemLicenses",
                path: "/SystemLicenses",
                component: () =>
                    import(/* webpackChunkName: "system-basic" */"../views/system/SystemLicenses.vue"),
                meta: {
                },
            },
        ]
    },

    {
        name: "RoleFunctions",
        path: "/roleFunctions",
        component: () =>
            import(/* webpackChunkName: "system-basic" */"../views/RoleFunctions.vue"),
        meta: {
        },
    },

    {
        name: "Subscriptions",
        path: "/subscriptions",
        component: () =>
            import(/* webpackChunkName: "system-basic" */"../views/subscriptions/Index.vue"),
        meta: {
        }
    },

    // SubscriptionsManagement

    // =============================================================================
    // WEBIMSYNC
    // =============================================================================
    {
        name: "Servers",
        path: "/Servers",
        component: () =>
            import(/* webpackChunkName: "webim-sync" */"../views/webimSync/Servers.vue"),
        meta: {
        }
    },
    {
        name: "ServerSetting",
        path: "/Servers/:serverId?/setting",
        component: () =>
            import(/* webpackChunkName: "webim-sync" */"../views/webimSync/ServerSetting.vue"),
        meta: {
        }
    },
    {
        name: "SystemVersions",
        path: "/SystemVersions",
        component: () =>
            import(/* webpackChunkName: "webim-sync" */"../views/webimSync/SystemVersions.vue"),
        meta: {
        }
    },
        // =============================================================================
        // ONLINE
        // =============================================================================

        online,

        // =============================================================================
        // MENU
        // =============================================================================

        menu,

    // =============================================================================
    // MENU
    // =============================================================================

    {
        name: "QuotaManage",
        path: "/QuotaManage",
        component: () =>
            import(/* webpackChunkName: "quota-manage" */"../views/cost/QuotaManage.vue"),
        meta: {
        }
    }
    ]
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router