<template>
  <v-app id="app">
    <router-view />
    <sb-snackbar />
    <sb-dialog />
  </v-app>
</template>

<script>
export default {

  async created(){
      await this.$store.dispatch('getSystemTime', this.$API)
  }
}


</script>

<style lang='scss' >
//global styles
  @import "./assets/scss/global/main.scss";
</style>
