<template>
<v-dialog v-model='dialogComputed' width='450px' persistent>
    <v-card flat :loading='loading'>
        <v-card-title>
            {{$t('System.SystemVersionsPublishDialog.publish')}}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class='pt-4'>
            {{$t("System.SystemVersionsPublishDialog.please_confirm_to_publish", { typeName:cloneDataItem.type.name, version: cloneDataItem.version  })}} ?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed @click='cancel'>{{$t("System.SystemVersionsPublishDialog.cancel")}}</v-btn>
            <v-btn depressed color="primary" @click="onPublish" :loading='loading' :disabled='loading'>{{$t("System.SystemVersionsPublishDialog.confirm")}}</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name:"SystemVersionsPublishDialog",
    props:{
        dialog:Boolean,
        dataItem:Object
    },
    data(){
        return {
            loading:false,
            cloneDataItem:{
                id:0,
                version:"0.0.0.0",
                description:"",
                type:"MainSystem"
            }
        }
    },
    computed:{
        dialogComputed:{
            get(){
                return this.dialog
            },
            set(dialog){
                this.$emit("update:dialog", dialog)
            }
        }
    },
    methods:{
        onPublish() {
            this.loading = true;
            this.$API.api.bim.systemVersions
            .publish(this.cloneDataItem.id)
            .then(() => {
                let alert = {
                    type: "success",
                    message: this.$t("System.SystemVersionsPublishDialog.publish_successfully"),
                    value: true,
                };
                this.SET_ALERT(alert);
                this.dialogComputed = false;
                
                this.$emit("publish:success")
            })
            .catch(() => {
                let alert = {
                    type: "error",
                    message: this.$t("System.SystemVersionsPublishDialog.publish_failed"),
                    value: true,
                };
                this.SET_ALERT(alert);
            })
            .finally(() => {
                this.loading = false;
            });
		},
        cancel(){
            this.dialogComputed = false;
        }
    },
    watch:{
        dialog(dialog){
            if(dialog){
                this.cloneDataItem = JSON.parse(JSON.stringify(this.dataItem))
            }else{
                this.cloneDataItem = {
                    id:0,
                    version:"0.0.0.0",
                    description:"",
                    type:"MainSystem"
                };
            }
        }
    }
}
</script>

<style scoped>
</style>
