<template>
    <v-dialog v-model='value' max-width="350px" persistent scrollable>
        <v-card>
            <v-card-title>
                刪除選項
            </v-card-title>
            <v-card-text>
                請問確定要刪除 {{editItem? $t(editItem.text):''}} ?
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="darken-1" text @click="close">{{ $t("general.cancel") }}</v-btn>
                <v-btn
                color="error"
                @click="deleteItem()"
                :disabled="this.loading"
                >刪除</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name:'MenuItemDeleteDialog',
    props:{
        editItem:{
            type:Object
        }
    },
    data(){
        return {
            value:false,
            loading:false
        }
    },
    methods:{
        toggle(){
            this.value=!this.value;
        },
        close(){
            this.value=false;
        },
        async deleteItem(){
            await this.emit('delete', this.editItem)
            this.close()
        },
        emit (eventName, value) {
            return new Promise((resolve) => {
                this.$emit(eventName, value)
                this.$nextTick(resolve)
            })
        }
    }
}
</script>

<style>

</style>