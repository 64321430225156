<template>
  <v-card flat :loading="loading">
    <div v-if="loading" class="k-loading-mask">
      <span class="k-loading-text"></span>
      <div class="k-loading-image" />
      <div class="k-loading-color" />
    </div>
    <localization-provider :language="$i18n.locale">
      <intl-provider :locale="$i18n.locale">
        <grid
          :data-items="dataItems"
          :skip="dataState.skip"
          :take="dataState.take"
          :filter="dataState.filter"
          :sort="dataState.sort"
          :resizable="resizable"
          :sortable="sortalbe"
          :pageable="pageable"
          :column-menu="columnMenu"
          :columns="columns"
          style="height: 100%"
          @datastatechange="dataStateChange"
          @sendFilter="filterDataStateChange"
        >
          <template #grid_type="{ props }">
            <td>
              {{ props.dataItem.type.name }}
            </td>
          </template>
          <template #grid_createdUser="{ props }">
            <td>
              <sb-ui-systemUser :userInfo="props.dataItem.createdUser" />
            </td>
          </template>

          <template #grid_createdDate="{ props }">
            <td>
              <sb-ui-time-cell :time="props.dataItem.createdDate"></sb-ui-time-cell>
            </td>
          </template>
          <template #grid_command="{ props }">
            <td :class="props.className">
              <v-btn
                v-if="!props.dataItem.isApplied"
                depressed
                color="primary"
                small
                class="mr-2"
                @click="onPublish(props.dataItem)"
              >
                <v-icon left x-small>mdi-publish</v-icon>
                {{ $t("System.SystemVersionsGrid.publish") }}
              </v-btn>
              <v-btn
                :key="props.dataItem.id + '_download'"
                :loading="props.dataItem.isDownloading"
                depressed
                color="primary"
                small
                class="mr-2"
                @click="onDownload(props.dataItem)"
              >
                <v-icon left x-small>mdi-download-box-outline</v-icon>
                {{ $t("System.SystemVersionsGrid.download") }}

                <template #loader>
                  {{ props.dataItem.downloadProgress }} %
                  <v-progress-linear
                    absolute
                    bottom
                    :value="props.dataItem.downloadProgress"
                    color="info lighten-2"
                  ></v-progress-linear>
                </template>
              </v-btn>
              <v-btn depressed color="primary" small class="mr-2" @click="onEdit(props.dataItem)">
                <v-icon left x-small>mdi-cog</v-icon>
                {{ $t("System.SystemVersionsGrid.edit") }}
              </v-btn>

              <v-btn depressed color="error" small @click="onDelete(props.dataItem)">
                <v-icon left x-small>mdi-trash-can</v-icon>
                {{ $t("System.SystemVersionsGrid.delete") }}
              </v-btn>
            </td>
          </template>
          <template #grid_description="{ props }">
            <td>
              <div style="white-space: pre-line; margin-top: 0rem">
                {{ props.dataItem.description }}
              </div>
            </td>
          </template>
          <grid-no-records>
            {{ $t("general.no_records") }}
          </grid-no-records>
        </grid>
      </intl-provider>
    </localization-provider>
  </v-card>
</template>

<script>
import { toDataSourceRequestString } from "@progress/kendo-data-query"
import KendoColumnHeader from "./KendoColumnHeader"

export default {
  name: "SystemVersionsGrid",
  components: {},
  props: {
    searchText: String
  },
  computed: {
    bimBaseUrl() {
      return this.$API.config.bimBaseUrl
    },
    authorizationHeaders() {
      return {
        Authorization: `bearer ${this.$API.config.token}`
      }
    },
    userId() {
      return this.$route.params.userId
    },
    columns() {
      return [
        {
          field: "id",
          title: this.$t("System.SystemVersionsGrid.id"),
          filter: "numeric",
          width: 100
        },
        {
          field: "type.id",
          cell: "grid_type",
          title: this.$t("System.SystemVersionsGrid.type"),
          sortable: false,
          width: 350,
          headerCell: KendoColumnHeader,
          headerOptions: {
            // header設定
            hideSorting: true, // 是否隱藏排序控件
            hideLocking: true // 是否隱藏鎖定控件
          },
          values: this.typeItems,
          dataField: "name",
          dataFilterField: "id",
          operator: "eq",
          placeholder: "",
          columnMenu: false
        },
        {
          field: "version",
          title: this.$t("System.SystemVersionsGrid.version"),
          width: 120
        },

        {
          field: "isApplied",
          title: this.$t("System.SystemVersionsGrid.is_applied"),
          sortable: false,
          type: "boolean",
          filter: "boolean",
          width: 120
        },
        {
          field: "description",
          title: this.$t("System.SystemVersionsGrid.description"),
          cell: "grid_description"
        },
        {
          field: "createdDate",
          title: this.$t("System.SystemVersionsGrid.created_date"),
          type: "date",
          filter: "date",
          cell: "grid_createdDate",
          width: 150,
          columnMenu: false,
          sortable: false
        },
        {
          field: "createdUser.name",
          title: this.$t("System.SystemVersionsGrid.created_user_name"),
          cell: "grid_createdUser",
          width: 160
        },
        {
          cell: "grid_command",
          sortable: false,
          columnMenu: false,
          width: 375,
          locked: true
        }
      ]
    }
  },
  data() {
    return {
      dataState: {
        skip: 0,
        take: 12,
        sort: [{ field: "id", dir: "desc" }],
        filter: null
      },
      sortalbe: true,
      resizable: true,
      pageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: true,
        previousNext: true
      },
      columnMenu: true,
      loading: false,
      dataItems: null,
      downloadProgress: 0,
      typeItems: []
    }
  },
  async mounted() {
    this.getData()
    this.typeItems = await this.getSystemVersionTypes()
  },
  methods: {
    async getData() {
      this.loading = true
      const queryStr = toDataSourceRequestString(this.dataState) // Serialize the state
      this.dataItems = await this.$API.api.bim.systemVersions
        .get(queryStr)
        .then((res) => res.data)
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getSystemVersionTypes() {
      let results = []
      try {
        results = await this.$API.api.bim.systemVersions.types().then((res) => res.data)
      } catch (err) {
        console.error(err)
      }
      return results
    },
    dataStateChange(event) {
      this.createAppState(event.data)
    },
    filterDataStateChange(filter) {
      this.dataState.filter = filter
      this.getData()
    },
    createAppState(dataState) {
      this.dataState = dataState
      this.getData()
    },
    onPublish(dataItem) {
      this.$emit("publish:dataItem", dataItem)
    },
    async onDownload(dataItem) {
      try {
        this.$set(dataItem, "isDownloading", true)
        const sasUrl = await this.getDownloadSasUrl(dataItem)
        this.downloadBlob(sasUrl)
      } catch (err) {
        console.error(err)
        let alert = {
          type: "error",
          message: this.$t("System.SystemVersionsGrid.download_failed"),
          value: true
        }
        this.SET_ALERT(alert)
      } finally {
        this.$set(dataItem, "isDownloading", false)
        this.parentLoading = false
        this.downloadProgress = 0
      }
    },
    getDownloadSasUrl(dataItem) {
      return this.$API.api.bim.V1_4.systemVersions.download(dataItem.id, null).then((response) => response.data)
    },
    downloadBlob(sasUrl) {
      const link = document.createElement("a")
      link.href = sasUrl
      document.body.appendChild(link)
      link.click()
    },
    onEdit(dataItem) {
      this.$emit("edit:dataItem", dataItem)
    },
    onDelete(dataItem) {
      this.$emit("delete:dataItem", dataItem)
    }
  },
  watch: {
    searchText() {
      let searchFilters = this.searchText
        ? [
            {
              logic: "and",
              filters: [
                {
                  field: "version",
                  operator: "contains",
                  value: this.searchText
                }
              ]
            },
            {
              logic: "and",
              filters: [
                {
                  field: "description",
                  operator: "contains",
                  value: this.searchText
                }
              ]
            },
            {
              logic: "and",
              filters: [
                {
                  field: "createdUser.name",
                  operator: "contains",
                  value: this.searchText
                }
              ]
            }
          ]
        : null

      this.dataState.filter = searchFilters
        ? {
            filters: searchFilters,
            logic: "or"
          }
        : null

      this.createAppState(this.dataState)
    }
  }
}
</script>
