<i18n lang="json">
{
  "zh-Hant":{
    "name":"名稱",
    "startedDate": "建立日期",
    "add":"新增",
    "cancel":"取消",
    "save":"儲存",
    "ok":"確認",
    "licenses": "執照",
    "plan":"方案",
    "amount":"數量",
    "subscriptions":"訂閱",
    "edit":"編輯",
    "endDate":"結束日期",
    "isValid":"有效",
    "createdUser": "建立者",
    "subscriptions_and_plans":"訂閱方案",
    "used_amount":"使用情形",
    "available":"可使用",
    "assigned":"已指派",
    "total":"所有",
    "daysRemaining": "剩餘天數",
    "expired":"已過期"

  },
  "en":{
    "name":"name",
    "startedDate": "Created",
    "add":"Add",
    "cancel":"Cancel",
    "save":"Save",
    "ok":"OK",
    "licenses": "Licenses",
    "plan":"plan",
    "amount":"amount",
    "subscriptions":"Subscriptions",
    "edit":"Edit",
    "endDate":"End",
    "isValid":"Valid",
    "createdUser": "Created By",
    "subscriptions_and_plans":"Subscribed Plans",
    "used_amount":"used condition",
    "available":"available",
    "assigned":"assigned",
    "total":"total",
    "daysRemaining": "Days Remaining",
    "expired":"Expired"
  }
}
</i18n>

<template>
<v-card outlined>
  <v-data-table
    calculate-widths
    :headers="headers"
    :items="items"
    :items-per-page="itemsPerPage"
    :loading='loading'
    height="675"
    :itemClass='itemClass'
    @click:row='onSelect'
  >
  <template #top>
    <v-toolbar
        flat
        dense
      >
        <v-toolbar-title>{{ $t("subscriptions_and_plans")}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              {{$t("add")}}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span>{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-autocomplete
                      v-model="editedItem.planId"
                      prepend-icon="mdi-clipboard-list-outline"
                      :label="$t('plan')"
                      outlined
                      hide-details
                      :items="plans"
                      item-value='id'
                      item-text='name'
                      dense
                      @change='onPlanChange'
                    >
                    <template #append>
                      <v-tooltip :disabled='isPlanLicensesLoading' top>
                        <template #activator="{on}">
                          <v-icon v-show='licenses.length' v-on='on'>
                            {{isPlanLicensesLoading?"mdi-loading mdi-spin": "mdi-information-outline"}}
                          </v-icon>
                        </template>
                          {{licensesInfo}}
                      </v-tooltip>
                    </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model.number="editedItem.amount"
                      prepend-icon="mdi-numeric"
                      :label="$t('amount')"
                      type="number"
                      outlined
                      hide-details
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                  >
                    <v-menu
                      ref="menu"
                      v-model="dataPickerMenu"
                      :close-on-content-click="false"
                      :return-value="editedItem.startDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      @input="onDataPickerMenuInput"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          prepend-icon="mdi-calendar"
                          :label="'起始時間'"
                          :value="startDateTextfieldDisplay"
                          readonly
                          v-bind="attrs"
                          outlined
                          dense
                          :rules="[
                            (v) =>
                              true ||
                              '請選擇開始時間',
                          ]"
                          v-on="on"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :value="startDateDisplay"
                        no-title
                        scrollable
                        color="primary"
                        @input="updateStartDate"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="onClose"
              >
                {{$t('cancel')}}
              </v-btn>
              <v-btn
                color="primary"
                @click="onSave"
              >
                {{$t('save')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="500px">
          <v-card>
            <v-card-title>您確定要刪除  <b class='px-1'>{{editedItem.id}}</b>  嗎?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="onCloseDeleteDialog">{{$t('cancel')}}</v-btn>
              <v-btn color="blue darken-1" text @click="onConfirmDelete">{{$t('ok')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    <v-divider></v-divider>
  </template>
  <template #item.createdUser="{ item }">
    <sb-ui-systemUser :user-info="item.createdUser"></sb-ui-systemUser>
  </template>
  <template #item.startedDate="{ item }">
    <sb-ui-time-cell :time="item.startedDate" :isFromNow="false" format="yyyy-MM-DD" />
  </template>
  <template #item.endDate="{ item }">
    <sb-ui-time-cell :time="item.endDate" :isFromNow="false" format="yyyy-MM-DD" />
  </template>
    <template #item.daysRemaining="{item}">
      <span>{{getDaysRemaining(item.endDate) ? getDaysRemaining(item.endDate) : $t('expired')}}</span>
    </template>
  <template #item.isValid="{ item }">
    <v-icon :color="item.isValid?'success':'error'">
      {{item.isValid?`mdi-check-circle`:`mdi-close-circle`}}
    </v-icon>
  </template>
  <template #item.usedAmount="{ item }">
    <v-row no-gutters>
    {{$t("assigned")}} {{item.usedAmount}}, {{$t("total")}} {{item.amount}}
    </v-row>
    <v-progress-linear :value="item.usedAmount*100/item.amount"></v-progress-linear>
  </template>
  <template v-slot:item.actions="{ item }">
    <!-- <v-btn small outlined class='mr-3'>
      <v-icon left>
        mdi-cog-outline
      </v-icon>
      管理使用者
    </v-btn> -->
    <v-icon
      class="mr-2"
      disabled
      @click.stop="onClickEdit(item)"
    >
      mdi-pencil
    </v-icon>
    <v-icon
      @click.stop="onClickDelete(item)"
      disabled
    >
      mdi-delete
    </v-icon>
  </template>
  </v-data-table>
</v-card>

</template>

<script>
export default {
  name: "SubscriptionsList",
  props: {
    accountId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      items: [],
      dialog: false,
      deleteDialog: false,
      editedItem: {
        planId: null,
        amount: 1,
        startDate: new Date().toISOString()
      },
      defaultItem: {
        planId: null,
        amount: 1,
        startDate: new Date().toISOString()
      },
      editedIndex: -1,
      itemsPerPage: 10,
      plan: null,
      plans: [],
      licenses: [],
      isPlanLicensesLoading: false,
      dataPickerMenu: false,
      subscriptionId: null
    };
  },

  computed: {
    headers() {
      return [
        // {
        //   text: this.$t("billId"),
        //   align: "start",
        //   sortable: false,
        //   value: "billId"
        // },
        // {
        //   text: this.$t("planId"),
        //   align: "start",
        //   sortable: false,
        //   value: "plan.id",
        //   width: 180
        // },
        {
          text: this.$t("plan"),
          align: "start",
          sortable: false,
          value: "plan.name",
          width: 180
        },
        // {
        //   text: this.$t("amount"),
        //   align: "end",
        //   sortable: false,
        //   value: "amount",
        //   width: 120
        // },
        {
          text: this.$t("used_amount"),
          align: "end",
          sortable: false,
          value: "usedAmount",
          width: 300
        },
        {
          text: this.$t("startedDate"),
          align: "end",
          sortable: false,
          value: "startedDate",
          width: 150
        },
        {
          text: this.$t("endDate"),
          align: "end",
          sortable: false,
          value: "endDate"
        },
        {
          text: this.$t("daysRemaining"),
          align: "end",
          sortable: false,
          value: "daysRemaining"
        },
        {
          text: this.$t("isValid"),
          align: "end",
          sortable: false,
          value: "isValid"
        },
        {
          text: this.$t("createdUser"),
          align: "end",
          sortable: false,
          value: "createdUser"
        },
        // {
        //   text: this.$t("companyId"),
        //   align: "end",
        //   sortable: false,
        //   value: "companyId",
        //   width: 300
        // },
        {
          align: "end",
          sortable: false,
          value: "actions",
          width: 300
        }
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t("add") : this.$t("edit");
    },
    licensesInfo() {
      return `${this.$t("licenses")} : ${this.licenses
        .map((license) => license.name)
        .join(", ")}`;
    },
    startDateDisplay() {
      return this.startDateString?new Date(this.startDateString)?.toISOString()?.split("T")[0]:"";
    },
    startDateTextfieldDisplay() {
        return this.startDateString.replace("24:00:00", "00:00:00")
    },
    startDateString() {
      const locale = navigator.language || navigator.userLanguage
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      return this.editedItem.startDate
        ? new Date(this.editedItem.startDate.toLocaleString()).toLocaleString(locale, { timeZone, hour12: false } )
        : "";
    }
  },
  watch: {
    dialog: {
      handler(val) {
        val || this.onClose();
      }
    },
    deleteDialog: {
      handler(val) {
        val || this.onCloseDeleteDialog();
      }
    }
  },
  async created() {
    this.items = await this.getAccountSubscriptions();
    this.plans = await this.getPlans();
  },
  methods: {
    onSelect(subscription) {
      this.subscriptionId = subscription.id;
      this.$emit("select", subscription);
    },
    itemClass(item) {
      return `${
        item.id === this.$route.params.subscriptionId
          ? "grey"
          : "cursor-pointer"
      }`;
    },
    async onClose() {
      this.dialog = false;
      await this.$nextTick();
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    async onSave() {
      // update
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);

        // create
      } else {
        console.log(this.editedItem);
        // this.items.push(this.editedItem);
        const params = { accountId: this.accountId, ...this.editedItem };
        await this.createAccountSubscription(params);
        this.items = await this.getAccountSubscriptions();
      }
      this.onClose();
    },
    onClickEdit(editedItem) {
      this.editedIndex = this.items.findIndex(
        (item) => item.id === editedItem.id
      );
      this.editedItem = Object.assign({}, editedItem);
      this.dialog = true;
    },

    onClickDelete(editedItem) {
      this.editedIndex = this.items.findIndex(
        (item) => item.id === editedItem.id
      );
      this.editedItem = Object.assign({}, editedItem);
      this.deleteDialog = true;
    },
    async onCloseDeleteDialog() {
      this.deleteDialog = false;
      await this.$nextTick();
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    onConfirmDelete() {
      this.items.splice(this.editedIndex, 1);
      this.deleteDialog = false;
    },
    async getAccountSubscriptions() {
      let results = [];
      try {
        if (this.accountId) {
          this.loading = true;
          const response = await this.$API.api.main.accountsSubscriptions.get(
            this.accountId
          );
          results = response.data;
        }
        // this.items.push(this.editedItem);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
      return results;
    },
    async getPlans() {
      let results = [];
      try {
        const response = await this.$API.api.main.plans.get();
        results = response.data;
      } catch (error) {
        console.error(error);
      }
      return results;
    },
    async onPlanChange(planId) {
      this.isPlanLicensesLoading = true;
      this.licenses = await this.getPlanLicenses(planId);
      this.isPlanLicensesLoading = false;
    },
    async getPlanLicenses(planId) {
      let results = [];
      try {
        const response = await this.$API.api.main.plansLicenses.get(planId);
        results = response.data;
      } catch (error) {
        console.error(error);
      }
      return results;
    },
    onDataPickerMenuInput(isShowing) {
      if (!isShowing) {
        this.$refs.menu.save(this.editedItem.startDate);
      }
    },
    async createAccountSubscription({ accountId, planId, amount, startDate }) {
      let results = null;
      console.log("accountId", accountId);

      try {
        const response = await this.$API.api.main.accountsSubscriptions.create(
          accountId,
          planId,
          amount,
          startDate
        );
        results = response.data;
      } catch (error) {
        console.error(error);
      }
      return results;
    },
    updateStartDate(startDate) {
      let date = new Date(startDate)
      const d = new Date();
      let diff = (d.getTimezoneOffset() * 60 * 1000)
      date.setTime( date.getTime() + diff);
      this.editedItem.startDate = date.toISOString()
    },
    getDaysRemaining(endTimeString){
      const endDate = new Date(endTimeString)
      const now = new Date()
      const diff = endDate - now
      // today
      if(diff < 0){
        return 0
      }
      const millisecondsInADay = 24 * 60 * 60 * 1000;
      const days = Math.floor(diff / millisecondsInADay);
      return days;
    }
  }
};
</script>

<style lang='scss' scoped >
::v-deep {
  // .v-data-table__wrapper {
  //   // height: calc(100% - 60px - 48px);
  // }

  .cursor-pointer {
    cursor: pointer;
  }
}
</style>
