<i18n lang="json">
{
  "zh-Hant":{
    "name":"名稱",
    "startedDate": "建立日期",
    "add":"新增",
    "cancel":"取消",
    "save":"儲存",
    "ok":"確認",
    "licenses": "執照",
    "plan":"方案",
    "subscriptions":"訂閱",
    "isActive": "啟用",
    "edit":"編輯",
    "endDate":"結束日期",
    "isValid":"有效",
    "createdUser": "建立者",
    "subscriptions_and_plans":"訂閱方案",
    "used_amount":"使用情形",
    "available":"可使用",
    "assigned":"已指派",
    "assignable":"可指派",
    "amount":"套數",
    "companyName":"公司名稱",
    "planName":"方案"
    
  },
  "en":{
    "name":"name",
    "startedDate": "Created",
    "add":"Add",
    "cancel":"Cancel",
    "save":"Save",
    "ok":"OK",
    "licenses": "Licenses",
    "plan":"plan",
    "subscriptions":"Subscriptions",
    "isActive": "isActive",
    "edit":"Edit",
    "endDate":"End",
    "isValid":"Valid",
    "createdUser": "Created By",
    "subscriptions_and_plans":"Subscribed Plans",
    "used_amount":"used condition",
    "available":"available",
    "assigned":"assigned",
    "assignable":"assignable",
    "amount":"Suit",
    "companyName":"company",
    "planName":"plan"
  }
}
</i18n>

<template>
  <v-card outlined>
    <v-card-title>
      <v-icon left>
        mdi-cash-multiple
      </v-icon>
      訂閱帳戶
      <v-spacer></v-spacer>
      <filter-search-bar
        :filter.sync="dataState.filter"
        :fields="['companyName', 'planName']"
        @search="dataState.skip = 0;getData();"
      ></filter-search-bar>
    </v-card-title>
    <div v-if="loading" class="k-loading-mask">
      <span class="k-loading-text"></span>
      <div class="k-loading-image" />
      <div class="k-loading-color" />
    </div>
    <localization-provider :language="$i18n.locale">
      <intl-provider :locale="$i18n.locale">
        <Grid
          :data-items="subscriptions"
          :columns="columns"
          :skip="dataState.skip"
          :take="dataState.take"
          :filter="dataState.filter"
          :sort="dataState.sort"
          :sortable="sortable"
          :pageable="pageable"
          :column-menu="columnMenu"
          @datastatechange="dataStateChange"
        >
        <template #item_startedDate="{ props }">
          <td>
            <sb-ui-time-cell
              :time="props.dataItem.startedDate"
            ></sb-ui-time-cell>
          </td>
        </template>
        <template #item_endDate="{ props }">
          <td>
            <sb-ui-time-cell 
              :time="props.dataItem.endDate"
            ></sb-ui-time-cell>
          </td>
        </template>
        <template #item_used="{ props }">
          <td :key='props.dataItem.id+"assignable"'>
            <v-row no-gutters>
              {{$t("assigned")}} {{props.dataItem.used}}, {{$t("assignable")}} {{props.dataItem.assignableAmount}}
            </v-row>
            <v-progress-linear :value="props.dataItem.used*100/props.dataItem.assignableAmount"></v-progress-linear>
          </td>
        </template>
        <template #item_isValid="{ props }">
          <td>
            <v-icon :color="props.dataItem.isValid?'success':'error'">
              {{props.dataItem.isValid?`mdi-check-circle`:`mdi-close-circle`}}
            </v-icon>
          </td>
        </template>

        <template #item_isActive="{ props }">
          <td>
            <v-icon :color="props.dataItem.isActive?'success':'error'">
              {{props.dataItem.isActive?`mdi-check-circle`:`mdi-close-circle`}}
            </v-icon>
          </td>
        </template>
        </Grid>
      </intl-provider>
    </localization-provider>
  </v-card>
</template>

<script>
import { toDataSourceRequestString } from "@progress/kendo-data-query"
import FilterSearchBar from "@/components/FilterSearchBar.vue";

export default {
  components:{
    FilterSearchBar
  },
  data(){
    return{
      subscriptions:null,
      loading: false,
      dataState:{
        skip: 0,
        take: 20,
        sort: null,
        filter: null
      },
      sortable: true,
      pageable: true,
      columnMenu: true,
    }
  },
  computed:{
    columns(){
      return [
        {
          title: this.$t("companyName"),
          field: "companyName"
        },
        {
          title: this.$t("planName"),
          field: "planName"
        },
        {
          title: this.$t("isValid"),
          field: "isValid",
          cell: "item_isValid",
          filter: "boolean"
        },
        {
          title: this.$t("isActive"),
          field: "isActive",
          cell: "item_isActive",
          filter: "boolean"
        },
        {
          title: this.$t("startedDate"),
          field: "startedDate",
          cell: "item_startedDate",
          filter: "date"
        },
        {
          title: this.$t("endDate"),
          field: "endDate",
          cell: "item_endDate",
          filter: "date"
        },
        {
          title: this.$t("used_amount"),
          field: "used",
          cell: "item_used",
          columnMenu:false
        },
        {
          title: this.$t("amount"),
          field: "amount",
          filter: "numeric"
        }
      ]
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    async getData(){
        this.loading = true
        this.subscriptions = await this.loadSubscriptions()
        this.loading = false
    },
    async loadSubscriptions(){
      let results = null 
      const queryStr = toDataSourceRequestString(this.dataState)
      try{
        const response = await this.$API.api.main.V1.subscriptions.getAll(queryStr)
        results = response.data
      }catch(err){
        console.error(err)
      }
      return results
    },
    dataStateChange(event){
      this.createAppState(event.data)
    },
    async createAppState(dataState) {
      this.dataState = dataState
      await this.getData()
    },
  }
}
</script>

<style>

</style>