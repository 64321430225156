<template>
  <td v-if="!dataItem['inEdit']" :class="className">
    <v-tooltip bottom>
      <template v-slot:activator="{on}">
        <v-btn icon color="info" small v-on="on" @click="editHandler" class="mr-1">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>{{$t('general.edit')}}</span>
    </v-tooltip>

    <!-- delete alert dialog -->
    <v-dialog
      v-if="dataItem.command && dataItem.command.removable"
      v-model="dialog.value"
      width="500"
    >
      <template v-slot:activator="{ on:dialog }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on:tooltip}">
            <v-btn icon color="error" small v-on="{...dialog, ...tooltip}">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{$t('general.delete')}}</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title class="headline text-capitalize">{{ $t("general.delete_title") }}</v-card-title>
        <v-card-text
          v-html="
            $t('panorama.delete_confirm', {
              file: `<b class='px-2 text-info'>${dataItem.name}</b>`
            })
          "
        ></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            icon
            text
            class="mr-1"
            @click="dialog.value = false"
          >{{ $t("general.cancel") }}</v-btn>
          <v-btn color="error" text @click="removeHandler">{{ $t("general.accept") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </td>
  <td v-else :class="className">
    <v-tooltip bottom>
      <template v-slot:activator="{on}">
        <v-btn
          icon
          color="primary"
          small
          v-on="on"
          @click="addUpdateHandler"
          class="mr-1"
        >
          <v-icon>{{dataItem.id?`mdi-check`:`mdi-check`}}</v-icon>
        </v-btn>
      </template>
      <span>
        {{
        dataItem.id ? $t("kendo.grid.command.update") : $t("kendo.grid.command.add")
        }}
      </span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{on}">
        <v-btn icon color="error" small v-on="on" @click="cancelDiscardHandler">
          <v-icon>{{dataItem.id?`mdi-cancel`:`mdi-cancel `}}</v-icon>
        </v-btn>
      </template>
      <span>
        {{
        dataItem.id ? $t("kendo.grid.command.cancel") : $t("kendo.grid.command.discard")
        }}
      </span>
    </v-tooltip>
  </td>
</template>

<script>
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  data() {
    return {
      dialog: {
        value: false,
        proccessing: false
      }
    };
  },
  methods: {
    editHandler: function () {
      this.$emit("edit", { dataItem: this.dataItem });
    },
    removeHandler: function () {
      this.dialog.value = false;
      this.$emit("remove", { dataItem: this.dataItem });
    },
    addUpdateHandler: function () {
      this.$emit("save", { dataItem: this.dataItem });
    },
    cancelDiscardHandler: function () {
      this.$emit("cancel", { dataItem: this.dataItem });
    }
  }
};
</script>

<style></style>
