<i18n>
{
  "zh-Hant":{
    "name":"名稱",
    "isEnabled": "使用狀態",
    "add":"新增",
    "cancel":"取消",
    "save":"儲存",
    "ok":"確認"
  },
  "en":{
    "name":"name",
    "isEnabled": "status",
    "add":"Add",
    "cancel":"Cancel",
    "save":"Save",
    "ok":"OK"
  }
}
</i18n>

<template>
  <v-card outlined>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
    >
    <template #top>
      <v-toolbar flat dense>
        <v-icon left>
          mdi-license
        </v-icon>
        <v-toolbar-title>
          {{$t("sidebar.system.licenses")}}
        </v-toolbar-title>
        
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            max-width="500px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              {{$t("add")}}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span >{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    lg='12'
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      outlined
                      v-model="editedItem.name"
                      :label="$t('name')"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.calories"
                      label="Calories"
                    ></v-text-field>
                  </v-col> -->
                  <!-- <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.fat"
                      label="Fat (g)"
                    ></v-text-field>
                  </v-col> -->
                  <!-- <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.carbs"
                      label="Carbs (g)"
                    ></v-text-field>
                  </v-col> -->
                  <!-- <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.protein"
                      label="Protein (g)"
                    ></v-text-field>
                  </v-col> -->
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="onClose"
              >
                {{$t('cancel')}}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="onSave"
              >
                {{$t('save')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="500px">
          <v-card>
            <v-card-title>您確定要刪除  <b class='px-1'>{{editedItem.name}}</b>  嗎?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="onCloseDeleteDialog">{{$t('cancel')}}</v-btn>
              <v-btn color="blue darken-1" text @click="onConfirmDelete">{{$t('ok')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-divider></v-divider>
    </template>
    <template #item.name="{ item }">
        {{item.name}}
    </template>
    <template v-slot:item.actions="{ item }">
    <v-icon
      class="mr-2"
      @click="onClickEdit(item)"
    >
      mdi-pencil
    </v-icon>
    <v-icon
      @click="onClickDelete(item)"
    >
      mdi-delete
    </v-icon>
  </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "LicensesList",
  props: {
    userId: {
      type: String,
      default: null
    },
    companyId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      itemsPerPage: 10,
      items: [
        {
          id: "00000000-0000-0000-0000-000000000001",
          name: "初始用戶名額",
          isEnabled: true
        },
        {
          id: "00000000-0000-0000-0000-000000000003",
          name: "儲存空間容量",
          isEnabled: true
        },
        {
          id: "00000000-0000-0000-0000-000000000005",
          name: "每月提交表單數量",
          isEnabled: true
        },
        {
          id: "00000000-0000-0000-0000-000000000006",
          name: "報表匯出功能",
          isEnabled: true
        },
        {
          id: "00000000-0000-0000-0000-000000000007",
          name: "任務指派功能",
          isEnabled: true
        },
        {
          id: "00000000-0000-0000-0000-000000000008",
          name: "簽核流程",
          isEnabled: true
        },
        {
          id: "00000000-0000-0000-0000-000000000009",
          name: "Open API功能",
          isEnabled: true
        },
        {
          id: "00000000-0000-0000-0000-000000000010",
          name: "自動化流程整合",
          isEnabled: true
        },
        {
          id: "00000000-0000-0000-0000-000000000011",
          name: "SSO整合",
          isEnabled: true
        }
      ],
      dialog: false,
      deleteDialog: false,
      editedItem: {
        id: "",
        name: "",
        isEnabled: true
      },
      defaultItem: {
        id: "",
        name: "",
        isEnabled: true
      },
      editedIndex: -1
    };
  },
  fetch() {
    console.log(this.userId || this.companyId);
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("isEnabled"),
          align: "end",
          sortable: true,
          value: "isEnabled"
        },
        // {
        //   text: this.$t("createdDate"),
        //   align: "end",
        //   sortable: false,
        //   value: "createdDate"
        // }
        // {
        //   text: this.$t('createdDate'),
        //   align: 'end',
        //   sortable: false,
        //   value: 'createdDate',
        // },
        {
          align: "end",
          sortable: false,
          value: "actions",
          width: 100
        }
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? "新增項目" : "編輯項目";
    }
  },
  methods: {
    async onClose() {
      this.dialog = false;
      await this.$nextTick();
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    onSave() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      } else {
        this.items.push(this.editedItem);
      }
      this.onClose();
    },
    onClickEdit(editedItem) {
      this.editedIndex = this.items.findIndex(
        (item) => item.id === editedItem.id
      );
      this.editedItem = Object.assign({}, editedItem);
      this.dialog = true;
    },

    onClickDelete(editedItem) {
      this.editedIndex = this.items.findIndex(
        (item) => item.id === editedItem.id
      );
      this.editedItem = Object.assign({}, editedItem);
      this.deleteDialog = true;
    },
    async onCloseDeleteDialog() {
      this.deleteDialog = false;
      await this.$nextTick();
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    onConfirmDelete() {
      this.items.splice(this.editedIndex, 1);
      this.deleteDialog = false;
    }
  }
};
</script>

<style>
</style>