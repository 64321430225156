<template>
    <div>
        <div v-if='loading' class="k-loading-mask">
                <span class="k-loading-text"></span>
                <div class="k-loading-image" />
                <div class="k-loading-color" />
        </div>
        <localization-provider :language="$i18n.locale">
            <intl-provider :locale="$i18n.locale">
                <grid
                    :data-items="dataItems"
                    :skip="dataState.skip"
                    :take="dataState.take"
                    :filter="dataState.filter"
                    :sort="dataState.sort"
                    :resizable="resizable"
                    :sortable="sortalbe"
                    :pageable="pageable"
                    :column-menu="columnMenu"
                    :columns="columns"
                    style="height:100%;"
                    @datastatechange='dataStateChange'
                >
                    <template #grid_userName='{ props }'>
                        <td>
                            <sb-ui-systemUser :userInfo="{name: props.dataItem.userName}" />
                        </td>
                    </template>

                    <template #grid_connectedDate='{ props }'>
                        <td>
                            <sb-ui-time-cell :time='props.dataItem.connectedDate'></sb-ui-time-cell>
                        </td>
                    </template>            
                    <grid-no-records>
                        {{ $t("general.no_records") }}
                    </grid-no-records>
                </grid>
            </intl-provider>
        </localization-provider>
    </div>
</template>

<script>
import axios from "axios";
import { toDataSourceRequestString } from "@progress/kendo-data-query";

export default {
    name:"OnlineUser",
    computed:{
        mainBaseUrl(){
            return this.$API.config.mainBaseUrl
        },
        authorizationHeaders(){
            return {
                    Authorization: `bearer ${this.$API.config.token}`
            }
        },
        userId(){
            return this.$route.params.userId
        },
        columns(){
            return [
                {
                    field:'origin',
                    title:'網址'
                },
                {
                    field:'connectedDate',
                    cell:'grid_connectedDate',
                    title:'建立連接時間'
                },
                {
                    field:'ipAddress',
                    title:'IP位址'
                }
            ]
        }
    },
    data(){
        return {
            dataState:{
                skip:0,
                take:15,
                sort:null,
                filter:null
            },
            sortalbe:true,
            resizable:false,
            pageable:{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true
            },
            columnMenu:true,
            loading:false,
            dataItems:null
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        async getData(){
            this.loading = true;
            const queryStr = toDataSourceRequestString(this.dataState); // Serialize the state
            this.dataItems = await axios({
                method:'get',
                url: `${this.mainBaseUrl}/OnlineUser/${this.userId}?${queryStr}`,
                headers:this.authorizationHeaders
            })
            .then(res=>{
                //console.log(res)
                return res.data
            })
            .catch(err=>err)
            .finally(()=>{
                this.loading = false;
            })
        },
        dataStateChange(event){
            this.createAppState(event.data);
        },
        createAppState(dataState){
            this.dataState = dataState;
            this.getData()
        }
    }

}
</script>