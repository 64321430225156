<template>
  <v-container class='pa-0' fluid>
    <v-row no-gutters>
      <v-col v-if='!subscriptionId' style='flex-grow: 1;' :cols='subscriptionId?4:12'>
        <subscriptions-list :accountId="accountId" @select='onSelect'></subscriptions-list>
      </v-col>
      <v-col v-else cols='12'>
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SubscriptionsList from "../../../components/subscriptions/List.vue";

export default {
  name: "AccountsSubscriptions",
  components: {
    SubscriptionsList
  },
  computed: {
    accountId() {
      return this.$route.params.accountId;
    },
    subscriptionId() {
      return this.$route.params.subscriptionId;
    }
  },
  data() {
    return {
      subscription: null
    };
  },
  methods: {
    onSelect(subscription) {
      this.$route.params.systemCompanyId
        ? this.$router
            .push({
              name: "SystemCompanyAccountsSubscriptionsLicenses",
              params: { ...this.$route.params, subscriptionId: subscription.id }
            })
            .catch((err) => {
              if (err.name !== "NavigationDuplicated") throw err;
            })
        : this.$router
            .push({
              name: "SystemUserAccountsSubscriptionsLicenses",
              params: { ...this.$route.params, subscriptionId: subscription.id }
            })
            .catch((err) => {
              if (err.name !== "NavigationDuplicated") throw err;
            });
      this.subscription = subscription;
    },
    onClose() {
      const parentRoute = this.$route.matched[this.$route.matched.length - 2];
      let cloneParentRoute = Object.assign({}, parentRoute);
      let cloneRouteParams = Object.assign({}, this.$route.params);
      delete cloneRouteParams.subscriptionId;
      cloneParentRoute.params = Object.assign({}, cloneRouteParams);
      this.$router.push(cloneParentRoute);
    }
  }
};
</script>

<style>
</style>