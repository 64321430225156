<i18n lang="json">
{
  "zh-Hant":{
    "companies": "公司列表",
    "accounts": "訂閱帳戶",
    "subscriptions_and_plans" : "訂閱方案",    
    "subscription_licenses" : "產品授權"
  },
  "en":{
    "companies": "Companies",
    "accounts": "Accounts",
    "subscriptions_and_plans" : "Subscribed plans",
    "subscription_licenses" : "Authorization"
  }
}
</i18n>

<template>
  <v-container fluid>
    <header class='mb-4'>
        <router-link :to="{ name:'SystemCompany' }">
          {{$t("companies")}}
          <v-skeleton-loader v-if="isCompanyNameLoading && systemCompanyName==''" type="chip" class='d-inline-block'></v-skeleton-loader>
        </router-link>
        / 
        <router-link :to="{ name:'SystemCompanyAccounts', params:{ systemCompanyId:companyId } }">
          {{$t("accounts")}} 
        <v-skeleton-loader v-if="isAccountNameLoading && accountName==''" type="chip" class='d-inline-block'></v-skeleton-loader>
        <span v-else-if="systemCompanyName!==''">( {{systemCompanyName}} )</span>
        
        </router-link>
      <span v-if="accountId">
        / 
        <router-link :to="{ name:'SystemCompanyAccountsSubscriptions', params:{ systemCompanyId:companyId , accountId } }">
          {{$t("subscriptions_and_plans")}}
          <span v-if="accountName!==''">( {{accountName}} )</span>
        </router-link>
      </span>
      <span v-if="subscriptionId">
        / {{$t("subscription_licenses")}}
        <span v-if="subscriptionName!==''">( {{subscriptionName}} )</span>
      </span>
    </header>
    <template v-if='!accountId'>
      <accounts-list :companyId="companyId" @select='onSelect'></accounts-list>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </v-container>
</template>

<script>
import AccountsList from "../../components/accounts/List.vue";

export default {
  name: "SystemCompanyAccounts",
  components: {
    AccountsList
  },
  data() {
    return {
      systemCompanyName: "",
      accountName: "",
      subscriptionName: "",
      isCompanyNameLoading: false,
      isAccountNameLoading: false
    };
  },
  computed: {
    companyId() {
      return this.$route.params.systemCompanyId;
    },
    accountId() {
      return this.$route.params.accountId;
    },
    subscriptionId() {
      return this.$route.params.subscriptionId;
    }
  },
  watch: {
    companyId: {
      immediate: true,
      async handler(companyId) {
        if (companyId) {
          try {
            this.isCompanyNameLoading = true;
            const response = await this.$API.api.main.company.get(
              this.companyId
            );
            this.systemCompanyName = response.data.name;
          } catch (err) {
            console.error(err);
          } finally {
            this.isCompanyNameLoading = false;
          }
        } else {
          this.systemCompanyName = "";
        }
      }
    },
    accountId: {
      immediate: true,
      async handler(accountId) {
        if (accountId) {
          this.isAccountNameLoading = true;
          try {
            const accounts = await this.getAccounts();
            const account = accounts.find(
              (account) => account.id === accountId
            );
            if (account) {
              this.accountName = account.name;
            } else {
              this.accountName = "";
            }
          } catch (err) {
            console.error(err);
          }
          this.isAccountNameLoading = false;
        } else {
          this.accountName = "";
        }
      }
    },
    subscriptionId: {
      immediate: true,
      async handler(subscriptionId) {
        if (subscriptionId) {
          let subscriptions = await this.getAccountSubscriptions();
          const subscription = subscriptions.find(
            (subscription) => subscription.id === subscriptionId
          );
          this.subscriptionName = subscription.plan.name;
        } else {
          this.subscriptionName = "";
        }
      }
    }
  },
  methods: {
    onSelect(account) {
      this.$router.push({
        name: "SystemCompanyAccountsSubscriptions",
        params: { ...this.$route.params, accountId: account.id }
      });
    },
    async getAccounts() {
      let results = [];
      try {
        if (this.companyId) {
          const response = await this.$API.api.main.companiesAccounts.get(
            this.companyId
          );
          results = response.data;
        }
      } catch (error) {
        console.error(error);
      }
      return results;
    },
    async getAccountSubscriptions() {
      let results = [];
      try {
        if (this.accountId) {
          this.loading = true;
          const response = await this.$API.api.main.accountsSubscriptions.get(
            this.accountId
          );
          results = response.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
      return results;
    }
  }
};
</script>

<style scoped lang='scss'>
::v-deep {
  .v-skeleton-loader__chip {
    height: 1.5rem;
  }
}
</style>