<template>
    <v-card outlined>
        <v-card-title>
            <v-icon style="margin-right: 10px">mdi-transit-connection-variant</v-icon>
            <div class="text-capitalize">連接數資訊</div>
            <v-spacer></v-spacer>
            <!-- <filter-search-bar :search.sync="search" :filter.sync="dataState.filter" :fields="['name']"></filter-search-bar> -->
        </v-card-title>
        <online-user-grid></online-user-grid>
    </v-card>
</template>

<script>
import OnlineUserGrid from "@/components/online/OnlineUserGrid.vue"

export default {
    components:{
        OnlineUserGrid
    }

}
</script>