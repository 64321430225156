import axios from 'axios'
import mainApi from '@/config/mainApi'

const defaultState = {
  createUserDialog: {
    value: false,
  },
  systemCompany: [],
  systemRoles: [],
  crApiConfig: null,
}

const actions = {
  /**取得所有系統角色 */
  getSystemRoles: ({
    commit,
    getters,
    rootGetters
  }) => {
    const mainBaseUrl = getters.crApiConfig.mainBaseUrl

    axios
      .get(mainBaseUrl + mainApi.role, {
        headers: {
          Authorization: `Bearer ${rootGetters['oidcStore/oidcAccessToken']}`
        }
      })
      .then(response => {
        commit('SET_SYSTEM_ROLES', response.data.system)
      })
      .catch(error => {
        console.error(error)
      })
  },
  /**取得系統內所有公司 */
  getSystemCompany: ({
    commit,
    getters,
    rootGetters
  }) => {
    const mainBaseUrl = getters.crApiConfig.mainBaseUrl

    axios
      .post(mainBaseUrl + mainApi.systemCompany, null, {
        headers: {
          Authorization: `Bearer ${rootGetters['oidcStore/oidcAccessToken']}`
        }
      })
      .then(response => {
        commit('SET_SYSTEM_COMPANY', response.data.data)
      })
      .catch(error => {
        console.error(error)
      })
  },
  /**建立帳號 */
  postUser: ({
    commit,
    getters,
    rootGetters
  }, account) => {
    const mainBaseUrl = getters.crApiConfig.mainBaseUrl

    axios
      .post(mainBaseUrl + mainApi.user, account, {
        headers: {
          Authorization: `Bearer ${rootGetters['oidcStore/oidcAccessToken']}`
        }
      })
      .then(() => {
        commit("SET_CREATEUSER_DIALOG", {
          value: false
        })
        const snackbar = {
          type: 'success',
          message: 'Success!',
          value: true,
          timeout: 4000
        };
        commit("notification/SET_ALERT", snackbar, {
          root: true
        });
      })
      .catch(error => {
        if (error.response.status === 400) {
          const dialog = {
            title: 'Error',
            message: error.response.data,
            value: true
          };
          commit("notification/SET_DIALOG", dialog, {
            root: true
          });
        } else {
          const dialog = {
            title: 'Error',
            message: error,
            value: true
          };
          commit("notification/SET_DIALOG", dialog, {
            root: true
          });
        }
        console.error(error)
      })
  },
}

const mutations = {
  SET_CREATEUSER_DIALOG(state, props) {
    state.createUserDialog = Object.assign(state.createUserDialog, props)
  },
  SET_SYSTEM_ROLES(state, data) {
    state.systemRoles = data
  },
  SET_SYSTEM_COMPANY(state, data) {
    state.systemCompany = data
  },
  SET_CR_APICONFIG(state, data) {
    state.crApiConfig = data
  },
}

const getters = {
  createUserDialog: state => state.createUserDialog,
  systemCompany: state => state.systemCompany,
  systemRoles: state => state.systemRoles,
  crApiConfig: state => state.crApiConfig,
}



export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations
}