// import _filter from 'lodash/filter';
// import _map from 'lodash/map'

//reference : 
// Build tree array from flat array in javascript

//https://stackoverflow.com/a/55426143/9901484
// export default (arr, parentIdKey, childrenKey, primaryKey) => _map(_filter(arr, ar => !ar[parentIdKey]), ar => ({ ...ar, [childrenKey]: _filter(arr, { [parentIdKey]: primaryKey?ar[primaryKey]:ar.id }) }))

//reference : https://stackoverflow.com/a/55241491
const nest = (items, id = undefined, link = 'parentId', childrenKey = 'children') =>{
  return items
  .filter(item => {
    return item[link] === id;
  })
  .map(item => {
    return {
      ...item,
      [childrenKey]: nest(items, item.id, link, childrenKey),
    };
  } );
}
export default nest;