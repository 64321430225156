<i18n>
{
  "zh-Hant":{
    "authorization":"授權",
    "remain":"剩餘"
  },
  "en":{
    "authorization":"Authorization",
    "remain":"remain"
  }
}
</i18n>


<template>
  <v-navigation-drawer v-model='drawer' width="500" fixed right temporary stateless>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon size='56'>
            mdi-account-circle
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content v-if='user'>
          <v-list-item-title class="text-h6">
            {{user.name}}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{user.email}}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon @click='drawer=false'>
            mdi-close
          </v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-card flat>
        <v-card-text :key="userId">
          <div v-if="isUsersSubscriptionLicensesLoading" class="k-loading-mask">
            <span class="k-loading-text"></span>
            <div class="k-loading-image" />
            <div class="k-loading-color" />
          </div>
          <div>
            {{$t("authorization")}}
          </div>  
          <v-checkbox
            v-for="(license) in  licenses"
            :key="`${license.id}-${userSubscriptionLicensesNames.indexOf(license.name)!==0}`"
            v-model='license.isUserEnable'
            class='mt-0'
            hide-details
            @change="onCheckboxChange(license, $event)"
          >
          <template #label>
            {{license.name}}
          </template>
          </v-checkbox>
        </v-card-text>
      </v-card>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "UserSubscriptionLicensesDrawer",
  data() {
    return {
      licenses: [],
      userSubscriptionsLicenses: [],
      isUsersSubscriptionLicensesLoading: false
    };
  },
  props: {
    value: {
      type: Boolean
    },
    user: {
      type: Object
    },
    subscriptionId: {
      type: String
    }
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(drawer) {
        this.$emit("input", drawer);
      }
    },
    userId() {
      return this.user ? this.user.id : null;
    },
    userSubscriptionLicensesNames() {
      return this.userSubscriptionsLicenses.map(
        (subscriptionLicense) => subscriptionLicense.name
      );
    }
  },
  watch: {
    userSubscriptionsLicenses: {
      immediate: true,
      handler(userSubscriptionsLicenses) {
        for (let i = 0; i < this.licenses.length; i++) {
          let userSubscriptionsLicensesNames = userSubscriptionsLicenses.map(
            (item) => item.name
          );
          this.licenses[i].isUserEnable =
            userSubscriptionsLicensesNames.indexOf(this.licenses[i].name) !==
            -1;
        }
      }
    },
    drawer: {
      immediate:true,
      async handler(drawer) {
        if (!drawer) {
          this.userSubscriptionsLicenses = [];
          this.licenses = []
        }else{
          this.licenses = await this.getSubscriptionLicenses();
          this.userSubscriptionsLicenses =
            await this.getUsersSubscriptionLicenses(this.subscriptionId);
        }
      }
    }
  },
  methods: {
    async getSubscriptionLicenses() {
      let results = [];
      try {
        const response = await this.$API.api.main.subscriptionsLicenses.get(
          this.subscriptionId
        );
        results = response.data.map((License) => ({
          ...License,
          isUserEnable: false
        }));
      } catch (error) {
        console.error(error);
      }
      return results;
    },
    async getUsersSubscriptionLicenses(subscriptionId) {
      let results = [];
      try {
        this.isUsersSubscriptionLicensesLoading = true;
        const response = await this.$API.api.main.usersSubscriptionLicenses.get(
          this.userId
        );
        results = response.data;
        results.filter(subscriptionLicense=> subscriptionLicense.subscriptionId === subscriptionId)
      } catch (error) {
        console.error(error);
      } finally {
        this.isUsersSubscriptionLicensesLoading = false;
      }
      return results;
    },
    async onCheckboxChange(license, value) {
      if (value) {
        try {
          this.isUsersSubscriptionLicensesLoading = true;
          await this.$API.api.main.subscriptionsLicenses.postLicenseUser(
            this.subscriptionId,
            license.id,
            this.user.id
          );
          this.userSubscriptionsLicenses =
            await this.getUsersSubscriptionLicenses(this.subscriptionId);
          const licenseIndex = this.licenses.findIndex(
            (l) => l.id === license.id
          );
          this.licenses[licenseIndex].usedAmount++;
          this.setAlert("success", this.$t("dialog.update_successfully"));
        } catch (error) {
          console.error(error);
          this.setAlert("error", this.$t("dialog.update_failed"));
        } finally {
          this.isUsersSubscriptionLicensesLoading = false;
        }
      } else {
        const userLicenseIdx = this.userSubscriptionLicensesNames.indexOf(
          license.name
        );
        const subscriptionId =
          this.userSubscriptionsLicenses[userLicenseIdx].subscriptionId;
        try {
          this.isUsersSubscriptionLicensesLoading = true;
          await this.$API.api.main.subscriptionsLicenses.deleteLicenseUser(
            subscriptionId,
            license.id,
            this.user.id
          );
          this.userSubscriptionsLicenses =
            await this.getUsersSubscriptionLicenses(this.subscriptionId);
          const licenseIndex = this.licenses.findIndex(
            (l) => l.id === license.id
          );
          this.licenses[licenseIndex].usedAmount--;
          this.setAlert("success", this.$t("dialog.update_successfully"));
        } catch (error) {
          console.error(error);
          this.setAlert("error", this.$t("dialog.update_failed"));
        } finally {
          this.isUsersSubscriptionLicensesLoading = false;
        }
      }
    }
  }
};
</script>

<style>
</style>