const mainApi = {
  baseUrl: process.env.VUE_APP_MAIN_BASE_URL,
  comments: '/Comments/',
  projectUser: '/ProjectUser/',
  projectUserAndGroup: function (projectId, query) {
    return `/Project/${projectId}/UserGroup/AndUser?${query}`
  },
  role: '/Role/',
  systemCompany: '/SystemCompany/',
  user: '/User',
  userInfo: '/UserInfo'
}

export default mainApi;