<i18n>
{
    "zh-Hant":{
        "server_list":"伺服器列表",
        "server_list_set_users":"設定伺服器使用者",
        "server_added_users":"目前伺服器成員",
        "server_system_users":"可加入的成員",
        "search_user":"搜尋公司, 姓名, 或Email",
        "remove":"移除",
        "add":"加入",
        "company_name":"公司",
        "user_name":"姓名",
        "user_email":"Email"
    }
}
</i18n>

<template>
    <v-card>
        <v-card-title>
            <v-icon left>
                mdi-file-document-multiple-outline
            </v-icon>
            <button @click="$router.go(-1)">
                {{$t("server_list")}}
            </button>
            <v-icon left>mdi-chevron-right</v-icon>
                {{$t("server_list_set_users")}}
                : {{ serverName }} ( {{machineName}} )
        </v-card-title>
        <v-card-text>
            <v-container fluid>
                <v-row no-gutters>
                    <v-col>
                        <v-card-title >
                            <v-icon left>
                            mdi-file-document-multiple-outline
                            </v-icon>
                                {{$t("server_added_users")}}
                            <v-spacer></v-spacer>
                            <v-icon left>mdi-magnify</v-icon>
                            <v-text-field
                                class="search-bar"
                                :placeholder="$t('search_user')"
                                outlined
                                dense
                                hide-details
                                @keyup="searching($event, 'addedUsers')"
                            ></v-text-field>
                        </v-card-title>
                        <v-card-text class='grid-view'>
                            <localization-provider class='h-100' :language="$i18n.locale">
                                <intl-provider class='h-100' :locale="$i18n.locale">
                                    <grid
                                        class="h-100"
                                        :columns="addedUsersColumns"
                                        :pageable="addedUsers.pageable"
                                        :sortable="addedUsers.sortable"
                                        :data-items="addedUsers.dataItems"
                                        :skip="addedUsers.skip"
                                        :take="addedUsers.take"
                                        :sort="addedUsers.sort"
                                        :filter="addedUsers.filter"
                                        @datastatechange="dataStateChange($event, 'addedUsers')"
                                        :column-menu="addedUsers.columnMenu"
                                        >
                                        <template v-slot:remove="{ props }">
                                            <td>
                                                <v-btn
                                                    small
                                                    :color="'primary'"
                                                    @click="remove(props.dataItem)"
                                                >
                                                {{$t('remove')}}
                                                </v-btn>
                                            </td>
                                        </template>
                                    </grid> 
                                </intl-provider>
                            </localization-provider>
                        </v-card-text>
                    </v-col>
                    <v-col>
                        <v-card-title>
                            <v-icon left>
                            mdi-file-document-multiple-outline
                            </v-icon>
                                {{$t("server_system_users")}}
                            <v-spacer></v-spacer>
                            <v-icon left>mdi-magnify</v-icon>
                            <v-text-field
                                class="search-bar"
                                :placeholder="$t('search_user')"
                                outlined
                                dense
                                hide-details
                                @keyup="searching($event, 'otherUsers')"
                            ></v-text-field>
                        </v-card-title>
                        <v-card-text class='grid-view'>
                            <localization-provider class='h-100' :language="$i18n.locale">
                                <intl-provider class='h-100' :locale="$i18n.locale">
                                    <grid
                                        class="h-100"
                                        :columns="otherUsersColumns"
                                        :pageable="otherUsers.pageable"
                                        :sortable="otherUsers.sortable"
                                        :page-size="otherUsers.pageSize"                 
                                        :data-items="otherUsers.dataItems"
                                        :skip="otherUsers.skip"
                                        :take="otherUsers.take"
                                        :sort="otherUsers.sort"
                                        :filter='otherUsers.filter'
                                        @datastatechange="dataStateChange($event, 'otherUsers')"
                                        :column-menu="otherUsers.columnMenu"
                                        >
                                        <template v-slot:add="{ props }">
                                            <td>
                                            <v-btn
                                                small
                                                :color="'primary'"
                                                @click="add(props.dataItem)"
                                            >
                                            {{$t('add')}}
                                            </v-btn>
                                            </td>
                                        </template>
                                    </grid> 
                                </intl-provider>
                            </localization-provider>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>

import { Grid } from '@progress/kendo-vue-grid';
import { toDataSourceRequestString  } from '@progress/kendo-data-query'; 

export default {
    name:"ServerSetting",
    components: { 
        'grid':Grid
    },
    data: function(){
        return{
            searchValue:null,
            addedUsers:{
                skip: 0,
                take: 15,
                sort: null,
                filter: null,
                pageSize: 20,
                pageable: {
                    buttonCount: 3,
                    info: true,
                    type: 'numeric',
                    pageSizes: true,
                    previousNext: true
                },
                columnMenu:true,
                sortable:true,
                dataItems:[]
            },
            otherUsers:{
                skip: 0,
                take: 15,
                sort: null,
                filter: null,
                pageSize: 20,
                pageable: {
                    buttonCount: 3,
                    info: true,
                    type: 'numeric',
                    pageSizes: true,
                    previousNext: true
                },
                dataItems:[],
                sortable:true,
                columnMenu:true
            }
        }
    },
    computed:{
        columns(){
            return [
                { field: 'company.name', title:this.$t('company_name'), filter:"text" },
                { field: 'user.name', title:this.$t('user_name'), filter:"text" },
                { field: 'user.email', title:this.$t('user_email'), filter:"text"}
            ]
        },
        addedUsersColumns(){
            return [
                ...this.columns,
                { width:'150px', cell:"remove", columnMenu:false, sortable:false  }
            ]
        },
        otherUsersColumns(){
            return [
                ...this.columns,
                { width:'150px', cell:"add", columnMenu:false, sortable:false  }]
        },
        serverId(){
            return this.$route.params.serverId
        },
        serverName(){
            return this.$route.query.serverName
        },
        machineName(){
            return this.$route.query.machineName
        }
    },
    methods:{
        searching(event, type){
            this[type].filter ={
                "filters":[
                    {"logic":"or","filters":[{"field":"company.name","operator":"contains","value":event.target.value}]},
                    {"logic":"or","filters":[{"field":"user.name","operator":"contains","value":event.target.value}]},
                    {"logic":"or","filters":[{"field":"user.email","operator":"contains","value":event.target.value}]},
                    ]
                ,"logic":"or"}
            this.getData(type);
        },
        async add(dataItem) {
            let userIds = [ dataItem.user.id ];
            try{
                await this.$API.api.bim.serverUsers.post(this.serverId, userIds)
                const snackbar={type:'success', message:this.$t('dialog.add_successfully'), value:true, timeout:4000};  
                this.$store.commit("notification/SET_ALERT", snackbar);
                this.getData('addedUsers')
                this.getData('otherUsers')
            }catch(error){
                console.error(error)
                const snackbar={type:'error', message:this.$t('dialog.add_failed'), value:true, timeout:4000};  
                this.$store.commit("notification/SET_ALERT", snackbar);
                const dialog={title:'ERROR', message:error, value:true};
                this.$store.commit("notification/SET_DIALOG", dialog);
            }
        },
        async remove(dataItem) {
            let userId = dataItem.user.id;
            try{
                await this.$API.api.bim.serverUsers.deleteSingle(this.serverId, userId)
                const snackbar={type:'success', message:this.$t('dialog.remove_successfully'), value:true, timeout:4000};  
                this.$store.commit("notification/SET_ALERT", snackbar);
                this.getData('addedUsers')
                this.getData('otherUsers')
            }catch(error){
                console.error(error)
                const snackbar={type:'error', message:this.$t('dialog.remove_failed'), value:true, timeout:4000};  
                this.$store.commit("notification/SET_ALERT", snackbar);
                const dialog={title:'ERROR', message:error, value:true};
                this.$store.commit("notification/SET_DIALOG", dialog);
            }
        },
        dataStateChange(event, type){
            this.createAppState(event.data, type);
        },
        createAppState(dataState, type= 'addedUsers') {
            this[type].take = dataState.take;
            this[type].skip = dataState.skip;
            this[type].sort = dataState.sort;
            this[type].filter = dataState.filter;
            this.getData(type);
        },
        async getData(type ='addedUsers'){
            let dataState = {
                take: this[type].take,
                skip: this[type].skip,
                filter: this[type].filter,
                sort: this[type].sort
            };
            
            const queryStr = toDataSourceRequestString(dataState); // Serialize the state
            try{
                if(type === 'addedUsers'){
                    this[type].dataItems = await this.$API.api.bim.serverUsers.get(this.serverId, queryStr).then(res=>res.data);
                }
                else if(type === 'otherUsers'){
                    this[type].dataItems = await this.$API.api.bim.users.get(queryStr).then(res=>res.data);
                }
            }catch(err){
                console.error(err)
            }
        }
},
    created(){
        this.getData('addedUsers')
        this.getData('otherUsers')
    },
    mounted(){
        this.$store.commit("layout/TOGGLE_IS_FOOTER_VISIBLE", false);
        this.$store.commit("layout/SET_PADDING_NUMBER", 4);
    },
    beforeDestroy(){
        this.$store.commit("layout/TOGGLE_IS_FOOTER_VISIBLE", true);
        this.$store.commit("layout/SET_PADDING_NUMBER", 5);
    }
 }
</script>
<style scoped>
    .search-bar{
        width: 250px;
        max-width: 250px;
    }
    
    .h-100{
        height:100%
    }

    .grid-view{
        max-height: 100%;
        height: calc(100vh - 64px - 20px - 20px - 36px - 72px - 36px);
    }
</style>
