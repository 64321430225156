<template>
    <SubscriptionPlanList :key="subscriptionId" :companyId='companyId' :subscriptionId='subscriptionId'></SubscriptionPlanList>
</template>

<script>
import SubscriptionPlanList from "@/components/licenses/SubscriptionPlanList";
export default {
  name: "SubscriptionPlansAndLicenses",
  components: { SubscriptionPlanList },
  data() {
    return {
      licenses: []
    };
  },
  computed: {
    subscriptionId() {
      return this.$route.params.subscriptionId;
    },
    companyId() {
      return this.$route.params.systemCompanyId;
    }
  }
};
</script>

<style>
</style>