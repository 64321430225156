
<i18n>
{
  "zh-Hant": {
    "system": {
      "role": {
        "role": "角色",
        "system": "系統",
        "user":"使用者",
        "company": "公司",
        "project": "專案",
        "zone":"區域"
      },
      "function": "Function"
    }
  },
  "en": {
    "system": {
      "role": {
        "role": "Role",
        "system": "System",
        "user":"User",
        "company": "Company",
        "project": "Project",
        "zone":"Zone"
      },
      "function": "Function"
    }
  }
}
</i18n>

<template>
  <v-container fluid class="roleSetting pa-0" style="height: calc( 100vh - 64px - 12px - 12px );">
      <v-row dense style="overflow: auto;" class="h-100">
        <v-col :cols="12" :md="4" :lg="3" :class="{'sticky-card':$vuetify.breakpoint.mdAndUp}">
          <v-card class="mb-4" outlined >
            <v-card-title>{{$t("system.role.role")}}</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-list rounded dense>
                <v-list-group v-for="(vals, name) in roles.role" :key="name" no-action>
                  <template v-slot:activator>
                    <v-list-item-title>{{$t("system.role."+name)}}</v-list-item-title>
                  </template>
                  <v-list-item
                    v-for="role in vals"
                    :key="role.id"
                    :class="{'v-list-item--active':isActiveItem(role)}"
                    :value="role.id"
                    @click="onClickRole(role, $event)"
                  >{{$t(role.name)}}</v-list-item>
                </v-list-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="12" :md="8" :lg="9">
          <v-card outlined dense>
            <v-data-iterator
              :items="functions.list"
              :search="search"
              :custom-filter="customFilter"
              :group-by="'type'"
              :groupDesc="true"
              :items-per-page="-1"
              :no-data-text="$t('iterator.no-records')"
              :loading="functions.loading"
              hide-default-footer
            >
              <template v-slot:header>
                <v-card-title dense>
                  {{$t("system.function")}}
                  <v-chip
                  small
                    color='primary'
                    class="ml-2"
                  >{{roleFunctionIndexesLength}}</v-chip>
                  <!-- <v-text-field
                    dense
                    v-model.trim="search"
                    clearable
                    flat
                    hide-details
                    solo
                    :label="$t('iterator.label.search')"
                    style="max-width:250px"
                    class="ml-10"
                  >
                    <template v-slot:prepend-inner>
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-icon v-on="on">mdi-magnify</v-icon>
                        </template>
                        <span>{{$t('iterator.textfield.search')}}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>-->

                  <v-spacer></v-spacer>
                      <v-switch class='mt-0 mr-5' :label="`${$t('system.RoleFunctions.toggle_edit_mode')}`" v-model='editing' hide-details ></v-switch>
                </v-card-title>
                <v-divider></v-divider>
              </template>

              <template v-slot:default="props">
                <v-card
                  v-for="(group, idx) in sortGroups(props.groupedItems)"
                  :key="idx +'-'+ group.name"
                  class="mt-2 mb-4"
                  flat
                >
                <v-card-title>
                  {{$t("system.role."+ group.name.toLowerCase())}}
                </v-card-title>
                <v-card-text class='card-text--overflow'>
                    <pivot-table :data="group.items" :col-fields="colFields" :row-fields="rowFields" :reducer='reducer'>
                      <template v-slot:value="{ value}">
                        <v-chip
                          small
                          v-if='value'
                          :key="value.item.id"
                          :filter="editing"
                          :disabled="!editing"
                          :style="{Opacity:1}"
                          @click="onClick($event, value.item, group.name)"
                          v-show="isChipShowing(value.item, group.name)"
                          :color="isChipActivated(value.item, group.name)?'primary':'grey'"
                          class='ma-1'
                        >{{$t(value.item.name)}}</v-chip> 
                      </template>
                      <template v-slot:loading>
                        <v-skeleton-loader
                          class="ma-5"
                          min-width="250"
                          height="250"
                          type="table-tbody"
                          elevation="0"
                        ></v-skeleton-loader>
                      </template>
                      <template v-slot:computing>
                        <v-skeleton-loader
                          class="ma-5"
                          min-width="250"
                          height="250"
                          type="table-tbody"
                          elevation="0"
                        ></v-skeleton-loader>
                      </template>
                    </pivot-table>
                </v-card-text>
                </v-card>
              </template>

              <template v-slot:loading>
                <v-skeleton-loader
                  class="ma-5"
                  min-width="250"
                  type="table-tbody, table-tbody, table-tbody"
                  elevation="0"
                ></v-skeleton-loader>
              </template>
            </v-data-iterator>
          </v-card>
        </v-col>
      </v-row>
  
    
  </v-container>
</template>

<script>
import PivotTable from "@/components/PivotTable.vue";

export default {
  name: "RoleFunction",
  components: {
    PivotTable
  },
  computed: {
    API: function () {
      return this.$API.api.main;
    },
    roleFunctionIndexesLength() {
      let length = 0;
      for (let key in this.roleFunctionIndexes) {
        length += this.roleFunctionIndexes[key].length;
      }
      return length;
    },
    types() {
      let types = this.functions.list.map((f) => f.type);
      return Array.from(new Set(types));
    },
    modules() {
      let modules = this.functions.list.map((f) => f.module);
      return Array.from(new Set(modules));
    },
    positions() {
      let positions = this.functions.list.map((f) => f.position);
      return Array.from(new Set(positions));
    },
    actions() {
      let actions = this.functions.list.map((f) => f.action);
      return Array.from(new Set(actions));
    }
  },
  data() {
    return {
      search: "",
      filter: {},
      sortDesc: false,
      editing: false,
      roles: {
        loading: false,
        role: {
          system: [],
          company: [],
          project: [],
          zone: []
        }
      },
      functions: {
        loading: false,
        list: []
      },
      active: {
        role: {},
        functions: []
      },
      roleFunctionIndexes: {
        System: [],
        Company: [],
        Project: [],
        Zone: []
      },
      isInit: false,
      activeFunc: null,
      reducer: (sum, item) => ({ sum: sum + 1, item }),
      colFields: [
        {
          getter: (item) => item.module,
          label: "Module"
        },
        {
          getter: (item) => item.position,
          label: "position"
        }
      ],
      rowFields: [
        {
          getter: (item) => item.action,
          label: "action"
        }
      ]
    };
  },
  mounted() {
    this.roles.loading = true;
    this.API.role
      .get()
      .then((res) => res.data)
      .then((role) => {
        this.roles.loading = false;
        this.roles.role = role;
        for (let key in this.roles.role) {
          this.roles.role[key].forEach((role) => {
            this.$set(role, "isActive", false);
          });
        }
      })
      .catch((err) => {
        this.setDialog("Error", err);
        console.error("Error:", err);
      });

    this.functions.loading = true;
    this.API.Function.get()
      .then((res) => res.data)
      .then((functions) => {
        this.functions.loading = false;

        //新增type ['System', 'Company', 'Project', 'Zone']
        this.functions.list = functions.map((func) => {
          let chuncks = func.name.split(".");
          let type = chuncks[0];
          let module = chuncks[1];
          let position = chuncks[2];
          let action = chuncks[3];
          return { ...func, type, module, position, action };
        });

        let uniqueType = new Set(this.functions.list.map((i) => i.type));

        uniqueType.forEach((item) => {
          this.$set(this.roleFunctionIndexes, item, []);
        });
      })
      .catch((err) => {
        this.setDialog("Error", err);
        console.error("Error:", err);
      });
  },
  methods: {
    onClickRole(role) {
      this.active.role = role;
      //loading
      this.API.roleFunction
        .get(this.active.role.id)
        .then((res) => res.data)
        .then((roleFunctions) => {
          this.isInit = false;
          this.active.functions = this.functions.list.filter((func) => {
            let isMatched = roleFunctions.map((rf) => rf.id).includes(func.id);
            func.isActive = isMatched;
            return isMatched;
          });

          for (let key in this.roleFunctionIndexes) {
            let specifiedTypeFunctions = this.active.functions.filter(
              (val) => val.type === key
            );
            this.roleFunctionIndexes[key] = specifiedTypeFunctions.map(
              (val) => {
                return this.functions.list
                  .filter((p) => p.type === key)
                  .findIndex((func) => val.id === func.id);
              }
            );
          }
        })
        .catch((err) => {
          this.setDialog("Error", err);
          console.error("Error:", err);
        });
    },
    isActiveItem(role) {
      return this.active ? this.active.role.id === role.id : false;
    },
    customFilter(items = [], search = "") {
      let searchLowerCase = search.toLowerCase();
      return items.filter(
        (i) =>
          this.$t(i.name).toLowerCase().includes(searchLowerCase) ||
          search === null
      );
    },
    onClickEditBtn() {
      this.editing = !this.editing;
    },
    onClick(e, func, name) {
      this.activeFunc = func;
      this.isInit = false;
      let index = this.functions.list
        .filter((p) => p.type === name)
        .findIndex((functn) => functn.id === func.id);
      let isIncluded = this.roleFunctionIndexes[name].includes(index);
      if (isIncluded) {
        this.activeFunc.isAdding = false;
        let idx = this.roleFunctionIndexes[name].indexOf(index);
        this.roleFunctionIndexes[name].splice(idx, 1);
      } else {
        this.activeFunc.isAdding = true;
        this.roleFunctionIndexes[name].push(index);
      }
    },
    isChipShowing(func, name) {
      return this.editing
        ? true
        : this.roleFunctionIndexes[name].includes(
            this.functions.list
              .filter((p) => p.type === name)
              .findIndex((f) => f.id === func.id)
          );
    },
    isChipActivated(func, name) {
      return this.roleFunctionIndexes[name].includes(
        this.functions.list
          .filter((p) => p.type === name)
          .findIndex((f) => f.id === func.id)
      );
    },
    axiosHandler() {
      if (this.isInit || !this.activeFunc) {
        return;
      }
      if (this.activeFunc.isAdding) {
        this.API.roleFunction
          .post(this.active.role.id, this.activeFunc.id)
          .then((res) => res.data)
          .then(() => {
            const alert = {
              type: "success",
              message: this.$t("dialog.add_successfully"),
              value: true
            };
            this.$store.commit("notification/SET_ALERT", alert);
          })
          .catch((err) => {
            console.error("Error:", err);
            const alert = {
              type: "error",
              title: "ERROR:",
              message: this.$t("dialog.add_failed"),
              value: true
            };
            this.$store.commit("notification/SET_ALERT", alert);
            const dialog = {
              title: `ERROR:${err.response.status}`,
              message: `${JSON.stringify(err.response)}`,
              value: true
            };
            this.$store.commit("notification/SET_DIALOG", dialog);
          });
      } else {
        this.API.roleFunction
          .delete(this.active.role.id, this.activeFunc.id)
          .then((res) => res.data)
          .then(() => {
            const alert = {
              type: "success",
              message: this.$t("dialog.delete_successfully"),
              value: true
            };
            this.$store.commit("notification/SET_ALERT", alert);
          })
          .catch((err) => {
            console.error("Error:", err);
            const alert = {
              type: "error",
              title: "ERROR:",
              message: this.$t("dialog.delete_failed"),
              value: true
            };
            this.$store.commit("notification/SET_ALERT", alert);
            const dialog = {
              title: `ERROR:${err.response.status}`,
              message: `${JSON.stringify(err.response)}`,
              value: true
            };
            this.$store.commit("notification/SET_DIALOG", dialog);
          });
      }
      this.activeFunc = null;
    },
    sortGroups(groupsArr) {
      let systemGroupArr = groupsArr.filter((g) => g.name === "System");
      let userGroupArr = groupsArr.filter((g) => g.name === "User");
      let companyGroupArr = groupsArr.filter((g) => g.name === "Company");
      let projectGroupArr = groupsArr.filter((g) => g.name === "Project");
      let zoneGroupArr = groupsArr.filter((g) => g.name === "Zone");
      return [
        ...systemGroupArr,
        ...userGroupArr,
        ...companyGroupArr,
        ...projectGroupArr,
        ...zoneGroupArr
      ];
    }
  },
  watch: {
    "roleFunctionIndexes.System"() {
      this.axiosHandler();
    },
    "roleFunctionIndexes.Company"() {
      this.axiosHandler();
    },
    "roleFunctionIndexes.Project"() {
      this.axiosHandler();
    },
    "roleFunctionIndexes.Zone"() {
      this.axiosHandler();
    }
  }
};
</script>

<style scoped>
.card-text--overflow {
  overflow: auto;
}
.h-100{
  height: 100% !important;
}

.sticky-card {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: fit-content !important;
}
</style>