<template>
  <sb-shared-menus-grid
    :title='title'
    :read-only='true'
    :custom-command='customCommand'>
    <template #custom_command="{ props }">
      <v-btn color='primary' small @click='applyMenu(props.dataItem)' :disabled='companyMenu && companyMenu.id === props.dataItem.id'>
        應用
      </v-btn>
    </template>
  </sb-shared-menus-grid>
</template>

<script>
export default {
  name: "SystemCompanyMenu",
  components: {},
  computed: {
    systemCompanyId() {
      return this.$route.params.systemCompanyId;
    },
    systemCompanyName() {
      return this.$route.query.systemCompanyName;
    },
    customCommand() {
      return {
        hidden: false
      };
    },
    title() {
      return {
        icon: "mdi-format-list-bulleted",
        text: `公司選單設定 : ${this.systemCompanyName}`
      };
    }
  },
  data() {
    return {
      companyMenu: null
    };
  },
  methods: {
    async applyMenu(menu) {
      await this.saveData(menu.id);
      this.companyMenu = menu;
    },
    async loadData() {
      this.companyMenu = await this.$API.api.main.companyMenu
        .get(this.systemCompanyId)
        .then((res) => (res.data && res.data.id ? res.data : null))
        .catch((err) => {
          console.error("Error:", err);
          this.setAlert("error", this.$t("dialog.load_failed"));
        });
    },
    saveData(menuId) {
      return this.$API.api.main.companyMenu
        .post(this.systemCompanyId, menuId)
        .then((res) => res.data)
        .then(() => {
          this.setAlert("success", this.$t("dialog.update_successfully"));
        })
        .catch((err) => {
          console.error("Error:", err);
          this.setAlert("error", this.$t("dialog.update_failed"));
        });
    }
  },
  created() {
    this.loadData();
  }
};
</script>