<i18n>
{
  "zh-Hant":{
    "accounts_settings": "訂閱帳戶"
  },
  "en":{
    "accounts_settings": "Accounts"
  }
}
</i18n>

<template>
  <div class="systemUser">
    <v-card outlined>
      <v-card-title>
        <v-icon style="margin-right: 10px">mdi-account-group-outline</v-icon>
        <div class="text-capitalize">{{ $t("system.setting_users") }}</div>
        <v-spacer></v-spacer>
        <filter-search-bar
          :filter.sync="systemUser.dataState.filter"
          :fields="['name', 'email', 'companyName']"
          @search="systemUser.dataState.skip = 0"
        ></filter-search-bar>
        <v-btn color="primary" class="ml-2" @click="addUser">
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t("system.user.add_user") }}
        </v-btn>
      </v-card-title>
      <v-card flat :loading="systemUser.loading">
        <div v-if="systemUser.loading" class="k-loading-mask">
          <span class="k-loading-text"></span>
          <div class="k-loading-image" />
          <div class="k-loading-color" />
        </div>
        <localization-provider :language="$i18n.locale">
          <intl-provider :locale="$i18n.locale">
            <grid
              ref="grid"
              :data-items="systemUser.gridData"
              :skip="systemUser.dataState.skip"
              :take="systemUser.dataState.take"
              :filter="systemUser.dataState.filter"
              :sort="systemUser.dataState.sort"
              :edit-field="'inEdit'"
              resizable
              sortable
              pageable
              column-menu
              :columns="columns"
              @itemchange="itemChange"
              @datastatechange="dataStateChange"
              @edit="edit"
              @remove="remove"
              @save="save"
              @cancel="cancel"
              style="height: 100%"
            >
              <template v-slot:item_isEnable="{ props }">
                <td>
                  <v-checkbox
                    v-model="props.dataItem.isEnable"
                    hide-details
                    class="ma-0 pa-0"
                    :disabled="!props.dataItem.inEdit"
                  ></v-checkbox>
                </td>
              </template>

              <template v-slot:item_companyName="{ props }">
                <td>
                  <v-chip
                    small
                    v-if="props.dataItem.companyName"
                    color="primary"
                    >{{ props.dataItem.companyName }}</v-chip
                  >
                </td>
              </template>

              <template v-slot:item_systemRole="{ props }">
                <td :style="{ position: 'relative' }">
                  <v-chip
                    small
                    v-for="role in props.dataItem.systemRole"
                    :key="props.dataItem.id + role.id"
                    class="mr-2 mb-2"
                    color="info"
                    >{{ $t(role.name) }}</v-chip
                  >
                </td>
              </template>

              <template v-slot:item_command="{ props }">
                <td>
                  <v-btn
                    small
                    color="primary"
                    class="mr-2"
                    @click="onClickUpdateSystemRole(props.dataItem)"
                  >
                    <v-icon left small>mdi-account-cog</v-icon>
                    {{ $t("system.edit_system_role") }}
                  </v-btn>

                  <v-btn
                    small
                    color="primary"
                    @click="OnClickAssignEmployerBtn(props.dataItem)"
                    class="mr-2"
                  >
                    <v-icon left small>mdi-briefcase-edit</v-icon>
                    {{ $t("system.edit_user_employing_company") }}
                  </v-btn>

                  <!-- <v-btn
                    :disabled="props.dataItem.inEdit"
                    color="primary"
                    small
                    class="mr-2"
                    :to="{
                      name: 'SystemUserAccounts',
                      params: { systemUserId: props.dataItem.id },
                      query: { systemUserName: props.dataItem.name },
                    }"
                  >
                    <v-icon small left>mdi-cash-multiple</v-icon>
                    {{ $t("accounts_settings") }}
                  </v-btn> -->
                </td>
              </template>

              <grid-no-records
                :style="{ minHeight: systemUser.loading ? '256px' : '128px' }"
              >
                <div>{{ $t("general.no_records") }}</div>
              </grid-no-records>
            </grid>
          </intl-provider>
        </localization-provider>
      </v-card>
    </v-card>
    <!-- select system roles -->
    <v-dialog v-model="systemRolesDialog.value" max-width="400px" persistent>
      <v-card>
        <v-toolbar flat dense>
          {{ $t("system.edit_system_role") }} ( {{ systemRolesDialog.title }} )
          <v-spacer></v-spacer>
          <v-btn small icon @click="systemRolesDialog.value = false">
            <v-icon small> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <v-autocomplete
            multiple
            :label="$t('system.system_role')"
            :placeholder="$t('system.system_role')"
            v-model="systemRolesDialog.user.systemRole"
            :items="systemRolesI18n"
            item-text="i18nName"
            item-value="id"
            @change="onSystemRoleChange"
            :loading="systemRolesDialog.isLoading"
            return-object
            outlined
            hide-details
          >
            <template v-slot:selection="{ item }">
              <v-chip color="info" small>{{ $t(item.name) }}</v-chip>
            </template>
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- employedBy dialog -->
    <v-dialog v-model="employedByDialog.value" max-width="400px" persistent>
      <v-card>
        <v-toolbar flat dense>
          {{ $t("system.edit_user_employing_company") }} (
          {{ employedByDialog.title }} )
          <v-spacer></v-spacer>
          <v-btn small icon @click="employedByDialog.value = false">
            <v-icon small> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <v-autocomplete
            :label="$t('autocomplete.employed_by')"
            :placeholder="$t('autocomplete.employed_by')"
            v-model="employedByDialog.user.companyId"
            :items="employedByDialog.companies"
            @change="onEmployerSubmit"
            item-text="name"
            item-value="id"
            :loading="employedByDialog.isLoading"
            chips
            clearable
            outlined
            hide-details
          >
            <template v-slot:selection="{ item }">
              <v-chip color="primary" small>
                {{ item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <CreateUser/>
  </div>
</template>

<script>
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import { filterGroupByField } from "@progress/kendo-vue-grid";
import CommandCell from "@/components/CommandCell.vue";
import FilterSearchBar from "@/components/FilterSearchBar.vue";
import CreateUser from "@/components/createUser/MainDialog.vue";

export default {
  name: "SystemUser",
  components: {
    FilterSearchBar,
    CreateUser
  },
  data: () => ({
    systemUser: {
      search: "",
      total: 0,
      loading: false,
      list: [],
      updatedData: [],
      dataState: {
        take: 15,
        skip: 0,
        sort: [],
        filter: null,
        group: ""
      },
      gridData: {
        data: [],
        total: 0
      }
    },
    systemRolesDialog: {
      title: "",
      value: false,
      user: {
        id: null,
        systemRole: []
      },
      systemRoles: [],
      isLoading: false
    },
    employedByDialog: {
      title: "",
      value: false,
      user: {
        id: null,
        companyId: "",
        comapnyName: ""
      },
      companies: [],
      isLoading: false
    }
  }),
  computed: {
    API: function () {
      return this.$API.api.main;
    },
    columns() {
      return [
        {
          title: this.$t("system.user_email"),
          field: "email",
          width: 280,
          editable: false
        },
        {
          title: this.$t("system.user_name"),
          field: "name",
          width: 140,
          editable: false
        },
        {
          title: this.$t("system.user_employed_by"),
          field: "companyName",
          cell: "item_companyName",
          width: 140
        },
        {
          title: this.$t("system.user_is_enable"),
          field: "isEnable",
          width: 110,
          filter: "boolean",
          cell: "item_isEnable"
        },
        {
          title: this.$t("system.system_role"),
          field: "systemRole",
          cell: "item_systemRole",
          columnMenu: false,
          sortable: false
        },

        {
          cell: "item_command",
          sortable: false,
          columnMenu: false,
          width: 400
        },
        {
          cell: CommandCell,
          sortable: false,
          columnMenu: false,
          width: 140
        }
      ];
    },
    systemRolesI18n() {
      return this.systemRolesDialog.systemRoles.map((role) => ({
        ...role,
        i18nName: this.$t(role.name)
      }));
    }
  },

  methods: {
    getData() {
      this.systemUser.loading = true;
      const queryStr = toDataSourceRequestString(this.systemUser.dataState); // Serialize the state
      const urlencoded = new URLSearchParams(queryStr);
      this.API.systemUser
        .post(urlencoded, (CONFIG) => {
          CONFIG.headers["Content-Type"] = "application/x-www-form-urlencoded";
          return CONFIG;
        })
        .then((res) => {
          let items = res.data.data;
          this.systemUser.list = items;
          this.systemUser.total = res.data.total;
          this.systemUser.loading = false;
          this.systemUser.updatedData = JSON.parse(
            JSON.stringify(this.systemUser.list)
          );
          this.processData();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    onClickUpdateSystemRole(user) {
      this.systemRolesDialog.title = user.name;
      this.systemRolesDialog.value = true;

      this.systemRolesDialog.user.id = user.id;
      this.systemRolesDialog.user.systemRole = user.systemRole;
    },

    onSystemRoleChange(vals) {
      let gridUserItem = this.systemUser.list.find(
        (item) => item.id === this.systemRolesDialog.user.id
      );

      let gridUserItemSystemRoles = gridUserItem.systemRole.map(
        (item) => item.id
      );
      let curUserItemSystemRoles = vals.map((item) => item.id);

      this.systemRolesDialog.isLoading = true;
      let gridUserItemIndex = this.systemUser.list.findIndex(
        (item) => item.id === this.systemRolesDialog.user.id
      );
      //add
      if (curUserItemSystemRoles.length > gridUserItemSystemRoles.length) {
        let newRoleId = curUserItemSystemRoles.find(
          (role) => gridUserItemSystemRoles.indexOf(role) === -1
        );
        let queryObj = {
          UserId: this.systemRolesDialog.user.id,
          RoleId: newRoleId
        };
        this.API.systemRole
          .post(queryObj.UserId, queryObj.RoleId)
          .then(() => {
            let addedRole = this.systemRolesDialog.systemRoles.find(
              (role) => role.id === newRoleId
            );
            this.systemUser.list[gridUserItemIndex].systemRole.push(addedRole);
            this.systemRolesDialog.isLoading = false;
            this.setAlert("success", this.$t("dialog.update_successfully"));
          })
          .catch((err) => {
            this.systemRolesDialog.isLoading = false;
            this.setAlert("error", this.$t("dialog.update_failed"));
            console.error("error:", err);
          });

        //remove
      } else {
        let removedRoleId = gridUserItemSystemRoles.find(
          (role) => curUserItemSystemRoles.indexOf(role) === -1
        );

        let queryObj = {
          UserId: this.systemRolesDialog.user.id,
          RoleId: removedRoleId
        };
        this.API.systemRole
          .delete(queryObj.UserId, queryObj.RoleId)
          .then(() => {
            let removedRoleIndex = this.systemUser.list[
              gridUserItemIndex
            ].systemRole.findIndex((role) => role.id === removedRoleId);
            this.systemUser.list[gridUserItemIndex].systemRole.splice(
              removedRoleIndex,
              1
            );
            this.systemRolesDialog.isLoading = false;
            this.setAlert("success", this.$t("dialog.update_successfully"));
          })
          .catch((err) => {
            this.systemRolesDialog.isLoading = false;
            this.setAlert("error", this.$t("dialog.update_failed"));
            console.error("error:", err);
          });
      }
    },

    cancelChanges() {
      let editedItems = this.systemUser.updatedData.filter(
        (p) => p.inEdit === true
      );
      if (editedItems.length) {
        editedItems.forEach((item) => {
          this.$set(item, "inEdit", undefined);
        });
      }
      this.$set(this.systemUser, "list", this.systemUser.updatedData.slice());
      this.processData();
    },
    processData() {
      this.systemUser.gridData.data = this.systemUser.list;
      this.systemUser.gridData.total = this.systemUser.total;
    },

    itemChange(e) {
      if (e.dataItem.id) {
        let item = this.systemUser.list.find((p) => p.id === e.dataItem.id);
        this.$set(item, e.field, e.value);
      } else {
        this.$set(e.dataItem, e.field, e.value);
      }
      this.processData();
    },
    dataStateChange: function (event) {
      this.activateHeaderByFilter(event);
      this.createAppState(event.data);
    },
    createAppState: function (dataState) {
      this.systemUser.dataState.take = dataState.take;
      this.systemUser.dataState.skip = dataState.skip;
      this.systemUser.dataState.sort = dataState.sort;
      this.systemUser.dataState.filter = dataState.filter;
      this.getData();
    },
    activateHeaderByFilter(event) {
      let isColumnActive = filterGroupByField(
        event.event.field,
        event.data.filter
      );
      let changedColumn = this.columns.find(function (column) {
        return column.field === event.event.field;
      });
      if (changedColumn) {
        changedColumn.headerClassName = isColumnActive ? "active" : "";
      }
    },
    isChecked(attrs) {
      return attrs["aria-selected"] == "true";
    },
    OnClickAssignEmployerBtn(dataItem) {
      let user = dataItem;
      this.employedByDialog.value = true;
      this.employedByDialog.title = user.name;

      this.employedByDialog.user.id = user.id;
      this.employedByDialog.user.companyId = user.companyId;
      this.employedByDialog.user.companyName = user.companyName;
    },
    onEmployerSubmit() {
      this.employedByDialog.isLoading = true;
      let userId = this.employedByDialog.user.id;
      let companyId = this.employedByDialog.user.companyId;
      if (companyId) {
        this.API.companyUser
          .postEmploy(companyId, userId)
          .then((res) => res.data)
          .then(() => {
            this.setAlert("success", this.$t("dialog.update_successfully"));
            this.getData();
            this.employedByDialog.isLoading = false;
          })
          .catch((err) => {
            console.error("Err:", err);
            this.setAlert("error", this.$t("dialog.update_failed"));
          })
          .finally(() => {
            this.employedByDialog.isLoading = false;
          });
      } else {
        let originCompanyId = this.systemUser.list.find(
          (user) => user.id === userId
        ).companyId;
        this.API.companyUser
          .deleteUser(originCompanyId, userId)
          .then((res) => res.data)
          .then(() => {
            this.setAlert("success", this.$t("dialog.update_successfully"));
            this.getData();
          })
          .catch((err) => {
            console.error("Err:", err);
            this.setAlert("error", this.$t("dialog.update_failed"));
          })
          .finally(() => {
            this.employedByDialog.isLoading = false;
          });
      }
    },
    displayCompany(item) {
      return item.employedBy ? item.employedBy.name : item.companyName;
    },

    edit: function (e) {
      this.$set(e.dataItem, "inEdit", true);
    },
    save: async function (e) {
      this.$set(e.dataItem, "inEdit", undefined);
      let item = this.systemUser.list.find((p) => p.id === e.dataItem.id);
      let index = this.systemUser.list.findIndex((p) => p.id === item.id);
      this.$set(
        this.systemUser.list,
        index,
        await this.update(this.systemUser.list.slice(), item)
      );
      this.$set(this.systemUser.list[index], "inEdit", undefined);
      this.processData();
    },
    async update(data, item, remove) {
      let updated;
      let index = data.findIndex((p) => item.id && p.id === item.id);
      if (index >= 0 && !remove) {
        updated = Object.assign({}, item);
        data[index] = updated;

        let userData = data[index];
        await this.API.userInfo
          .put(userData.id, userData)
          .then((res) => res.data)
          .then(() => {
            this.setAlert("success", this.$t("dialog.update_successfully"));
          })
          .catch((err) => {
            console.error("ERROR:", err);
            this.setAlert("error", this.$t("dialog.update_failed"));
          });
      }

      if (remove) {
        data = data.splice(index, 1);
      }

      return data[index];
    },
    cancel(e) {
      if (e.dataItem.id) {
        let originalItem = this.systemUser.updatedData.find(
          (p) => p.id === e.dataItem.id
        );
        let index = this.systemUser.updatedData.findIndex(
          (p) => p.id === originalItem.id
        );
        this.$set(originalItem, "inEdit", undefined);
        this.$set(this.systemUser.list, index, originalItem);
      } else {
        let index = this.systemUser.list.findIndex(
          (item) => JSON.stringify(item) === JSON.stringify(e.dataItem)
        );
        this.systemUser.list.splice(index, 1);
      }
      this.processData();
    },
    remove(e) {
      e.dataItem.inEdit = undefined;
      this.processData();
    },
    setAlert(type, message) {
      const snackbar = { type, message, value: true, timeout: 4000 };
      this.$store.commit("notification/SET_ALERT", snackbar);
    },
    addUser() {
      const createUser = { value: true };
      this.$store.commit("sbCreateUser/SET_CREATEUSER_DIALOG", createUser);
    },
    async getSystemRoles() {
      this.systemRolesDialog.systemRoles = await this.API.systemRole
        .getAll()
        .then((res) => res.data);
    }
  },
  mounted() {
    this.getSystemRoles();

    this.employedByDialog.isLoading = true;
    this.API.systemCompany
      .post({})
      .then((res) => {
        this.employedByDialog.isLoading = false;
        let kendoDataSource = res.data;
        this.employedByDialog.companies = kendoDataSource.data.map((item) => {
          let { id, name } = item;
          return { id, name };
        });
      })
      .catch((err) => {
        this.employedByDialog.isLoading = false;
        console.error("ERROR:", err);
      });
  },
  watch: {
    "systemUser.dataState": {
      immediate: true,
      handler: function () {
        this.getData();
      },
      deep: true
    }
  }
};
</script>
