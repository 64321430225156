//main.js
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
Vue.config.productionTip = false
Vue.config.disableNoTranslationWarning = true;

//vuetify
import vuetify from './plugins/vuetify';

//i18n
import i18n from './i18n'

// shared-ui
import syncoboxSharedUi from './plugins/syncobox-shared-ui'
syncoboxSharedUi({ store, router })

// shared-framework
import "./plugins/sharedFramework"


// import "syncobox-product-configurator/dist/syncobox-product-configurator.css"
// import syncoboxPC from "syncobox-product-configurator/src/package/index";
// Vue.use(syncoboxPC);


import "./plugins/kendo.js";

//global mixins
import sbMixins from './mixins/sbMixins'
Vue.mixin(sbMixins)

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')