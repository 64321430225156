<i18n lang="json">
  {
    "zh-Hant": {
      "version": "版本",
      "description": "描述",
      "type": "類型"
    }
  }
  </i18n>
  
  <template>
    <v-dialog v-model="dialogComputed" width="450px" persistent>
      <template #activator="{ on }">
        <v-btn v-on="on" color="primary" depressed>
          <v-icon left small> mdi-file-upload </v-icon>
          {{ $t("System.SystemVersionsUploadDialog.file_upload") }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          {{ $t("System.SystemVersionsUploadDialog.file_upload") }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <template #progress>
            <div v-if="file">
              <strong class="upload-percentage--text">{{ Math.ceil(uploadPercentage) }}%</strong>
              <v-progress-linear height="2" :value="uploadPercentage" absolute rounded bottom class="mt-6"></v-progress-linear>
            </div>
          </template>
          <sb-ui-uploader
            v-model="file"
            :prepend-icon="''"
            color="primary darken-1"
            :label="$t('System.SystemVersionsUploadDialog.file_upload') + '...'"
            accept=".zip, .exe, .msi"
            hide-details
            outlined
            dense
            show-size
            single-line
            class="mt-4 pa-0"
            :loading="loading"
          >
          </sb-ui-uploader>
          <v-autocomplete
            v-model="typeId"
            :label="$t('type')"
            outlined
            dense
            class="mt-4"
            hide-details
            item-value="id"
            item-text="name"
            :items="typeItems"
          ></v-autocomplete>
          <v-text-field
            v-model="version"
            :label="$t('version')"
            outlined
            single-line
            hide-details
            dense
            class="mt-4"
          ></v-text-field>
          <v-textarea
            v-model="description"
            :label="$t('description')"
            solo
            flat
            outlined
            auto-grow
            hide-details
            rows="3"
            row-height="24"
            class="mt-4"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed :disabled="loading" @click="cancel">{{ $t("System.SystemVersionsUploadDialog.cancel") }}</v-btn>
          <v-btn
            :disabled="!file || !version || typeId === null"
            :loading="loading"
            depressed
            color="primary"
            @click="uploadVersion"
            >{{ $t("System.SystemVersionsUploadDialog.upload") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import axios from "axios"
  
  export default {
    name: "SystemVersionsUploadDialog",
    props: {
      dialog: Boolean
    },
    data() {
      return {
        version: null,
        description: "",
        typeId: "",
        file: null,
        loading: false,
        uploadPercentage: 0,
        typeItems: []
      }
    },
    computed: {
      dialogComputed: {
        get() {
          return this.dialog
        },
        set(dialog) {
          this.$emit("update:dialog", dialog)
        }
      }
    },
    async mounted() {
      this.typeItems = await this.getSystemVersionTypes()
    },
    methods: {
      async uploadVersion() {
        this.loading = true
        try {
          const { tempId, sasUrl } = await this.getUploadingSasUrl()
          await this.uploadToSas(sasUrl)
          await this.completeSasUpload(tempId)
  
          this.loading = false
          this.dialogComputed = false
          this.$emit("upload:success")
        } catch (err) {
          console.error(err)
          let alert = {
            type: "error",
            message: this.$t("System.SystemVersionsUploadDialog.upload_failed"),
            value: true
          }
          this.SET_ALERT(alert)
        }
      },
      async getUploadingSasUrl() {
        const fileName = this.file.name
        const params = { fileName }
        const response = await this.$API.api.bim.V1_4.systemVersions.upload(params)
        return response.data
      },
      uploadToSas(sasUrl) {
        const axiosConfig = {
          method: "PUT",
          url: sasUrl,
          headers: {
            "x-ms-blob-type": "BlockBlob",
            "x-ms-blob-content-type": "application/octet-stream",
            "Content-Type": "text/plain; charset=UTF-8"
          },
          onUploadProgress: this.onUploadProgress,
          data: this.file
        }
  
        return axios(axiosConfig)
      },
      completeSasUpload(tempId) {
        const fileName = this.file.name
        const data = {
          tempId,
          fileName,
          version: this.version,
          typeId: this.typeId,
          description: this.description
        }
        return this.$API.api.bim.V1_4.systemVersions
          .post(data)
          .then(() => {
            let alert = {
              type: "success",
              message: this.$t("System.SystemVersionsUploadDialog.upload_successfully"),
              value: true
            }
            this.SET_ALERT(alert)
          })
          .catch(() => {
            let alert = {
              type: "error",
              message: this.$t("System.SystemVersionsUploadDialog.upload_failed"),
              value: true
            }
            this.SET_ALERT(alert)
          })
      },
      onUploadProgress(progressEvent) {
        this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
      },
      cancel() {
        this.dialogComputed = false
      },
      async getSystemVersionTypes() {
        let results = []
        try {
          results = await this.$API.api.bim.systemVersions.types().then((res) => res.data)
        } catch (err) {
          console.error(err)
        }
        return results
      }
    },
    watch: {
      dialog(dialog) {
        if (!dialog) {
          this.uploadPercentage = 0
          this.file = null
        }
      },
      file: {
        handler(file) {
          if(file) {
            const splittedFileNames = file.name.replace(/\.[^/.]+$/, "").split("_")
            const typeName = splittedFileNames[0]
            const version = splittedFileNames[1]
            const fileIndex = this.typeItems.findIndex((item) => item.name === typeName)
            if (fileIndex > -1) {
              this.typeId = this.typeItems[fileIndex].id
              this.version = version
            }
          } else {
            this.typeId = null
            this.version = null
            this.description = ""
          }
        },
        deep: false
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  ::v-deep {
    .file-content .v-input--hide-details {
      margin-right: 0 !important;
    }
  }
  .upload-percentage--text {
    position: absolute;
    left: 50%;
    bottom: -24px;
    transform: translateX(-50%);
    font-size: 14px;
  }
  </style>
  