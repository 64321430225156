<template>
  <div class="home">
    Welcome to Syncobox admin!
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  mounted() {
    this.$router.push({ name: 'SystemCompany' })
  }
}
</script>
