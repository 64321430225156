<i18n>
{
  "zh-Hant":{
    "invite": "邀請",
    "please_input_the_email_of_invited_user":"請輸入被邀請者的email。",
    "dialog.invite_successfully":"邀請成功",
    "dialog.invite_failed":"邀請失敗"
  },
  "en":{
    "invite": "Invite",
    "please_input_the_email_of_invited_user":"Please input the email of invited user.",
    "dialog.invite_successfully":"Invite Successfully",
    "dialog.invite_failed":"Invite Failed"
  }
}
</i18n>

<template>
  <div>
    <v-card-text>
      <p>{{ $t('please_input_the_email_of_invited_user') }}</p>
      <v-form v-model="valid">
        <v-text-field v-model.trim="email" outlined required dense clearable :prepend-icon="'mdi-email-outline'"
          :label="$t('company.autocomplete.label.add_system_user')" :placeholder="
            $t('company.autocomplete.placeholder.add_system_user_by_email')
          " :rules="rules" @keydown.13.prevent=""></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" :loading="loading" :disabled="!valid || email.length === 0" @click="inviteUser">{{
          $t('invite')
      }}</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: 'InviteContent',
  props: {
    addUserState: {
      Type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      email: '',
    }
  },
  computed: {
    state: {
      get() {
        return this.addUserState
      },
      set(val) {
        this.$emit('update:addUserState', val)
      },
    },
    rules() {
      return [
        (value) =>
          !!value || this.$t('company.validation.this_field_is_required'),
        (value) => {
          const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          return re.test(value) || this.$t('system.createUser.invalid_email')
        },
      ]
    },
  },
  methods: {
    async inviteUser() {
      this.loading = true
      const companyId = this.state.submitObject.id
      const email = this.email
      try {
        await this.$API.api.main.companyUser.addOrInvite(companyId, email)

        this.$emit('refresh')
        this.$emit('close')
        this.setAlert('success', `${this.email} ${this.$t('dialog.invite_successfully')}`)
      } catch (err) {
        this.setAlert('error', `${this.email} ${this.$t('dialog.invite_failed')}`)
      }
      this.email = ''
      this.loading = false
    },
  },
}
</script>
