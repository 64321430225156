<i18n>
{
  "zh-Hant":{
    "invite": "邀請",
    "please_input_the_email_of_invited_user":"請填寫被邀請者的email。"
  },
  "en":{
    "invite": "Invite",
    "please_input_the_email_of_invited_user":"Please input the email of invited user."
  }
}
</i18n>

<template>
  <v-card>
    <v-card-text class="pt-4">{{$t('please_input_the_email_of_invited_user')}}</v-card-text>
    <v-card flat class="pr-8 pl-7 pt-2">
      <v-form v-model='valid' lazy-validation>
        <v-text-field
          v-model="account.email"
          :label="$t('system.createUser.email')"
          :placeholder="$t('system.createUser.email')"
          prepend-icon="mdi-email"
          :rules="[rules.required, rules.email]"
          :error-messages="errorMessages"
          outlined
          clearable
          required
          class="mb-2"
        ></v-text-field>
      </v-form>
    </v-card>
    <v-card-actions class="pb-4 pr-4">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :disabled='!valid || !account.email'
        @click="inviteUser">{{ $t('invite') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters("sbCreateUser", ["systemRoles", "systemCompany"]),
    systemRolesI18n() {
      return this.systemRoles.map((role) => ({
        ...role,
        i18nName: this.$t(role.name)
      }));
    }
  },
  data() {
    return {
      valid: false,
      account: {
        email: ''
      },
      rules: {
        required: (value) => !!value || this.$t("system.createUser.required"),
        counter: (value) =>
          value && value.length >= 8 || this.$t("system.createUser.password_too_short"),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (
            pattern.test(value) || this.$t("system.createUser.invalid_email")
          );
        },
        oneLowerCase: (value) => {
          const oneLCPattern = /[a-z]/;
          return (
            oneLCPattern.test(value) ||
            this.$t("system.createUser.password_requires_lower")
          );
        },
        confirmPassword: (value) =>
          value === this.account.Password ||
          this.$t("system.createUser.password_Error")
      },
      showPassword: false,
      showPasswordConfirm: false,
      errorMessages: ""
    };
  },
  methods: {
    ...mapMutations("sbCreateUser", ["SET_CREATEUSER_DIALOG"]),
    close() {
      this.SET_CREATEUSER_DIALOG({ value: false });
      // this.clearData();
    },
    async inviteUser() {
      // this.$store.dispatch("sbCreateUser/postUser", this.account);
      try{
        await this.$API.api.main.systemUser.invite(this.account.email)
        this.close()
      }catch(error){
        console.error("error", error)
      }      
      
    },
    clearData() {
      this.account = {
        email: null
      };
      this.showPassword = false;
      this.showPasswordConfirm = false;
    }
  }
};
</script>
