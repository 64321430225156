import Vue from 'vue'
import Vuex from 'vuex'
import sbCreateUser from "./modules/sbCreateUser"

Vue.use(Vuex)

let store = new Vuex.Store({
  state: {
    systemTimeOffset: null
  },
  mutations: {
    SET_SYSTEM_TIME_OFFSET(state, systemTime) {
      state.systemTimeOffset = systemTime
    }
  },
  actions: {
    async getSystemTime({ commit }, sharedApi) {
      const response = await sharedApi.api.main.systemTime.get()
      const serverTime = response.data
      const duration = response.duration
      const offset =
        new Date(serverTime).getTime() + Number(duration) / 2 - Date.now()
      commit('SET_SYSTEM_TIME_OFFSET', offset)
    },
  },
  modules: {
  }
})


store.registerModule("sbCreateUser", sbCreateUser)

export default store