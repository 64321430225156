<i18n>
{
  "zh-Hant":{
    "name":"名稱",
    "availableAmount": "可用",
    "usedAmount": "已用",
    "add":"新增",
    "cancel":"取消",
    "save":"儲存",
    "ok":"確認",
    "subscriptions": "訂閱內容",
    "add": "新增",
    "edit": "編輯",
    "company_name":"公司名稱",
    "users":"使用者",
    "available":"可使用",
    "assigned":"已指派",
    "total":"所有",
    "delete":"刪除"
  },
  "en":{
    "name":"name",
    "availableAmount": "Available",
    "usedAmount": "Used",
    "add":"Add",
    "cancel":"Cancel",
    "save":"Save",
    "ok":"OK",
    "subscriptions": "Subscriptions",
    "add": "Add",
    "edit": "Edit",
    "company_name":"Company Name",
    "users":"Users",
    "available":"available",
    "assigned":"Assigned",
    "total":"Total",
    "delete":"Delete"
  }
}
</i18n>

<template>
<v-card flat class='transparent'>
  <v-card v-if='subscription' width="300" outlined class='mt-2 mb-4'>
    <v-card-title>
      {{subscription.plan?subscription.plan.name:''}}
      <v-icon right :color="subscription.isValid?'success':'error'">
          {{subscription.isValid?`mdi-check-circle`:`mdi-close-circle`}}
      </v-icon>
    </v-card-title>
    <v-card-text>
      <span class='d-block'>
        {{subscription.amount - items.length}} {{$t("available")}}
      </span>
      <v-progress-linear 
        :value="items.length*100/subscription.amount"
        class='my-2'
      ></v-progress-linear>
      <span class='d-block'>
        {{$t("assigned")}} : {{items.length}},  {{$t("total")}} : {{subscription.amount}}
      </span>
      <!-- <div class='caption'>
        {{ subscription.plan?subscription.plan.id:''}}
      </div> -->
      
    </v-card-text>
  </v-card>
  <v-card outlined>
    <v-data-table
      v-if='subscription'
      :key='subscription.id'
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :loading='loading'
    >
    <template #top>
      <v-toolbar
          flat
          dense
        >
          <v-icon left>
            mdi-account-multiple-outline
          </v-icon>
          <v-toolbar-title>
            {{$t("users", { name:subscription.plan.name })}}</v-toolbar-title>
            <v-spacer></v-spacer>  
              <v-btn color='primary' 
                @click='()=>userSelectDialog=true'
                :disabled='loading || subscription.amount === items.length'
              >
                新增
              </v-btn>
              <!-- :key='token' -->
              <sb-ui-userSelector
                :token="token"
                :baseUrl="baseUrl"
                :value="userSelectDialog"
                :settingProps="userSettingProps"
                :searchFieldPlaceholder="$t('permission.search_user_group')"
                :isPermissionSetting="isPermissionSetting"
                @close="()=>userSelectDialog =false"
                @addUser="onAddUser"
              />
          <v-dialog v-model="deleteDialog" max-width="500px">
            <v-card>
              <v-card-title>刪除</v-card-title>
              <v-card-text>您確定要刪除  <b class='px-1'>{{editedItem.name}} ( {{editedItem.email}} )</b>  嗎?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="darken-1" text @click="onCloseDeleteDialog">{{$t('cancel')}}</v-btn>
                <v-btn color="error" @click="onConfirmDelete" :loading='isDeleting'>{{$t('delete')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      <v-divider></v-divider>
    </template>
    <template #item.name="{ item }">
      <a href="javascript:void(0);" @click="onClick(item)">
        {{item.name}}
      </a>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        @click="onClickDelete(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    </v-data-table>
  </v-card>
  <UserSubscriptionLicensesDrawer
    v-if="subscription"
    v-model="isUserSubscriptionLicensesDrawerVisible"
    :subscriptionId="subscription.id"
    :user="selectedUser"
  ></UserSubscriptionLicensesDrawer>
  
</v-card>

</template>

<script>
import UserSubscriptionLicensesDrawer  from "./UserSubscriptionLicensesDrawer"

export default {
  name: "LicensesList",
  components: {
    UserSubscriptionLicensesDrawer
  },
  props: {
    subscriptionId: {
      type: String,
      default: null
    },
    companyId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      subscription: null,
      itemsPerPage: 10,
      items: [],
      dialog: false,
      deleteDialog: false,
      editedItem: {
        id: "",
        availableAmount: 0,
        usedAmount: 0,
        name: ""
      },
      defaultItem: {
        id: "",
        availableAmount: 0,
        usedAmount: 0,
        name: ""
      },
      editedIndex: -1,
      expanded: [],
      openSelector: false,
      userSelectDialog: false,
      isDeleting: false,
      // singleExpand: false,
      isUserSubscriptionLicensesDrawerVisible: false,
      selectedUser: null
    };
  },
  watch: {
    dialog: {
      handler(val) {
        val || this.onClose();
      }
    },
    deleteDialog: {
      handler(val) {
        val || this.onCloseDeleteDialog();
      }
    },
    subscriptionId: {
      immediate: true,
      async handler(subscriptionId) {
        if (subscriptionId) {
          this.subscription = await this.getSubscription();
          this.items = await this.getSubscriptionsPlansUsers()
        }
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t("add") : this.$t("edit");
    },
    headers() {
      return [
        // { text: "", value: "data-table-expand" },
        
        {
          text: this.$t("email"),
          align: "start",
          sortable: true,
          value: "email"
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("company_name"),
          align: "start",
          sortable: true,
          value: "companyName"
        },
        // {
        //   text: this.$t("startDate"),
        //   align: "end",
        //   sortable: false,
        //   value: "startDate",
        //   width: 100
        // },
        {
          align: "end",
          sortable: false,
          value: "actions",
          width: 100
        }
      ];
    },
    token() {
      return this.$store.state.oidcStore.access_token;
    },
    baseUrl() {
      return this.$API.config.mainBaseUrl;
    },
    userSettingProps() {
      return {
        levelType: "company",
        companyId: this.companyId,
        // projectId: "caa6df8f-5808-4625-8515-612d3115c634",
        containGroups: true
      };
    },
    isPermissionSetting() {
      return false;
    }
  },
  methods: {
    async getSubscription() {
      let results = {};
      try {
        if (this.subscriptionId) {
          const response = await this.$API.api.main.V1.subscriptions.get(
            this.subscriptionId
          );
          results = response.data;
        }
      } catch (error) {
        console.error(error);
      }
      return results;
    },
    onClick(user) {
      this.$emit("select", user);
      this.isUserSubscriptionLicensesDrawerVisible = !this.isUserSubscriptionLicensesDrawerVisible;
      this.selectedUser = user
    },
    async onClose() {
      this.dialog = false;
      await this.$nextTick();
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    onClickEdit(editedItem) {
      this.editedIndex = this.items.findIndex(
        (item) => item.id === editedItem.id
      );
      this.editedItem = Object.assign({}, editedItem);
      this.dialog = true;
    },

    onClickDelete(editedItem) {
      this.editedIndex = this.items.findIndex(
        (item) => item.id === editedItem.id
      );
      this.editedItem = Object.assign({}, editedItem);
      this.deleteDialog = true;
    },
    async onCloseDeleteDialog() {
      this.deleteDialog = false;
      await this.$nextTick();
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    async onConfirmDelete() {
      // this.items.splice(this.editedIndex, 1);
      // this.deleteDialog = false;
      // this.items.splice(this.editedIndex, 1);
      await this.deleteSubscriptionPlansUser(
        this.editedItem.id
      );
      this.items = await this.getSubscriptionsPlansUsers()
      this.deleteDialog = false;
    },
    async getSubscriptionLicenses() {
      let results = [];
      try {
        this.loading = true;
        const response = await this.$API.api.main.subscriptionsLicenses.get(
          this.subscriptionId
        );
        results = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
      return results;
    },
      async addSubscriptionsLicensesUser( userId) {
      let results = null;
      try {
        const response =
          await this.$API.api.main.subscriptionsPlans.postUsers(
            this.subscription.id,
            this.subscription.plan.id,
            userId
          );
        results = response.data;
      } catch (error) {
        console.error(error);
      }
      return results;
    },
    async deleteSubscriptionPlansUser(userId) {
      let results = null;
      this.isDeleting = true;
      try {
        const response =
          await this.$API.api.main.subscriptionsPlans.deleteUsers(
            this.subscription.id,
            this.subscription.plan.id,
            userId
          );
        results = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isDeleting = false;
      }
      return results;
    },
    async getSubscriptionLicenseDetail(licenseId) {
      let results = null;
      try {
        const response =
          await this.$API.api.main.subscriptionsLicenses.getLicense(
            this.subscriptionId,
            licenseId
          );
        results = response.data;
      } catch (error) {
        console.error(error);
      }
      return results;
    },
    async getSubscriptionsPlansUsers(){
      let results = [];
      try {
        this.loading = true;
        const response = await this.$API.api.main.V1.subscriptionsPlans.getUsers(
          this.subscriptionId,
          this.subscription.plan.id
        );
        results = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
      return results;
    },
    async onAddUser(users) {
      console.log("users", users)
      try {
        for (let i = 0; i < users.length; i++) {
          await this.addSubscriptionsLicensesUser(users[i].id);
        }
        this.items = await this.getSubscriptionsPlansUsers()
      } catch (error) {
        console.error(error);
      } finally {
        this.userSelectDialog = false;
      }
    }
  }
};
</script>

<style scoped lang='scss'>
// ::v-deep {
//   .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
//     // box-shadow: unset !important;
//   }
// }
</style>