import Main from "@/views/menu/Main.vue"

export default {
        path: 'menus',
        name: 'Menu',
        component: Main,
        children: [
            {
                name: "MenuManage",
                path: "mange",
                component: () =>
                    import (/* webpackChunkName: "else" */"@/views/menu/Manage.vue"),
                meta: {
                }
            },
            {
                name: "MenuMenuItems",
                path: ":menuId/menuItems",
                component: () =>
                    import (/* webpackChunkName: "else" */"@/views/menu/MenuItems.vue"),
                meta: {
                }
            }
        ]
    }