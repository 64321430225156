<i18n>
{
    "zh-Hant":{
        "server_list":"伺服器列表",
        "search_server":"搜尋機器, 伺服器或描述...",
        "edit":"編輯",
        "machine_name":"機器名稱",
        "server_name":"伺服器名稱",
        "description":"描述"
    }
}
</i18n>


<template>
    <v-card outlined> 
        <v-card-title >
        <v-icon left>
        mdi-file-document-multiple-outline
        </v-icon>
            {{$t("server_list")}}
        <v-spacer></v-spacer>
        <v-text-field
            v-model="searchValue"
            class="search-bar"
            :placeholder="$t('search_server')"
            outlined
            dense
            hide-details
            append-icon='mdi-magnify'
            @keyup.13="searching"
            @click:append="searching"
        ></v-text-field>
    </v-card-title>
    <v-card :loading='loading' flat>
        <div v-if="loading" class="k-loading-mask">
        <span class="k-loading-text" />
        <div class="k-loading-image" />
        <div class="k-loading-color" />
        </div>
        <localization-provider class='grid-view' :language="$i18n.locale">
            <intl-provider class='h-100' :locale="$i18n.locale">
                <grid
                    class="h-100"
                    :pageable="pageable"
                    sortable
                    :data-items="servers"
                    :columns="columns"
                    :skip="skip"
                    :take="take"
                    :sort="sort"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :column-menu="columnMenu"
                    >
                    <template v-slot:change="{props}">
                        <td>
                            <v-btn color="primary" small 
                                    :to="{name: 'ServerSetting', params: {serverId:props.dataItem.id}, query:{serverName:props.dataItem.serverName, machineName:props.dataItem.machineName,}}" >
                                <v-icon left small>
                                    mdi-pencil
                                </v-icon>
                                {{$t("edit")}}
                            </v-btn> 
                        </td>
                    </template>
                </grid> 
            </intl-provider>
        </localization-provider>
    </v-card>
</v-card> 

</template>
<script>
import { Grid } from '@progress/kendo-vue-grid';
import { toDataSourceRequestString  } from '@progress/kendo-data-query'; 

export default {

    name: 'Servers', 
    components: { 
        'grid':Grid,
    },
    data: function(){
        return{
            servers:null,
            searchValue:null,
            skip: 0,
            take: 15,
            filter:null,
            sort:null,
            pageSize: 10,
            pageable: {
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true
            },
            columnMenu:true,
            loading:false
        }
    },
    computed:{
        dataResult(){ return []},
        columns(){
            return [
                { field: 'machineName', title: this.$t("machine_name"), filter:"text" },
                { field: 'serverName', title:this.$t("server_name"), filter:"text", editable:false },
                { field: 'description', title: this.$t("description"), filter:"text", editable:false},
                { cell:"change" , width:'120px',filterable:false, sortable: false, columnMenu:false },
            ]
        }
    },
    methods:{
        searching(){
            this.filter ={
                "filters":[
                    {"logic":"or","filters":[{"field":"machineName","operator":"contains","value":this.searchValue}]},
                    {"logic":"or","filters":[{"field":"serverName","operator":"contains","value":this.searchValue}]},
                    {"logic":"or","filters":[{"field":"description","operator":"contains","value":this.searchValue}]},
                    ]
                ,"logic":"or"}
            this.skip=0;
            this.getData();
        },
        dataStateChange(event){
            this.createAppState(event.data);
        },
        createAppState: function (dataState) {
            this.take = dataState.take;
            this.skip = dataState.skip;
            this.sort = dataState.sort;
            this.filter = dataState.filter;
            this.getData();
        },
        async getData() {
            this.loading=true;
            let dataState = {
				take: this.take,
				skip: this.skip,
				filter: this.filter,
				sort: this.sort,
			};
            const queryStr = toDataSourceRequestString(dataState); // Serialize the state
            try{
                this.servers = await this.$API.api.bim.servers.get(queryStr).then(res=>res.data);
            }catch(err){
                console.error(err)
            }
            this.loading=false
        },
    },
    created(){
        this.getData()
    }
 }
</script>
<style scoped>
    .grid-view{
        max-height: 100%;
        height: calc(100% - 72px);
    }

    .search-bar{
        width: 250px;
        max-width: 250px;
    }

    .h-100{
        height:100%;
    }
</style>
