<template>
  <sb-shared-layout
    :menu-items='menuItems' 
    :version='packageJson.version'
    :company-setting-url='companySettingUrl'
    :account-items='accountItems'
    :logo-link="logoLink"
    :info-url="infoUrl"
    :is-shared-menu="isSharedMenu"
    :setting-items="settingItems"
    ></sb-shared-layout>
</template>

<script>
import packageJson from '@/../package.json';
import menuItems from "./navDrawerMenuItems"
import accountItems from "@/layouts/navAccountMenuItems";

export default {
  name:"Main",
  computed:{
    functions(){
      return this.$store.state.auth.system.functions
    }
  },
  data(){
    return {
      menuItems: process.env.VUE_APP_BUILD_MODE==='development'?menuItems:menuItems,
      packageJson:packageJson,
      companySettingUrl:process.env.VUE_APP_COMPANY_SETTINGS,
      accountItems,
      logoLink:process.env.VUE_APP_REDIRECT_BASE_URL,
      infoUrl: "https://info.syncobox.com",
      isSharedMenu:false,
      settingItems:[]
    }
  }
  
}
</script>