<i18n lang="json">
{
  "zh-Hant":{
    "guest":"來賓",
    "member":"成員",
    "remove_member":"移除成員",
    "set_enabled_state":"設定啟用狀態",
    "company_members": "公司成員",
    "enabled":"啟用",
    "disabled":"不啟用",
    "set_licenses":"設定授權",
    "set_employer":"設為成員",
    "set {userName} as a company member":"設定 {userName} 為公司成員",
    "is_main_company":"身分 (是否為成員)",
    "note":"備註",
    "add":"新增",
    "invite": "邀請",
    "unknown":"待定"
  },
  "en":{
    "guest":"Guest",
    "member":"Member",
    "remove_member":"Remove Member",
    "set_enabled_state":"Set Enabled State",
    "company_members": "Company Members",
    "enabled":"Enabled",
    "disabled":"Disabled",
    "set_licenses":"Set Licenses",
    "set_employer":"Set AS Member",
    "set {userName} as a company member":"set {userName} as a company member",
    "is_main_company":"Identity (Is member)",
    "note":"note",
    "add":"add",
    "invite": "invite",
    "unknown":"Unknown"
  }
}
</i18n>

<template>
  <v-card
    flat
    :outlined="loading"
    height="calc(100vh - 64px - 20px - 20px - 28px )"
    max-height="100%"
  >
    <div v-if="loading" class="k-loading-mask">
      <span class="k-loading-text"></span>
      <div class="k-loading-image"></div>
      <div class="k-loading-color"></div>
    </div>
    <localization-provider :language="$i18n.locale" style="height: 100%">
      <intl-provider :locale="$i18n.locale" style="height: 100%">
        <sb-ui-fullScreen ref="fullscreen" style="height: 100%">
          <grid
            ref="grid"
            style="height: 100%; max-height: 100%"
            :class="{ fullScreen: isFullscreen }"
            :data-items="dataItemsObj"
            :skip="dataState.skip"
            :take="dataState.take"
            :filter="dataState.filter"
            :sort="dataState.sort"
            :resizable="resizable"
            :sortable="sortable"
            :pageable="pageable"
            :column-menu="columnMenu"
            :columns="columns"
            :selected-field="selectedField"
            @datastatechange="dataStateChange"
            @selectionchange="onSelectionChange"
            @headerselectionchange="onHeaderSelectionChange"
          >
            <grid-toolbar class="pa-3">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="$router.go(-1)" >
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t("back")}}</span>
                  </v-tooltip>
              <span style="font-size: 1.25rem">
                <v-icon left>mdi-account-supervisor-outline</v-icon>
                {{ $t('company.setting.users', { companyName: companyName }) }}
              </span>

              <v-spacer></v-spacer>
              <filter-search-bar
                :filter.sync="dataState.filter"
                :fields="['email', 'name']"
                @search="onSearch"
              >
              </filter-search-bar>
              <v-btn color="primary" @click="onOpenAddUserDialog">
                <v-icon left small> mdi-plus </v-icon>
                {{ $t('invite') }}
              </v-btn>
              <!-- get user by email dialog -->
                  <AddOrInviteDialog
                    v-model='isAddUserDialogVisible'
                    :company-id="companyId"
                    :addUserState.sync="addUserDialog"
                    :submit-function="onAddCompanyUser"
                    @refresh="getData"
                  ></AddOrInviteDialog>

              <v-tooltip :top="!isFullscreen" :bottom="isFullscreen">
                <template #activator="{ on }">
                  <v-btn class="ml-2" icon @click="toggleFullscreen" v-on="on">
                    <v-icon v-if="isFullscreen">mdi-fullscreen-exit</v-icon>
                    <v-icon v-else>mdi-fullscreen</v-icon>
                  </v-btn>
                </template>
                <span>{{
                  isFullscreen ? $t('exit_fullscreen') : $t('fullscreen')
                }}</span>
              </v-tooltip>
            </grid-toolbar>

            <!-- manipulate items -->
            <grid-toolbar class="py-0">
              <v-btn
                small
                outlined
                class="mr-1"
                :disabled="!isEmployeeSelected"
                @click="onClickToggleIsCompanyEnableDialog"
              >
                <v-icon left small> mdi-cog-outline </v-icon>
                {{ $t('set_enabled_state') }}
              </v-btn>
              <v-dialog
                v-model="toggleIsCompanyEnableDialog"
                persistent
                max-width="450px"
              >
                <v-card>
                  <v-card-title>
                    {{ $t('set_enabled_state') }}
                  </v-card-title>
                  <v-card-text>
                    <p>
                      {{ $t('company_members') }} ({{
                        selectedItemsExceptGuest.length
                      }})：
                      <b>{{ selectedItemsNameExceptGuest }}</b>
                    </p>
                    <v-switch
                      v-model="isUserCompanyEnable"
                      class="ma-0"
                      hide-details
                    >
                      <template #label>
                        <span class="ml-2">
                          {{ $t('company.is_company_enable') }}
                        </span>
                        <v-icon
                          right
                          :color="isUserCompanyEnable ? 'success' : 'error'"
                        >
                          {{
                            isUserCompanyEnable
                              ? 'mdi-check-circle'
                              : 'mdi-close-circle'
                          }}
                        </v-icon>
                      </template>
                    </v-switch>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="toggleIsCompanyEnableDialog = false">{{
                      $t('general.cancel')
                    }}</v-btn>
                    <v-btn
                      :loading="isToggleIsCompanyEnableDialogLoading"
                      color="primary"
                      @click="onClickToggleIsCompanyEnable()"
                      >{{ $t('general.confirm') }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- removed all permissions -->
              <v-dialog
                v-model="removePermissionsDialog"
                persistent
                max-width="400px"
              >
                <template #activator="{ on }">
                  <v-btn
                    small
                    outlined
                    color="error"
                    :disabled="isButtonDisabled"
                    v-on="on"
                  >
                    <v-icon left small>mdi-delete-outline</v-icon>
                    {{ $t('remove_member') }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    {{ $t('company.remove_all_permission') }}
                  </v-card-title>
                  <v-card-text>
                    <p
                      v-html="
                        $t('company.are_you_sure_to_remove_all_permissions', {
                          userName: `<b> ${selectedItemsName} </b>`,
                        })
                      "
                    ></p>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="removePermissionsDialog = false">{{
                      $t('general.cancel')
                    }}</v-btn>
                    <v-btn
                      :loading="isRemovePermissionsDialogLoading"
                      color="error"
                      @click="onClickRemoveAllPermission()"
                      >{{ $t('general.confirm') }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- companyRole dialog -->
              <v-dialog
                v-model="companyRoleDialog.value"
                max-width="400px"
                persistent
              >
                <v-card :loading="companyRoleDialog.isLoading">
                  <v-toolbar flat dense>
                    {{ $t('company.edit_company_role') }} (
                    {{ companyRoleDialog.user.name }} )
                    <v-spacer></v-spacer>
                    <v-btn small icon @click="companyRoleDialog.value = false">
                      <v-icon small> mdi-close </v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-card-text class="mt-4">
                    <v-autocomplete
                      v-model="companyRoleDialog.user.companyRole"
                      :label="$t('company.company_role')"
                      :loading="companyRoleDialog.isLoading"
                      :placeholder="$t('company.company_role')"
                      :items="companyRolesI18n"
                      item-text="i18nName"
                      item-value="id"
                      multiple
                      return-object
                      chips
                      small-chips
                      outlined
                      hide-details
                      @change="onCompanyRoleChange"
                    >
                      <template #selection="{ item }">
                        <v-chip color="info" small>{{ $t(item.name) }}</v-chip>
                      </template>
                    </v-autocomplete>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </grid-toolbar>

            <template #item_name="{ props }">
              <td>
                {{ props.dataItem.name }}
              </td>
            </template>

            <template #item_isCompanyEnable="{ props }">
              <td>
                <v-icon
                  v-if="props.dataItem.isActive"
                  :color="
                    props.dataItem.isMainCompany
                      ? props.dataItem.isCompanyEnable
                        ? 'success'
                        : 'error'
                      : 'darkGrey'
                  "
                >
                  {{
                    props.dataItem.isCompanyEnable
                      ? 'mdi-check-circle'
                      : 'mdi-close-circle'
                  }}
                </v-icon>
                <span v-else>
                  {{ $t('unknown') }}
                </span>
              </td>
            </template>

            <template #item_isMainCompany="{ props }">
              <td>
                {{ props.dataItem.isMainCompany ? $t(`member`) : $t(`guest`) }}
              </td>
            </template>

            <template #item_companyRole="{ props }">
              <td :class="isDisabled(props.dataItem)">
                <v-chip
                  small
                  class="mr-1 mb-1"
                  v-for="(role, order) in props.dataItem.roles"
                  :key="`${props.dataItem.id}-${role.id}-${order}`"
                  color="info"
                  >{{ $t(role.name) }}</v-chip
                >
              </td>
            </template>

            <template #item_actions="{ props }">
              <td :class="isDisabled(props.dataItem) + ' ' + props.className">
                <v-btn
                  small
                  class="mr-4 mb-1"
                  color="primary"
                  @click="onClickUpdateCompanyRole(props.dataItem)"
                >
                  <v-icon left small>mdi-pencil</v-icon>
                  {{ $t('company.edit_company_role') }}
                </v-btn>

                <v-btn
                  small
                  color="primary"
                  class="mr-4 mb-1"
                  @click="onClickSetUserLicense(props.dataItem)"
                >
                  <!-- :disabled="!props.dataItem.isMainCompany" -->
                  <v-icon left small> mdi-cog-outline </v-icon>
                  {{ $t('set_licenses') }}
                </v-btn>

                <!-- set as employer -->
                <v-dialog
                  v-if="props.dataItem.companyId === null"
                  v-model="props.dataItem.setEmployerDialog"
                  persistent
                  max-width="400px"
                >
                  <template #activator="{ on }">
                    <v-btn small color="primary" class="mr-1" v-on="on">
                      <v-icon left small> mdi-account-plus-outline </v-icon>
                      {{ $t('set_employer') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      {{ $t('set_employer') }}
                    </v-card-title>
                    <v-card-text>
                      <p>
                        {{
                          $t('set {userName} as a company member', {
                            userName: props.dataItem.name,
                          })
                        }}
                      </p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        @click="props.dataItem.setEmployerDialog = false"
                        >{{ $t('general.cancel') }}</v-btn
                      >
                      <v-btn
                        :loading="props.dataItem.isSetEmployerDialogLoading"
                        color="primary"
                        @click="onClickSetEmployer(props.dataItem)"
                        >{{ $t('general.confirm') }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </td>
            </template>
          </grid>
          <UserSubscriptionLicensesDrawer
            v-model="userSubscriptionLicensesDrawer"
            :user="editedItem"
            :company-id="companyId"
          ></UserSubscriptionLicensesDrawer>
        </sb-ui-fullScreen>
      </intl-provider>
    </localization-provider>
  </v-card>
</template>

<script>
import { GridToolbar } from '@progress/kendo-vue-grid'
import { toDataSourceRequestString } from '@progress/kendo-data-query'
import FilterSearchBar from "@/components/FilterSearchBar.vue";
import AddOrInviteDialog from "@/components/companies/users/MainDialog.vue";
import UserSubscriptionLicensesDrawer from '@/components/userSubscriptionLicenses/Drawer.vue'

export default {
  name: 'SystemCompanyUser',
  meta: {
    type: 'company',
    functions: ['Company.Default.MenuItem.Write'],
    text: 'sidebar.company_user',
  },
  components: {
    GridToolbar,
    FilterSearchBar,
    AddOrInviteDialog,
    UserSubscriptionLicensesDrawer
  },
  data() {
    return {
      companyRoles: [],
      isFullscreen: false,
      loading: false,
      dataItemsObj: {
        aggregateResults: null,
        data: [],
        errors: null,
        total: 0,
      },
      dataState: {
        take: -1, //10,
        skip: 0,
        sort: [],
        filter: null,
        group: '',
      },
      resizable: true,
      sortable: true,
      columnMenu: true,
      pageable: false,
      // pageable: {
      //   buttonCount: 0,
      //   info: true,
      //   type: 'numeric',
      //   pageSizes: true,
      //   previousNext: true,
      // },
      selectedField: 'selected',
      addUserDialog: {
        title: '',
        input: '',
        isLoading: false,
        errMsg: '',
        error: false,
        submitObject: {
          id: '',
          type: 'company',
          name: '',
        },
      },
      isAddUserDialogVisible: false,
      removePermissionsDialog: false,
      isRemovePermissionsDialogLoading: false,
      toggleIsCompanyEnableDialog: false,
      isToggleIsCompanyEnableDialogLoading: false,
      isUserCompanyEnable: false,
      companyRoleDialog: {
        value: false,
        user: {
          id: null,
          name: '',
          companyRole: [],
        },
        isLoading: false,
      },
      userSubscriptionLicensesDrawer: false,
      editedItem: null,
    }
  },
  computed: {
    companyRolesI18n() {
      return this.companyRoles.map((role) => ({
        ...role,
        i18nName: this.$t(role.name),
      }))
    },
    companyId() {
      return this.$route.params.systemCompanyId;
    },
    companyName() {
      return this.$route.query.companyName
        ? this.$route.query.companyName
        : ''
    },
    API() {
      return this.$API.api.main
    },
    columns() {
      return [
        {
          title: null,
          field: 'selected',
          className: 'table-data--selected',
          width: 55,
          columnMenu: false,
        },
        {
          title: this.$t('company.user_email'),
          field: 'email',
          width: 256,
        },
        {
          title: this.$t('company.user_name'),
          field: 'name',
          editable: false,
          width: 200,
          cell: 'item_name',
        },
        {
          title: this.$t('company.is_company_enable'),
          field: 'isCompanyEnable',
          sortable: false,
          filter: 'boolean',
          width: 130,
          cell: 'item_isCompanyEnable',
        },
        {
          title: this.$t('is_main_company'),
          field: 'isMainCompany',
          sortable: true,
          filter: 'boolean',
          columnMenu: true,
          width: 170,
          cell: 'item_isMainCompany',
        },
        {
          title: this.$t('note'),
          field: 'note',
          width: 120,
          sortable: false,
          columnMenu: false,
        },
        {
          title: this.$t('company.company_role'),
          field: 'roles.name',
          sortable: false,
          columnMenu: false,
          cell: 'item_companyRole',
          width: 450,
          // hidden: this.$vuetify.breakpoint.mdAndDown,
        },
        {
          cell: 'item_actions',
          sortable: false,
          columnMenu: false,
          width: 300,
          locked: true,
        },
      ]
    },
    isSelected() {
      return this.dataItemsObj.data.filter((item) => item.selected).length !== 0
    },
    isButtonDisabled() {
      return !this.isSelected
    },
    isEmployeeSelected() {
      return this.selectedItemsExceptGuest.length > 0
    },
    selectedItems() {
      return this.dataItemsObj.data.filter((item) => item.selected)
    },
    selectedItemsName() {
      return this.selectedItems.map((item) => item.name).join(', ')
    },
    selectedItemsExceptGuest() {
      return this.dataItemsObj.data.filter(
        (item) => item.selected && item.isMainCompany
      )
    },
    selectedItemsNameExceptGuest() {
      return this.selectedItemsExceptGuest.map((item) => item.name).join(', ')
    },
  },
  watch: {
    companyId: {
      immediate: true,
      async handler(companyId) {
        if (companyId) {
          this.getData()
          try {
            const response = await this.API.companyRole.role(companyId)
            this.companyRoles = response.data
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Error', err)
            this.setAlert('error', this.$t('Error'))
          }
        }
      },
    },
  },
  methods: {
    onSearch() {
      this.dataState.skip = 0
      this.getData()
    },
    async toggleFullscreen() {
      this.$refs.fullscreen.toggle()
      await this.$nextTick()
      this.isFullscreen = !this.isFullscreen
    },
    extendData(items) {
      return items.map((item) => ({
        ...item,
        selected: false,
        setEmployerDialog: false,
        isSetEmployerDialogLoading: false,
      }))
    },
    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField]
    },
    onHeaderSelectionChange(event) {
      const checked = event.event.target.checked
      this.dataItemsObj.data = this.dataItemsObj.data.map((item) => {
        return { ...item, selected: checked }
      })
    },
    dataStateChange(event) {
      this.createAppState(event.data)
    },
    createAppState(dataState) {
      this.dataState = Object.assign({}, dataState)
      this.getData()
    },
    async getData() {
      if (!this.companyId) {
        return
      }
      this.loading = true
      let resultsObj = {
        aggregateResults: null,
        data: [],
        errors: null,
        total: 0,
      }
      try {
        const queryStr = toDataSourceRequestString(this.dataState) // Serialize the state
        const urlencoded = new URLSearchParams(queryStr)

        const response = await this.API.companyUser.postUser(
          this.companyId,
          urlencoded
        )
        resultsObj = response.data
        this.loading = false
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
      resultsObj.data = this.extendData(resultsObj.data)
      this.dataItemsObj = resultsObj
      // this.dataState.take = resultsObj.total;
    },
    onOpenAddUserDialog() {
      this.isAddUserDialogVisible = true
      this.addUserDialog.submitObject.name = this.companyName
      this.addUserDialog.submitObject.id = this.companyId
    },
    isDisabled(dataItem) {
      return { 'sb-disabled': !dataItem.isCompanyEnable }
    },
    async onClickToggleIsCompanyEnable() {
      this.isToggleIsCompanyEnableDialogLoading = true
      try {
        for (let i = 0; i < this.selectedItemsExceptGuest.length; i++) {
          await this.updateIsCompanyEnable(
            this.selectedItemsExceptGuest[i],
            this.isUserCompanyEnable
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.getData()
        this.isToggleIsCompanyEnableDialogLoading = false
        this.toggleIsCompanyEnableDialog = false
      }
    },
    async updateIsCompanyEnable(dataItem, isCompanyEnable) {
      this.$set(dataItem, 'loading', true)
      const userId = dataItem.id
      const enable = isCompanyEnable
      try {
        await this.API.companyUser.postEnable(this.companyId, userId, enable)
        this.setAlert('success', this.$t('dialog.update_successfully'))
      } catch (err) {
        this.setAlert('error', `${this.$t('dialog.update_failed')}:${err}`)
        console.error(err)
      }
      this.$set(dataItem, 'loading', false)
    },
    async onClickRemoveAllPermission() {
      this.isRemovePermissionsDialogLoading = true
      try {
        for (let i = 0; i < this.selectedItems.length; i++) {
          await this.removeAllPermission(this.selectedItems[i])
          if(this.selectedItems[i].isMainCompany){
            await this.removeUserCompanyId(this.selectedItems[i])
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.getData()
        this.isRemovePermissionsDialogLoading = false
        this.removePermissionsDialog = false
      }
    },
    removeAllPermission(dataItem) {
      return this.API.companyUser
        .deleteRoles(this.companyId, dataItem.id)
        .then((res) => res.data)
        .then(() => {
          this.setAlert('success', this.$t('dialog.update_successfully'))
        })
        .catch((err) => {
          console.error('ERROR:', err)
          this.setAlert('error', this.$t('dialog.update_failed'))
        })
    },
    removeUserCompanyId(dataItem) {
      return this.API.companyUser
        .deleteUser(this.companyId, dataItem.id)
        .then((res) => res.data)
        .then(() => {
          this.setAlert('success', this.$t('dialog.delete_successfully'))
        })
        .catch((err) => {
          console.error('ERROR:', err)
          this.setAlert('error', this.$t('dialog.delete_failed'))
        })
    },
    onClickUpdateCompanyRole(user) {
      this.companyRoleDialog.value = true
      this.companyRoleDialog.user.id = user.id
      this.companyRoleDialog.user.name = user.name
      this.companyRoleDialog.user.companyRole = user.roles
    },
    onCompanyRoleChange(vals) {
      const gridUserItem = this.dataItemsObj.data.find(
        (item) => item.id === this.companyRoleDialog.user.id
      )

      const gridUserCompanyRoleIds = gridUserItem.roles.map((item) => item.id)
      const userCompanyRoleIds = vals.map((item) => item.id)

      this.companyRoleDialog.isLoading = true
      const gridUserItemIndex = this.dataItemsObj.data.findIndex(
        (item) => item.id === this.companyRoleDialog.user.id
      )
      // add
      if (userCompanyRoleIds.length > gridUserCompanyRoleIds.length) {
        const newRoleId = userCompanyRoleIds.find(
          (role) => !gridUserCompanyRoleIds.includes(role)
        )
        const queryObj = {
          UserId: this.companyRoleDialog.user.id,
          RoleId: newRoleId,
          CompanyId: this.companyId,
        }
        this.API.companyRole
          .post(queryObj.UserId, queryObj.CompanyId, queryObj.RoleId)
          .then(() => {
            const addedRole = this.companyRoles.find(
              (role) => role.id === newRoleId
            )
            this.dataItemsObj.data[gridUserItemIndex].roles.push(addedRole)
            this.setAlert('success', this.$t('dialog.update_successfully'))
            this.companyRoleDialog.isLoading = false
          })
          .catch((err) => {
            this.setAlert('error', this.$t('dialog.update_failed'))
            this.companyRoleDialog.isLoading = false
            console.error('error:', err)
          })

        // remove
      } else {
        const removedRoleId = gridUserCompanyRoleIds.find(
          (role) => !userCompanyRoleIds.includes(role)
        )

        const queryObj = {
          UserId: this.companyRoleDialog.user.id,
          RoleId: removedRoleId,
          CompanyId: this.companyId,
        }
        this.API.companyRole
          .delete(queryObj.UserId, queryObj.CompanyId, queryObj.RoleId)
          .then(() => {
            const removedRoleIndex = this.dataItemsObj.data[
              gridUserItemIndex
            ].roles.findIndex((role) => role.id === removedRoleId)
            this.dataItemsObj.data[gridUserItemIndex].roles.splice(
              removedRoleIndex,
              1
            )
            this.setAlert('success', this.$t('dialog.update_successfully'))
            this.companyRoleDialog.isLoading = false
          })
          .catch((err) => {
            this.setAlert('error', this.$t('dialog.update_failed'))
            this.companyRoleDialog.isLoading = false
            console.error('error:', err)
          })
      }
    },
    onClickSetUserLicense(dataItem) {
      this.userSubscriptionLicensesDrawer = true
      this.editedItem = Object.assign({}, dataItem)
    },
    async onClickSetEmployer(companyMember) {
      companyMember.isSetEmployerDialogLoading = true
      try {
        await this.$API.api.main.companyUser.postEmploy(
          this.companyId,
          companyMember.id
        )
        companyMember.setEmployerDialog = false
        const snackbar = {
          type: 'success',
          message: this.$t('dialog.modify_successfully'),
          value: true,
          timeout: 4000,
        }
        this.$store.commit('notification/SET_ALERT', snackbar)
        this.getData()
      } catch (error) {
        const snackbar = {
          type: 'error',
          message: this.$t('dialog.modify_failed'),
          value: true,
          timeout: 4000,
        }
        this.$store.commit('notification/SET_ALERT', snackbar)
        console.error(error)
      } finally {
        companyMember.isSetEmployerDialogLoading = false
      }
    },
    
    onAddCompanyUser(userId, companyId, setEmployedBy = false) {
      return this.API.companyUser
        .postUserId(companyId, userId, setEmployedBy)
        .then(() => {
          this.setAlert('success', this.$t('dialog.add_successfully'), 6000)
          this.getData()
        })
        .catch((error) => {
          this.setAlert(
            'error',
            `${this.$t('dialog.add_failed')}: ${error.response.data}`
          )
          console.error('Error:', error)
        })
    },
    onClickToggleIsCompanyEnableDialog() {
      this.toggleIsCompanyEnableDialog = true
      this.isUserCompanyEnable = this.selectedItemsExceptGuest.map(item=> item.isCompanyEnable).includes(true)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .table-data--selected {
  position: relative;

  .k-checkbox {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .fullScreen {
    height: 100vh;
    width: 100vw;
  }
}
</style>
