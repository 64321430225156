


<template>
    <v-card outlined>
        <v-card-title>
            <v-icon left>mdi-folder-cog-outline</v-icon>
            {{$t("System.SystemVersions.SystemVersionsList")}}
            <v-spacer></v-spacer>
            <v-text-field 
                class='search-bar mr-4'
                outlined
                v-model='searchText'
                dense
                hide-details
                :placeholder="$t('System.SystemVersions.please_use_versions_description_or_username_to_search')"
                append-icon='mdi-magnify'
                @keyup.13="searching"
                @click:append="searching"></v-text-field>
            <SystemVersionsUploadDialog :dialog.sync='uploadDialog' @upload:success='onUploadDialogSuccess'></SystemVersionsUploadDialog>
        </v-card-title>
        <SystemVersionsGrid ref='SystemVersionsGrid'
            @edit:dataItem="onEditDataItem"
            @delete:dataItem='onDeleteDataItem'
            @publish:dataItem='onPublishDataItem'
            :search-text='gridSearchText'
            >
        </SystemVersionsGrid>
        <SystemVersionsEditDialog :dialog.sync='editDialog' :data-item='dataItem' @edit:success="onEditDialogSuccess"></SystemVersionsEditDialog>
        <SystemVersionsDeleteDialog :dialog.sync='deleteDialog' :data-item='dataItem' @delete:success="onDeleteDialogSuccess"></SystemVersionsDeleteDialog>
        <SystemVersionsPublishDialog :dialog.sync='publishDialog' :data-item='dataItem' @publish:success="onPublishDialogSuccess"></SystemVersionsPublishDialog>
    </v-card>
</template>

<script>
import SystemVersionsGrid from "@/components/systemVersions/SystemVersionsGrid.vue"
import SystemVersionsUploadDialog from "@/components/systemVersions/SystemVersionsUploadDialog.vue"
import SystemVersionsEditDialog from "@/components/systemVersions/SystemVersionsEditDialog.vue"
import SystemVersionsDeleteDialog from "@/components/systemVersions/SystemVersionsDeleteDialog.vue"
import SystemVersionsPublishDialog from "@/components/systemVersions/SystemVersionsPublishDialog.vue"
export default {
    name:"systemVersions",
    components:{
        SystemVersionsGrid,
        SystemVersionsUploadDialog,
        SystemVersionsEditDialog,
        SystemVersionsDeleteDialog,
        SystemVersionsPublishDialog
    },
    data(){
        return {
            uploadDialog:false,
            editDialog:false,
            deleteDialog:false,
            publishDialog:false,
            dataItem:null,
            searchText:null,
            gridSearchText:null
        }
    },
    methods:{
        onEditDataItem(dataItem){
            this.dataItem = dataItem;
            this.editDialog = true;
        },
        onDeleteDataItem(dataItem){
            this.dataItem = dataItem;
            this.deleteDialog = true;
        },
        onPublishDataItem(dataItem){
            this.dataItem = dataItem;
            this.publishDialog = true;
        },
        onEditDialogSuccess(){
            this.dataItem=null;
            this.$refs["SystemVersionsGrid"].getData()
        },
        onUploadDialogSuccess(){
            this.$refs["SystemVersionsGrid"].getData()
        },
        onDeleteDialogSuccess(){
            this.dataItem=null;
            this.$refs["SystemVersionsGrid"].getData()
        },
        onPublishDialogSuccess(){
            this.dataItem=null;
            this.$refs["SystemVersionsGrid"].getData()
        },
        searching(){
            this.gridSearchText = this.searchText
        }
    }
}
</script>

<style scoped>
.search-bar{
    width: 280px;
    max-width: 280px;
}
</style>