<template>
  <v-menu :nudge-right="32" bottom>
    <template #activator="{ on }">
      <v-btn icon @click.stop v-on="on" x-small>
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(listItem) in editListItems"
        :key="item.id+listItem.title"
        @click="listItem.onClick?listItem.onClick(item):''"
      >
        <v-list-item-title>{{ listItem.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    editListItems: {
      type: Array,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  }
};
</script>