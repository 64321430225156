<template>
  <sb-shared-menus-grid :customCommand='customCommand'>
    <template #custom_command="{props}">
            <v-btn 
              small
              color="primary"
              :to="{ name:'MenuMenuItems' , params:{ menuId: props.dataItem.id } }"
            >
                <v-icon small left>
                    mdi-cog-outline
                </v-icon>
                設定選項
            </v-btn>
        </template>
  </sb-shared-menus-grid>
</template>

<script>

export default {
  name:"MenuManage",
  components:{
  },
  data(){
    return {
      customCommand:{
        hidden:false
      }
    }
  }
}
</script>

<style>

</style>