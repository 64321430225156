<template>
    <v-dialog v-model="modelValue" width="500" class="text-center" persistent>
      <v-card>
        <v-card-title>
          {{'新增'}}
          <v-spacer></v-spacer>
          <v-btn icon class="ml-2" @click="close">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-tabs
          v-model="tab"
          background-color="primary"
          dark
        >
          <v-tab
            v-for="item in items"
            :key="item.tab"
          >
            {{ item.tab }}
          </v-tab>
          <v-spacer></v-spacer>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
          >
            <component :is="item.component"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
</template>


<script>

import { mapGetters, mapMutations } from "vuex";
import AddContent from "./AddContent";
import InviteContent from "./InviteContent.vue";

export default {
  name: "SbCreateUserDialog",
  components: {
    AddContent,
    InviteContent
  },
  data(){
    return {
      tab: null,
      items: [
        { tab: '新增', component: 'AddContent' },
        { tab: '邀請', component: 'InviteContent' },
      ],
    }
  },
  computed: {
    ...mapGetters("sbCreateUser", ["createUserDialog"]),
    modelValue: {
      get() {
        return this.createUserDialog.value;
      },
      set(value) {
        this.SET_CREATEUSER_DIALOG({ value: value });
      }
    }
  },
  watch: {
    createUserDialog: {
      handler(val) {
        if (val.value) {
          this.$store.commit("sbCreateUser/SET_CR_APICONFIG", this.$API.config);
          this.$store.dispatch("sbCreateUser/getSystemRoles");
          this.$store.dispatch("sbCreateUser/getSystemCompany");
        }
      },
      deep: true
    }
  },
  methods:{
    ...mapMutations("sbCreateUser", ["SET_CREATEUSER_DIALOG"]),
    close() {
      this.SET_CREATEUSER_DIALOG({ value: false });
    }
  }
};
</script>

