<i18n lang="json">
{
  "zh-Hant": {
    "filter": "篩選",
    "clear": "清除"
  },
  "en": {
    "filter": "篩選",
    "clear": "清除"
  }
}
</i18n>

<template>
  <div ref="header" class="kendoHeader" @click="sorting()">
    <!-- 標題 -->
    {{ title }}
    <!-- 排序符號 -->
    <span v-if="sortcase === 'ascending'" class="k-icon k-i-sort-asc-sm"></span>
    <span v-if="sortcase === 'descending'" class="k-icon k-i-sort-desc-sm"></span>
    <!-- filter menu -->
    <v-menu v-model="value" offset-y :close-on-content-click="false" min-width="400">
      <template v-slot:activator="{ on }">
        <v-btn icon small class="ma-0 mt-1 pa-0" absolute style="right: 1rem; top: 0.5rem" v-on="on">
          <v-icon size="1.2rem">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <!-- Sort Ascending -->
        <v-list-item v-if="!headerOptions.hideSorting" @click="ascending()">
          <v-list-item-icon>
            <v-icon>mdi-sort-ascending</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sort Ascending</v-list-item-title>
        </v-list-item>
        <!-- Sort Descending -->
        <v-list-item v-if="!headerOptions.hideSorting" @click="descending()">
          <v-list-item-icon>
            <v-icon>mdi-sort-descending</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sort Descending</v-list-item-title>
        </v-list-item>
        <!-- Lock -->
        <v-list-item v-if="!headerOptions.hideLocking" @click="switchLock()">
          <v-list-item-icon>
            <v-icon>mdi-lock-open</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Lock</v-list-item-title>
        </v-list-item>

        <v-divider v-if="!headerOptions.hideLocking || !headerOptions.hideSorting" class="ma-0 pa-0" />
        <!-- foreign key選擇器 -->
        <v-list-item>
          <v-autocomplete
            v-model="selectValue"
            filled
            dense
            hide-details
            :items="values"
            :item-text="dataField"
            :placeholder="placeholder"
            return-object
          ></v-autocomplete>
        </v-list-item>
        <v-list-item class="justify-start">
          <v-btn depressed class="mr-3" @click="clear()">
            {{ $t("clear") }}
          </v-btn>
          <v-btn :disabled="isFilterDisabled" depressed color="primary" @click="filter()">
            {{ $t("filter") }}
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "sb_ui_kendo_objectHeader",
  props: {
    field: String, // kendo預設
    title: String, // kendo預設
    sortable: [Boolean, Object], // kendo預設
    render: Object
  },
  data() {
    return {
      value: false,
      selectValue: null,
      locked: false,
      menuItems: [
        { title: "Sort Ascending", icon: "mdi-sort-ascending" },
        { title: "Sort Descending", icon: "mdi-sort-descending" },
        { title: "Lock", icon: "mdi-lock-open" }
      ],
      sortmap: ["none", "ascending", "descending"],
      sort: 0
    }
  },
  computed: {
    column() {
      // TODO: optimize get column method
      var columns = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.columns
      var data = columns ? columns.find((item) => item.field === this.field) : {}
      return data
    },
    sortcase() {
      return this.sortmap[this.sort]
    },
    /** 回傳dataPicker 要顯示的欄位 */
    dataField() {
      return this.column.dataField
    },
    /** 回傳搜尋用的欄位 */
    dataFilterField() {
      return this.column.dataFilterField
    },
    operator() {
      if (this.column.operator) {
        return this.column.operator
      }
      return "contains"
    },
    placeholder() {
      if (this.column.placeholder) {
        return this.column.placeholder
      }
      return ""
    },
    /** header設定 */
    headerOptions() {
      var data = this.column
      var options = data.headerOptions
      if (!options) {
        // 回傳預設
        return {
          hideSorting: false,
          hideLocking: false
        }
      }
      return options
    },
    values() {
      return this.column ? this.column.values : []
    },
    isFilterDisabled() {
      return this.selectValue === null
    }
  },
  // mounted() {
  //   this.getColumnValues()
  // },
  methods: {
    /** 預設排序 */
    nonesorting() {
      this.sort = 0
      this.$emit("datastatechange", { type: "sort", data: [] })
    },
    /** 升冪排序 */
    ascending() {
      if (this.sortcase === "ascending") {
        this.nonesorting()
        return
      }
      this.sort = 1
      var sort = { dir: "asc", field: this.field }
      this.$emit("datastatechange", { type: "sort", data: [sort] })
    },
    /** 降冪排序 */
    descending() {
      if (this.sortcase === "descending") {
        this.nonesorting()
        return
      }
      this.sort = 2
      var sort = { dir: "desc", field: this.field }
      this.$emit("datastatechange", { type: "sort", data: [sort] })
    },
    switchLock() {
      var data = this.column

      var locked = data.locked !== undefined ? data.locked : true
      this.$emit("columnSettingChange", { field: this.field, locked: !locked })
    },
    sorting() {
      if (!this.sortable) {
        return false
      }
      this.sort += 1
      if (this.sort > this.sortmap.length - 1) {
        this.sort = 0
      }
      switch (this.sortcase) {
        case "none":
          this.nonesorting()
          break
        case "ascending":
          this.ascending()
          break
        case "descending":
          this.descending()
          break
        default:
          break
      }
    },
    clear() {
      this.selectValue = null
      this.value = false
      var filters = {
        filters: [],
        logic: "and"
      }
      this.$emit("sendFilter", filters)
    },
    filter() {
      var filters = {
        filters: [
          {
            field: this.field,
            operator: this.operator,
            value: this.selectValue[this.dataFilterField]
          }
        ],
        logic: "and"
      }
      console.log("sendFilter", filters)
      this.$emit("sendFilter", filters)
      this.value = false
    }
    /** 取得column資訊 */

    // async getColumnValues() {
    //   var data = this.column
    //   if (data.getDatafunction) {
    //     this.values = await data.getDatafunction()
    //   }
    // }
  }
}
</script>

<style></style>
