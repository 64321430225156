<i18n>
{
  "zh-Hant":{
    "authorization":"授權",
    "remain":"剩餘"
  },
  "en":{
    "authorization":"Authorization",
    "remain":"remain"
  }
}
</i18n>


<template>
  <v-navigation-drawer v-model='drawer' width="500" fixed right temporary stateless>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon size='56'>
            mdi-account-circle
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content v-if='user'>
          <v-list-item-title class="text-h6">
            {{user.name}}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{user.email}}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon @click='drawer=false'>
            mdi-close
          </v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-card flat>
        <v-card-text :key="userId">
          <div v-if="isUsersSubscriptionLicensesLoading" class="k-loading-mask">
            <span class="k-loading-text"></span>
            <div class="k-loading-image" />
            <div class="k-loading-color" />
          </div>
          <!-- <div class='mb-2'>
            {{$t("authorization")}}
          </div> -->

          <div>
            <p>
              <b class='mb-4'>方案</b>
            </p>
            <div
              v-for="(companyPlan) in  companyPlans"
              :key="`${companyPlan.id}-${companyPlan.subscriptionId}`"
            >
              <v-checkbox
                v-model='companyPlan.isUserEnable'
                class='mt-0'
                hide-details
                :disabled='companyPlan.usedAmount===companyPlan.amount&&!companyPlan.isUserEnable'
                @change="onCompanyPlanCheckboxChange(companyPlan, $event)"
              >
                <template #label>
                  <!-- {{companyPlan.isUserEnable}} -->
                  <div>
                   <p class='mb-1'>
                    <b>{{companyPlan.name}}</b>
                   </p>
                   <p class='mb-2 caption'>
                    {{companyPlan.usedAmount}} / {{companyPlan.amount}} 個已使用的方案 
                   </p>
                  </div>
                  <!-- ( {{companyPlan.amount - companyPlan.usedAmount}} available) -->
                </template>
              </v-checkbox>
            </div>
          </div>

          <v-divider class='my-4'></v-divider>
          <div>
            <div class='mb-2'>
              <b>授權</b>
            </div>
            <v-autocomplete 
              v-model="selectedSubscriptionId"
              outlined
              dense
              item-value="subscriptionId"
              placeholder="請選擇指派的方案"
              :item-text="(item)=>`${item.name}`"
              :items="userPlanSubscriptions"
            ></v-autocomplete>
            <!-- ( ${item.subscriptionId} ) -->
            <v-checkbox
              v-for="(license) in  licenses"
              :key="`${license.id}-${license.subscriptionId}-${userSubscriptionLicensesNames.indexOf(license.name)!==0}`"
              v-model='license.isUserEnable'
              class='mt-0'
              hide-details
              @change="onLicenseCheckboxChange(license, $event)"
            >
              <template #label>
                <div>
                   <p class='mb-1'>
                    <b>{{license.name}}</b>
                   </p>
                   <p class='mb-2 caption'>
                    {{license.planName}}
                   </p>
                </div>
              </template>
            </v-checkbox>
          </div>      
        </v-card-text>
      </v-card>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "UserSubscriptionLicensesDrawer",
  props: {
    value: {
      type: Boolean
    },
    user: {
      type: Object
    },
    companyId: {
      type: String
    }
  },
  data() {
    return {
      licenses: [],
      userSubscriptionsLicenses: [],
      isUsersSubscriptionLicensesLoading: false,
      companyPlans: [],
      userPlanSubscriptions: [],
      selectedSubscriptionId:null
    };
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(drawer) {
        this.$emit("input", drawer);
      }
    },
    userId() {
      return this.user ? this.user.id : null;
    },
    userSubscriptionLicensesNames() {
      return this.userSubscriptionsLicenses.map(
        (subscriptionLicense) => subscriptionLicense.name
      );
    }
  },
  watch: {
    userSubscriptionsLicenses: {
      immediate: true,
      handler(userSubscriptionsLicenses) {
        for (let i = 0; i < this.licenses.length; i++) {
          const userSubscriptionsLicensesNames = userSubscriptionsLicenses.map(
            (item) => item.name
          );
          this.licenses[i].isUserEnable =
            userSubscriptionsLicensesNames.indexOf(this.licenses[i].name) !==
            -1;
        }
      }
    },
    userPlanSubscriptions:{
      immediate: true,
      handler(userPlanSubscriptions) {
        for (let i = 0; i < this.companyPlans.length; i++) {
          const userPlanSubscriptionIds = userPlanSubscriptions.map(
            (item) => item.subscriptionId
          );
          this.companyPlans[i].isUserEnable =
            userPlanSubscriptionIds.indexOf(this.companyPlans[i].subscriptionId) !==
            -1;
        }
      }
    },
    drawer: {
      immediate: true,
      async handler(drawer) {
        const htmlDom = document.querySelector('html')
        if (drawer) {
          htmlDom.classList.add('overflow-hidden')
          this.companyPlans = await this.getCompaniesPlans()
          this.userPlanSubscriptions = await this.getUsersSubscriptionPlans(this.user.id)
          this.selectedSubscriptionId = 0
        }else{
          htmlDom.classList.remove('overflow-hidden')
          this.selectedSubscriptionId = null
          this.companyPlans = []
          this.userPlanSubscriptions = []
        }
      }
    },
    selectedSubscriptionId:{
      immediate: false,
      async handler(selectedSubscriptionId){
        this.updateLicenses(selectedSubscriptionId)
      }
    }
  },
  methods: {
    async getSubscriptionLicenses(subscriptionId) {
      let results = [];
      try {
        const response = await this.$API.api.main.subscriptionsLicenses.get(
          subscriptionId
        );
        results = response.data.map((License) => ({
          ...License,
          isUserEnable: false,
          subscriptionId
        }));
      } catch (error) {
        console.error(error);
      }
      return results;
    },
    async getUsersSubscriptionLicenses(subscriptionId) {
      let results = [];
      try {
        this.isUsersSubscriptionLicensesLoading = true;
        const response = await this.$API.api.main.usersSubscriptionLicenses.get(
          this.userId
        );
        results = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isUsersSubscriptionLicensesLoading = false;
      }
      if(subscriptionId){
        results = results.filter(license=>license.subscriptionId === subscriptionId)
      }
      return results;
    },
    async onLicenseCheckboxChange(license, value) {
      if (value) {
        try {
          this.isUsersSubscriptionLicensesLoading = true;

          await this.$API.api.main.V1.subscriptionsLicenses.postLicenseUser(
            license.subscriptionId,
            license.id,
            this.user.id
          );
          this.setAlert("success", this.$t("dialog.update_successfully"));
        } catch (error) {
          console.error(error);
          this.setAlert("error", this.$t("dialog.update_failed"));
        } finally {
          this.isUsersSubscriptionLicensesLoading = false;
        }
      } else {
        try {
          this.isUsersSubscriptionLicensesLoading = true;
          await this.$API.api.main.subscriptionsLicenses.deleteLicenseUser(
            license.subscriptionId,
            license.id,
            this.user.id
          );
          this.setAlert("success", this.$t("dialog.update_successfully"));
        } catch (error) {
          console.error(error);
          this.setAlert("error", this.$t("dialog.update_failed"));
        } finally {
          this.isUsersSubscriptionLicensesLoading = false;
        }
      }
    },
    async getCompaniesPlans(){
      let results = null
      try{
        const response = await this.$API.api.main.V1.companiesPlans.get(this.companyId)
          results = response.data.map((subscriptionPlan) => ({
          ...subscriptionPlan,
          isUserEnable: false
        }));
        results = response.data
      }catch(err){
        console.error(err)
      }
      return results
    },
    async getUsersSubscriptionPlans(userId){
      let results = [{id:0, subscriptionId:0, name: "所有方案"}]
      try {
        const response = await this.$API.api.main.V1.usersSubscriptionPlans.get(userId)
        results = [...results, ...response.data]
      }catch(err){
        console.log(err)
      }
      return results
    },
    async onCompanyPlanCheckboxChange(plan, value){
      if (value) {
        try {
          this.isUsersSubscriptionLicensesLoading = true;

          await this.$API.api.main.V1.subscriptionsPlans.postUsers(
            plan.subscriptionId,
            plan.id,
            this.user.id
          );

          this.userPlanSubscriptions = await this.getUsersSubscriptionPlans(this.user.id);
          const planIndex = this.companyPlans.findIndex(
            (p) => p.subscriptionId === plan.subscriptionId
          );

          this.companyPlans[planIndex].usedAmount++;
          this.setAlert("success", this.$t("dialog.update_successfully"));
        } catch (error) {
          console.error(error);
          this.setAlert("error", this.$t("dialog.update_failed"));
        } finally {
          this.isUsersSubscriptionLicensesLoading = false;
        }
      } else {
        try {
          this.isUsersSubscriptionLicensesLoading = true;
          await this.$API.api.main.V1.subscriptionsPlans.deleteUsers(
            plan.subscriptionId,
            plan.id,
            this.user.id
          );

          this.userPlanSubscriptions = await this.getUsersSubscriptionPlans(this.user.id);
          const planIndex = this.companyPlans.findIndex(
            (p) => p.subscriptionId === plan.subscriptionId
          );

          this.companyPlans[planIndex].usedAmount--;
          this.setAlert("success", this.$t("dialog.update_successfully"));
        } catch (error) {
          console.error(error);
          this.setAlert("error", this.$t("dialog.update_failed"));
        } finally {
          this.isUsersSubscriptionLicensesLoading = false;
        }
      }
      this.updateLicenses(this.selectedSubscriptionId)
    },
    async updateLicenses(selectedSubscriptionId){
        if(selectedSubscriptionId){
          const plan = this.companyPlans.find(p=>p.subscriptionId === selectedSubscriptionId)
          const planLicenses = await this.getSubscriptionLicenses(selectedSubscriptionId)
          this.licenses =  planLicenses.map(l=>({...l, planName: plan.name}))
          this.userSubscriptionsLicenses = await this.getUsersSubscriptionLicenses(selectedSubscriptionId);
        }else{
          this.isUsersSubscriptionLicensesLoading = true;
          let buffer = []
          const isUserEnableCompanyPlans = this.companyPlans.filter(p=>p.isUserEnable)
          for(let i=0; i< isUserEnableCompanyPlans.length ;i++ ){
            const plan = isUserEnableCompanyPlans[i]
            const {subscriptionId}  = plan
            const companyPlansLicenses = await this.getSubscriptionLicenses(subscriptionId)
            this.isUsersSubscriptionLicensesLoading = true;
            const companyPlansLicensesWithPlanName = companyPlansLicenses.map(l=>({...l, planName: plan.name}))
            buffer = [...buffer, ...companyPlansLicensesWithPlanName]
          }
          this.licenses = buffer
          this.userSubscriptionsLicenses = await this.getUsersSubscriptionLicenses();
          this.isUsersSubscriptionLicensesLoading = false;
        }
    }
  }
};
</script>

<style lang="scss"  scoped >
.v-input--selection-controls ::v-deep .v-input__slot{
  align-items: flex-start;
}
</style>

<style>
.overflow-hidden {
  overflow: hidden !important;
}
</style>