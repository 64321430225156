import { mapMutations } from 'vuex';

export default {
    methods: {
        ...mapMutations('notification', ['SET_DIALOG', 'SET_ALERT']),
        setAlert(type, message) {
            this.SET_ALERT({
                type,
                message,
                value: true
            })
        },
        setDialog(title, message) {
            this.SET_DIALOG({
                title,
                message,
                value: true
            })
        }
    }

}