<template>
    <v-card flat :loading='loading'>
        <div v-if="loading" class="k-loading-mask">
            <span class="k-loading-text"></span>
            <div class="k-loading-image" />
            <div class="k-loading-color" />
        </div>
        <localization-provider :language="$i18n.locale">
            <intl-provider :locale="$i18n.locale">
                <grid
                    :data-items="dataItems"
                    :skip="dataState.skip"
                    :take="dataState.take"
                    :filter="dataState.filter"
                    :sort="dataState.sort"
                    :resizable="resizable"
                    :sortable="sortalbe"
                    :pageable="pageable"
                    :column-menu="columnMenu"
                    :columns="columns"
                    style="height:100%;"
                    @datastatechange="dataStateChange"
                >
                <template #grid_userName='{ props }'>
                    <td>
                        <sb-ui-systemUser :userInfo="{name: props.dataItem.userName}" />
                    </td>
                </template>

                <template #grid_connectedDate='{ props }'>
                    <td>
                        <sb-ui-time-cell v-if='isNotNull(props.dataItem.connectedDate)' :time='props.dataItem.connectedDate'></sb-ui-time-cell>
                    </td>
                </template>

                <template #grid_detail="{ props }">
                    <td>
                        <v-btn :to="{ name:'OnlineUser', params:{userId: props.dataItem.id}}" class='mr-3' small color='primary'>
                            <v-icon left small>mdi-transit-connection-variant</v-icon>
                            連接數資訊</v-btn>
                        <v-btn :to="{ name:'OnlineUserHistory', params:{userId: props.dataItem.id}}" small color='info'>
                            <v-icon left small>mdi-history</v-icon>
                            歷史資訊</v-btn>
                    </td>
                </template>

                    <grid-no-records>
                        {{ $t("general.no_records") }}
                    </grid-no-records>
                </grid>
            </intl-provider>
        </localization-provider>
    </v-card>
</template>

<script>
import axios from "axios";
import { toDataSourceRequestString } from "@progress/kendo-data-query";

export default {
  created() {
    this.getData();
  },
  computed: {
    mainBaseUrl() {
      return this.$API.config.mainBaseUrl;
    },
    authorizationHeaders() {
      return {
        Authorization: `bearer ${this.$API.config.token}`
      };
    },
    columns() {
      return [
        {
          field: "connectionCount",
          title: "連接數"
        },
        {
          field: "companyName",
          title: "公司名稱"
        },
        {
          field: "userName",
          cell: "grid_userName",
          title: "使用者名稱"
        },
        {
          field: "connectedDate",
          cell: "grid_connectedDate",
          title: "建立連接時間"
        },
        {
          field: "ipAddress",
          title: "IP位址"
        },
        {
          cell: "grid_detail"
        }
      ];
    }
  },
  data() {
    return {
      dataState: {
        skip: 0,
        take: 15,
        sort: [{ field: "connectionCount", dir: "desc" }],
        filter: null
      },
      sortalbe: true,
      resizable: false,
      pageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: true,
        previousNext: true
      },
      columnMenu: true,
      loading: false,
      dataItems: null
    };
  },
  methods: {
    async getData() {
      this.loading = true;
      const queryStr = toDataSourceRequestString(this.dataState); // Serialize the state
      this.dataItems = await axios({
        method: "get",
        url: `${this.mainBaseUrl}/onlineUser?${queryStr}`,
        headers: this.authorizationHeaders
      })
        .then((res) => {
          return res.data;
        })
        .catch((err) => err)
        .finally(() => {
          this.loading = false;
        });
    },
    dataStateChange(event) {
      this.createAppState(event.data);
    },
    createAppState(dataState) {
      this.dataState = dataState;
      this.getData();
    },
    isNotNull(timeString) {
      return timeString !== "0001-01-01T00:00:00+00:00" ? timeString : null;
    }
  }
};
</script>