//src/layouts/navDrawerMenuItems.js

export default [
    {
        id: "system companies",
        header: "",
        text: "sidebar.system.companies_setting",
        name: "SystemCompany",
        icon: "mdi-briefcase-outline",
        visible: true,
        subMenuItems: [],
        meta: {
            type: "system",
            functions: ["System.Default.Company.Read"]
        }
    }, {
        id: "system users",
        header: "",
        text: "sidebar.system.users_setting",
        name: "SystemUser",
        icon: " mdi-account-group-outline",
        visible: true,
        subMenuItems: [],
        meta: {
            type: "system",
            functions: ["System.Default.Company.Read"]
        }
    }, {
        id: "role Function",
        header: "",
        text: "sidebar.system.role_setting",
        name: "RoleFunctions",
        icon: "mdi-head-cog-outline",
        visible: true,
        subMenuItems: [],
        meta: {
            type: "system",
            functions: ["System.Default.Company.Read"]
        }
    },{
        id: "subscriptions",
        header: "",
        text: "sidebar.system.subscriptions",
        name: "Subscriptions",
        icon: "mdi-cash-multiple",
        visible: true,
        subMenuItems: [],
        isLabeled: false,
        meta: {
            type: "system",
            functions: ["System.Default.Subscription.Manage"],
        }
    },{
        id: "menuManage",
        header: "",
        text: "sidebar.system.menu_items_setting",
        name: "MenuManage",
        icon: "mdi-view-list-outline",
        visible: true,
        subMenuItems: [],
        isLabeled: false,
        meta: {
            type: "system",
            functions: ["System.Default.Company.Read"],
        }
    },
    // {
    //     id: "Licenses",
    //     header: "",
    //     text: "sidebar.system.licenses",
    //     name: "SystemLicenses",
    //     icon: "mdi-license",
    //     visible: true,
    //     subMenuItems: [],
    //     isLabeled: true,
    //     label: {
    //         text: "Dev",
    //         outlined: true,
    //         small: true
    //     },
    //     meta: {
    //         type: "system",
    //         functions: [],
    //     }
    // },

    // =============================================================================
    // WebimSync
    // =============================================================================

    {
        id: "system servers",
        header: "WeBIMSync",
        text: "sidebar.webimSync.servers",
        name: "Servers",
        icon: "mdi-server",
        visible: true,
        subMenuItems: [],
        meta: {
            type: "system",
            functions: ["System.Default.Company.Read"]
        }
    },
    {
        id: "system versions",
        header: "WeBIMSync",
        text: "sidebar.webimSync.system_versions",
        name: "SystemVersions",
        icon: "mdi-folder-cog-outline",
        visible: true,
        subMenuItems: [],
        meta: {
            type: "system",
            functions: ["System.Default.Company.Read"]
        }
    },
    // =============================================================================
    // PROJECT
    // =============================================================================

    {
        id: "QuotaManage",
        name: "QuotaManage",
        header: "Product Configurator",
        text: "sidebar.system.pc_quota_manage",
        icon: "mdi-currency-usd",
        subMenuItems: [],
        meta: {
        }
    },
    {
        id: "OnlineUsers",
        name: "OnlineUsers",
        header: "Online",
        text: "sidebar.system.online_users",
        icon: "mdi-account-multiple-outline",
        subMenuItems: [],
        meta: {
        }
    }
];