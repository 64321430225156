<template>
<div>
    <v-card outlined>
        <v-card-title>
            <v-btn @click='goBack()' icon>
                <v-icon>
                    mdi-arrow-left
                </v-icon>
            </v-btn>

            <v-divider vertical class='mx-3'></v-divider>
            <v-icon left>mdi-format-list-bulleted</v-icon> 選項設定 - {{menu?menu.name:''}}
            <v-spacer></v-spacer>
            
            <v-dialog :min-width="listWidthInPixel" width='50%' scrollable>
                <template #activator="{ on }">
                    <v-btn v-on="on" class='mr-4'>預覽</v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        預覽
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class='d-flex justify-center grey darken-1 py-4' style="max-height: 70vh;">
                        <sb-vertical-nav-menu
                            v-if='menuItems && menuItems.length'
                            class="white"
                            :sidebar-menu="menuItems"
                            :is-i18n="menuIsI18n"
                            :is-dispaly-subheader="true"
                            :inactive="true"
                            :style="{width:listWidthInPixel}"
                        ></sb-vertical-nav-menu>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-btn
                color="primary"
                dark
                @click='openEditDialog()'
            >
                新增選項
            </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="d-flex justify-center grey darken-1">
            <v-row no-gutters>
                <!--loading-->
                <v-col v-if="loading"  class="light-grey d-flex justify-center align-center">
                    <sb-vertical-nav-menu
                        id="menu-items--loading"
                        class="white pa-3 rounded-lg"
                        :style="{width:'320px'}"
                        :isSidebarMenuLoading="true"
                        :sidebar-menu="[]"
                    ></sb-vertical-nav-menu>
                </v-col>
                <!--no-records-->
                <v-sheet
                    v-else-if="menuItems && menuItems.length==0"
                    :width='"360px"'
                    class="white text-center mx-auto py-4"
                >
                    {{$t("iterator.no-records")}}
                </v-sheet>
                <!--data loaded-->
                <v-col v-else class="d-flex justify-center align-center flex-column">
                    <v-list dense class="rounded-lg">
                        <Draggable 
                            ref="tree"
                            :value="treeData" 
                            idKey="id"
                            parentIdKey="parentId"
                            triggerClass="drag-trigger"
                            @after-drop="onDrop"
                            :style="{width:listWidthInPixel}"
                        >
                            <template #default="{ node }">
                                <v-list-item
                                    v-if='node.visible!==false'
                                    :key='node.id || node.name'
                                    :inactive="isDisabled(node)"
                                >
                                    <v-list-item-icon>
                                        <v-icon class='drag-trigger ml-1 mr-6'>mdi-drag</v-icon>

                                        <v-icon v-if="node.icon" :class="{'disabled':isDisabled(node)}">{{ node.icon }}</v-icon>
                                        <v-img v-if="node.src" :class="{'disabled':isDisabled(node)}" :src="node.src" max-width="24"></v-img>
                                    </v-list-item-icon>
                                    <v-list-item-content :class="{'disabled':isDisabled(node)}">
                                        {{ $t(node.text) }}
                                    </v-list-item-content>
                                    <v-list-item-action class='my-0'>
                                        <v-chip v-if='node.isLabeled' v-bind='node.label'>{{$t(node.label.text)}}</v-chip>
                                    </v-list-item-action>
                                    <v-list-item-action class='my-0'>
                                        <span class="btn-toggle--expand">
                                            <v-icon 
                                                v-if="node?.$children?.length"
                                                left
                                                class="tree-button--toggle"
                                                @click="onClickToggler(node)"
                                            >
                                                {{ node.$folded ? "mdi-menu-up" : "mdi-menu-down" }}
                                            </v-icon>
                                        </span>
                                    </v-list-item-action>
                                    <v-list-item-action class='my-0'>
                                        <EditListItems :edit-list-items='menuItemsEditors' :item="node" ></EditListItems>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                        </Draggable>
                    </v-list>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
    
    <menu-item-edit-dialog
        ref='menuItemEditDialog'
        :edit-item='editingMenuItem'
        @create="createMenuItem"
        @update="updateMenuItem"
    ></menu-item-edit-dialog> 
    <MenuItemDeleteDialog 
        ref='MenuItemDeleteDialog' 
        :edit-item='editingMenuItem' 
        @delete='deleteMenuItem'
    ></MenuItemDeleteDialog>
</div>
</template>

<script>
import MenuItemEditDialog from "@/components/menuMenuItem/MenuItemEditDialog.vue";
import MenuItemDeleteDialog from "@/components/menuMenuItem/MenuItemDeleteDialog.vue";
import EditListItems from "./EditListItems.vue"

import '@he-tree/vue/dist/v2/style.css'
import { Draggable } from '@he-tree/vue/vue2'

import listToTree from "@/utils/ListToTree";

export default {
    name:"MenuMenuItems",
    components:{
        MenuItemEditDialog,
        MenuItemDeleteDialog,
        Draggable,
        EditListItems
    },
    async created(){
        await this.getData(this.menuId)
    },
    computed:{
        menuId(){
            return this.$route.params.menuId
        },
        flatMenuItems(){
            return this.menu? JSON.parse(this.menu.menuItems):[];
        },
        menuItems(){
            return listToTree(this.flatMenuItems, undefined, "parentId", "children")
        },
        treeData(){
            return this.menuItems
        },
        menuItemsEditors() {
            return [
                {
                    title: this.$t("company.appMenuSetting.modify_menu_item"),
                    onClick: this.openEditDialog,
                },
                {
                    title: this.$t("company.appMenuSetting.add_sub_menu_item"),
                    onClick: this.addChildMenuItem,
                },
                {
                    title: this.$t("company.appMenuSetting.delete_menu_item"),
                    onClick: this.openDeleteDialog,
                }
            ];
        },
        listWidthInPixel(){
            return this.listWidth + "px";
        },
        routesItems(){
            let accumulator =[]
            this.$router.options.routes.forEach(route=>{
                accumulator.push({
                    name:route.name,
                    path:route.path
                })

                if(route.children && route.children.length){
                    this.addNestedRoutes(route.children, accumulator)
                }
            })
            return accumulator
        }
    },
    data(){
        let defaultEditItem = {
                id:undefined,
                parentId:undefined,
                header: null,
                text: "",
                isExternalLink:false,
                name:null,
                href:null,
                isExternalIcon:false,
                icon: "mdi-file-outline",
                src:null,
                visible: true,
                isLabeled:false,
                label: {
                    ["x-small"]:true,
                    outlined:true,
                    text:'text'
                },
                subMenuItems: [],
                meta: {
                    type:null,
                    functions:[]
                }
            }

        return {
            menu:null,
            editDialog: { 
                value: false,
                loading: false,
                item:null
            },
            defaultEditItem: defaultEditItem,
            editingMenuItem: defaultEditItem,
            loading:false,
            listWidth: 320,
            menuIsI18n: true
        }
    },
    methods:{
        makeid(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        setAlert(type, message){
            const snackbar={type, message, value:true, timeout:4000};
            this.$store.commit('notification/SET_ALERT', snackbar)
        },
        async getData(menuId){
            this.loading = true
            this.menu = await this.$API.api.main.menu.get(menuId)
            .then(res=>res.data)
            .catch(err=>{
                console.error('Error:', err)
            }).finally(()=>{
                this.loading = false
            })
        },
        openEditDialog(editItem){
            let cloneEditItem=editItem?JSON.parse(JSON.stringify(editItem)):JSON.parse(JSON.stringify(this.defaultEditItem))

            delete cloneEditItem.$id
            delete cloneEditItem.$children
            delete cloneEditItem.$pid
            delete cloneEditItem.$level
            delete cloneEditItem.$folded
            delete cloneEditItem.$checked

            this.editingMenuItem = cloneEditItem;
            this.$refs.menuItemEditDialog.toggle();
        },
        openDeleteDialog(editItem){
            let cloneEditItem=JSON.parse(JSON.stringify(editItem))

            delete cloneEditItem.$id
            delete cloneEditItem.$children
            delete cloneEditItem.$pid
            delete cloneEditItem.$level
            delete cloneEditItem.$folded
            delete cloneEditItem.$checked

            this.editingMenuItem = cloneEditItem;
            this.$refs.MenuItemDeleteDialog.toggle()
        },
        addChildMenuItem(menuItem){
            let childMenuItem = {...JSON.parse( JSON.stringify( this.defaultEditItem ) ), parentId: menuItem.id}
            this.openEditDialog(childMenuItem)
        },
        async createMenuItem(editItem){
            let menuItem = Object.assign({id:this.makeid(5)}, editItem)
            let menu = Object.assign({},this.menu)
            menu.menuItems.push(menuItem)
            await this.save(menu)
        },
        async updateMenuItem(editItem){
            let menuItem = Object.assign({}, editItem)
            let index = this.menu.menuItems.findIndex(menuItem=>menuItem.id===editItem.id)
            let menu =  JSON.parse(JSON.stringify(this.menu))
            menu.menuItems.splice(index, 1, menuItem)
            await this.save(menu)
        },
        async save(menu){
            try{
                await this.$API.api.main.menu.put(this.menuId, menu)
                await this.getData(this.menuId)
                this.setAlert("success", this.$t("dialog.update_successfully"))
            }catch(err){
                this.setAlert("error", err.response.status)
            }
        },
        async deleteMenuItem(editItem){
            let index = this.menu.menuItems.findIndex(menuItem=>menuItem.id===editItem.id)
            let menu =  JSON.parse(JSON.stringify(this.menu))
            menu.menuItems.splice(index, 1)
            await this.save(menu)
        },
        goBack(){
            this.$router.go(-1)
        },
        onClickToggler(node){
            this.$refs["tree"].toggleFold(node)
        },
        onDrop(store){
            let updatedFlatData = store.targetTree.outputFlatData()
            this.$set(this.menu, 'menuItems', updatedFlatData)
            this.save(this.menu)
        },        
        isDisabled(item){
            //如果是Page必須有PageId 如果是一般路由必須設定替代路徑且外部連結不存在
            return (item.name==='Page' && !item.pageId) || (!this.isRouteNameExist(item) && !item.altNamePath && !item.href) 
        },
        isRouteNameExist(menuItem){
            return this.routesItems.filter(routesItem=>routesItem.name===menuItem.name && menuItem.name!==undefined).length>0
        },
        addNestedRoutes(routes, accumulator){
            routes.forEach(route=>{
                accumulator.push({
                    name:route.name,
                    path:route.path
                })
                if(route.children && route.children.length){
                this.addNestedRoutes(route.children, accumulator)
                }
            })
        },
    }
}
</script>

<style scoped lang='scss'>
    .disabled{
        opacity: 0.5;
    }

    #menu-items--loading ::v-deep{
        .v-skeleton-loader{
            max-width: unset !important;
        }
    } 
</style>