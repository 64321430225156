import Main from "@/views/online/Main.vue"

export default {
        path: 'Online',
        name: 'Online',
        component: Main,
        children: [
            {
                name: "OnlineUsers",
                path: "users",
                component: () =>
                    import (/* webpackChunkName: "else" */"@/views/online/Users.vue"),
                meta: {
                }
            },
            {
                name: "OnlineUser",
                path: "users/:userId",
                component: () =>
                    import (/* webpackChunkName: "else" */"@/views/online/User.vue"),
                meta: {
                }
            },
            {
                name: "OnlineUserHistory",
                path: "users/:userId/history",
                component: () =>
                    import (/* webpackChunkName: "else" */"@/views/online/UserHistory.vue"),
                meta: {
                }
            }
        ]
    }