<template>
<v-dialog v-model="modalVisibility" max-width="400px" persistent>
    <v-card class="systemCompanyModule">
      <v-card-title>{{company.name}}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class='mt-4'>
        <v-combobox
          :loading="loading"
          multiple
          :label="$t('company.company_module')"
          :placeholder="$t('company.company_module')"
          v-model="companyModules"
          :items="systemModules"
          item-text="i18nValue"
          item-value="hash"
          return-object
          outlined
          hide-details
        >
          <template #selection="{ item }">
            <v-chip color="primary" small>{{`${$t(item.value)} ( ${item.type} )`}}</v-chip>
          </template>
          <template #item="{ item }">
            <v-chip color="primary" small>{{`${$t(item.value)} ( ${item.type} )`}}</v-chip>
          </template>
        </v-combobox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="modalVisibility=false">{{$t("dialog.cancel")}}</v-btn>
        <v-btn color="primary" @click="onSubmit">{{$t("dialog.confirm")}}</v-btn>
      </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
export default {
  name: "SystemCompanyModuleDialog",
  props: {
    company: {
      type: Object,
      default: () => {
        return { id: "", name: "", modules: [] };
      }
    },
    systemModules: {
      type: Array,
      default: () => {
        return [];
      }
    },
    dialog: Boolean,
    API: {
      type: Object,
      default: function () {
        return this.$API.api.main;
      }
    }
  },

  data() {
    return {
      companyModules: [],
      loading: false
    };
  },
  computed: {
    modalVisibility: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
        return val;
      }
    }
  },
  methods: {
    getCompanyModules(companyId) {
      this.loading = true;
      const includeLicenseModule = false;
      this.API.companiesModules
        .get(companyId, includeLicenseModule)
        .then((res) => res.data)
        .then((modules) => {
          this.loading = false;
          this.companyModules = modules.map((module) => ({
            ...module,
            hash: `${module.id}-${module.type}`
          }));
        })
        .catch((error) => {
          console.error("ERROR:", error);
        });
    },
    onSubmit() {
      this.loading = true;
      let moduleIds = this.companyModules.map((p) => p.id);
      this.API.companyModule
        .post(this.company.id, moduleIds)
        .then((res) => res.data)
        .then(() => {
          this.loading = false;
          this.modalVisibility = false;
          this.setAlert("success", this.$t("dialog.update_successfully"));
        })
        .catch((err) => {
          console.error("Error:", err);
          this.loading = false;
          this.modalVisibility = false;
          this.setAlert("error", this.$t("dialog.update_failed"));
        });
    }
  },
  mounted() {
    if (this.company.id) {
      this.getCompanyModules(this.company.id);
    }
  },
  watch: {
    modalVisibility: {
      immediate: true,
      handler(modalVisibility) {
        if (modalVisibility) {
          this.getCompanyModules(this.company.id);
        } else {
          this.companyModules = [];
        }
      }
    }
  }
};
</script>