<i18n>
  {
    "zh-Hant":{
      "in_maintenance":"維護中"
    },
    "en":{
      "in_maintenance":"in maintenance" 
    }
  }
</i18n>


<template>
  <div>
    {{$t("in_maintenance")}}
    <!-- <sb-tohatsu-upload-quota-manager :companyId='companyId'></sb-tohatsu-upload-quota-manager> -->
  </div>
 
</template>

<script>
export default {
  data() {
    return {
      companyId: "8ef5ecd8-748a-468a-950d-43d09847c1d0"
    };
  }
};
</script>

<style>
</style>