<i18n>
{
  "zh-Hant":{
    "guest":"來賓",
    "member":"成員",
    "project.company":"公司",
    "added":"已加入",
    "guest":"來賓",
    "companyName":"所屬公司",
    "note":"備註",
    "add":"新增",
    "remove":"移除",
    "invite":"邀請"
  },
  "en":{
    "guest":"Guest",
    "member":"Member",
    "project.company":"company",
    "added":"added",
    "guest":"guest",
    "companyName":"company",
    "note":"note",
    "add":"add",
    "remove":"Remove",
    "invite":"Invite"
  }
}
</i18n>

<template>
  <v-dialog v-model="isDialogVisible" persistent max-width="450px">
    <v-card>
      <v-card-title>
        <slot name="card-title">
          <span>
            {{ $t('invite') }}
          </span>
        </slot>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-tabs v-show="false" v-model="tab" background-color="primary" dark>
        <v-tab v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
        <v-spacer></v-spacer>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <component :is="item.component" :addUserState.sync="state" :submitFunction="submitFunction" @close="onClose"
            @refresh="onRefresh"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import AddContent from './AddContent.vue'
import InviteByEmail from './InviteByEmail.vue'

export default {
  name: 'AddOrInviteDialog',
  components: {
    AddContent,
    InviteByEmail,
  },
  props: {
    addUserState: {
      Type: Object,
      required: true,
    },
    submitFunction: {
      Type: Function,
    },
    value: {
      Type: Boolean,
    },
  },
  data() {
    return {
      tab: null,
      items: [
        // { tab: '透過Email', component: 'AddContent' },
        { tab: this.$t('invite'), component: 'InviteByEmail' },
      ],
    }
  },
  computed: {
    state: {
      get() {
        return this.addUserState
      },
      set(val) {
        this.$emit('update:addUserState', val)
      },
    },
    isDialogVisible: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    cancel() {
      this.isDialogVisible = false
    },
    onClose() {
      this.cancel()
    },
    onRefresh() {
      this.$emit("refresh")
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-input__append-outer {
  margin-top: 3px !important;
}
</style>
