<i18n>
{
  "zh-Hant":{
    "accounts_settings": "訂閱帳戶"
  },
  "en":{
    "accounts_settings": "Accounts"
  }
}
</i18n>

<template>
  <div class="systemCompany">
    <v-card outlined>
      <v-card-title>
        <v-icon style="margin-right: 10px">mdi-briefcase-outline</v-icon>
        <div class="text-capitalize">{{ $t("sidebar.system.companies_setting") }}</div>
        <v-spacer></v-spacer>
        <filter-search-bar
          :filter.sync="dataState.filter"
          :fields="['name']"
          @search="dataState.skip = 0;getData();"
        >
        </filter-search-bar>
        <div>
          <v-btn
            color="primary"
            class="mx-3"
            :disabled="editLagerThanOne"
            @click="insert"
            >
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ $t("system.company.add_company") }}</v-btn
          >
          <v-btn v-if="hasItemsInEdit" @click="cancelChanges">{{
            $t("system.cancel_current_changes")
          }}</v-btn>
        </div>
      </v-card-title>
      <div v-if="loading" class="k-loading-mask">
        <span class="k-loading-text"></span>
        <div class="k-loading-image" />
        <div class="k-loading-color" />
      </div>
      <v-card flat :loading="loading">
        <localization-provider :language="$i18n.locale">
          <intl-provider :locale="$i18n.locale">
            <grid
              ref="grid"
              :data-items="dataItems"
              :skip="dataState.skip"
              :take="dataState.take"
              :filter="dataState.filter"
              :sort="dataState.sort"
              :edit-field="'inEdit'"
              :resizable="resizable"
              sortable
              :pageable="pageable"
              column-menu
              :columns="columns"
              @itemchange="itemChange"
              @datastatechange="dataStateChange"
              @edit="edit"
              @remove="remove"
              @save="save"
              @cancel="cancel"
              style="height: 100%"
            >
              <template v-slot:item_isEnable="{ props }">
                <td>
                  <v-checkbox
                    v-model="props.dataItem.isEnable"
                    :disabled="!props.dataItem.inEdit"
                    class="mt-0"
                    hide-details
                  ></v-checkbox>
                </td>
              </template>

              <template v-slot:item_actions="{ props }">
                <td>
                  <v-btn
                    :disabled="props.dataItem.inEdit"
                    color="primary"
                    small
                    class="mr-2"
                    @click="onClickModules(props)"
                  >
                    <v-icon small left>mdi-cog</v-icon>
                    {{ $t("system.company_set_modules") }}
                  </v-btn>
                  <v-btn
                    :disabled="props.dataItem.inEdit"
                    color="primary"
                    small
                    class="mr-2"
                    :to="{
                      name: 'SystemCompanyUser',
                      params: { systemCompanyId: props.dataItem.id },
                      query: {
                        ...$route.query,
                        companyName: props.dataItem.name,
                      },
                    }"
                  >
                    <v-icon small left>mdi-account-multiple-outline</v-icon>
                    {{ $t("system.company_set_users") }}
                  </v-btn>
                  <v-btn
                    :disabled="props.dataItem.inEdit"
                    color="primary"
                    small
                    class="mr-2"
                    :to="{
                      name: 'SystemCompanyMenu',
                      params: { systemCompanyId: props.dataItem.id },
                      query: { systemCompanyName: props.dataItem.name },
                    }"
                  >
                    <v-icon small left>mdi-menu</v-icon>
                    {{ $t("system.company_set_menu_items") }}
                  </v-btn>
                  <v-btn
                    :disabled="props.dataItem.inEdit"
                    color="primary"
                    small
                    class="mr-2"
                    :to="{
                      name: 'SystemCompanyAccounts',
                      params: { systemCompanyId: props.dataItem.id },
                      query: { systemCompanyName: props.dataItem.name },
                    }"
                  >
                    <v-icon small left>mdi-cash-multiple</v-icon>
                    {{ $t("accounts_settings") }}
                  </v-btn>
                </td>
              </template>

              <grid-no-records
                :style="{ minHeight: loading ? '256px' : '128px' }"
              >
                <div>{{ $t("general.no_records") }}</div>
              </grid-no-records>
            </grid>
          </intl-provider>
        </localization-provider>
      </v-card>
    </v-card>
    <system-company-module-dialog
      v-if="activeCompany"
      :company="activeCompany"
      :modal.sync="dialog.value"
      :systemModules="modulesI18n"
      :API="API"
      @alert="setAlert"
      :dialog.sync="dialog.value"
    />
  </div>
</template>

<script>
import SystemCompanyModuleDialog from "@/components/SystemCompanyModuleDialog.vue";
import CommandCell from "@/components/CommandCell.vue";
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import FilterSearchBar from "@/components/FilterSearchBar.vue";

export default {
  name: "SystemCompany",
  components: {
    SystemCompanyModuleDialog,
    FilterSearchBar
  },
  computed: {
    API: function () {
      return this.$API.api.main;
    },
    columns() {
      return [
        {
          title: this.$t("system.name"),
          field: "name",
          width: 4 * this.unitColumnWidth
        },
        {
          title: this.$t("user_max"),
          field: "userMax",
          filter: "numeric",
          editor: "numeric",
          // sortable: false,
          // columnMenu: false,
          width: 1 * this.unitColumnWidth
        },
        {
          title: this.$t("system.company_is_enable"),
          field: "isEnable",
          filter: "boolean",
          editor: "boolean",
          cell: "item_isEnable",
          width: 1 * this.unitColumnWidth
        },
        {
          title: "公司路由",
          field: "route",
          hidden: true,
          editable: false,
          columnMenu: false,
          minResizableWidth: 1 * this.unitColumnWidth,
          sortable: false,
          width: 2 * this.unitColumnWidth
        },
        {
          cell: "item_actions",
          editable: false,
          columnMenu: false,
          width: 3.2 * this.unitColumnWidth,
          sortable: false
        },
        {
          cell: CommandCell,
          sortable: false,
          columnMenu: false,
          locked: true,
          width: 0.75 * this.unitColumnWidth,
          headerClassName: "command-header"
        }
      ];
    },
    hasItemsInEdit() {
      return (
        this.dataItems && this.dataItems.data.filter((p) => p.inEdit).length > 0
      );
    },
    editLagerThanOne() {
      return (
        this.dataItems &&
        this.dataItems.data.filter((item) => !!item.id === false).length >= 1
      );
    },
    modulesI18n() {
      return this.modules.map((module) => ({
        ...module,
        i18nValue: this.$t(module.value)
      }));
    }
  },
  data: () => ({
    search: "",
    dialog: {
      title: "",
      text: "",
      value: false
    },
    modules: [],
    activeCompany: null,
    unitColumnWidth: 150,
    dataItems: null,
    dataState: {
      sort: null,
      filter: null,
      take: 15,
      skip: 0,
      group: ""
    },
    sortable: false,
    resizable: true,
    pageable: {
      buttonCount: 5,
      info: true,
      type: "numeric",
      pageSizes: true,
      previousNext: true
    },
    sortalbe: false,
    columnMenu: false,
    loading: false,
    updatedData: null
  }),

  methods: {
    async getData() {
      this.loading = true;
      const queryStr = toDataSourceRequestString(this.dataState); // Serialize the state
      var urlencoded = new URLSearchParams(queryStr);

      this.dataItems = await this.API.systemCompany
        .post(urlencoded)
        .then((res) => res.data)
        .finally(() => {
          this.loading = false;
        });

      this.updatedData = JSON.parse(JSON.stringify(this.dataItems.data));
    },

    cancelChanges() {
      let editedItems = this.dataItems.data.filter((p) => p.inEdit === true);
      if (editedItems.length) {
        editedItems.forEach((editedItem) => {
          if (editedItem.id) {
            let originalItem = this.updatedData.find(
              (p) => p.id === editedItem.id
            );
            let index = this.updatedData.findIndex(
              (p) => p.id === originalItem.id
            );
            this.$set(originalItem, "inEdit", undefined);
            this.$set(this.dataItems.data, index, originalItem);
          } else {
            let index = this.dataItems.data.findIndex(
              (companyItem) =>
                JSON.stringify(companyItem) === JSON.stringify(editedItem)
            );
            this.dataItems.data.splice(index, 1);
          }
        });
      }
      this.dataItems.data = this.updatedData.slice();
    },

    deleteCompanies(item) {
      item.processing = true;
      this.API.company
        .delete(item.id)
        .then(() => {
          let index = this.dataItems.data.indexOf(item);
          this.dataItems.data.splice(index, 1);
        })
        .then(() => {
          item.dialog.delete = false;
          item.processing = false;
          this.setAlert("success", this.$t("dialog.delete_successfully"));
        })
        .catch((error) => {
          console.error(error);
          this.setAlert("error", this.$t("dialog.delete_failed"));
        });
    },
    edit: function (e) {
      this.updatedData = JSON.parse(JSON.stringify(this.dataItems.data));
      this.$set(e.dataItem, "inEdit", true);
    },
    save: async function (e) {
      // if (!this.validateEditedItem(e.dataItem)) {
      //   return;
      // }
      this.$set(e.dataItem, "inEdit", undefined);
      let item = this.dataItems.data.find((p) => p.id === e.dataItem.id);
      let index = this.dataItems.data.findIndex((p) => p.id === item.id);
      let updatedItem = await this.update(this.dataItems.data.slice(), item);
      this.$set(this.dataItems.data, index, updatedItem);
      this.$set(this.dataItems.data[index], "inEdit", undefined);
      this.updatedData = JSON.parse(JSON.stringify(this.dataItems.data));
    },
    async update(data, item, remove) {
      this.loading = true;
      let updated;
      let index = data.findIndex((p) => item.id && p.id === item.id);

      //update
      if (index >= 0 && !remove) {
        updated = Object.assign({}, item);
        data[index] = updated;
        let formData = new FormData();
        for (let key in updated) {
          formData.append(key, updated[key]);
        }

        await this.API.company
          .update(item.id, formData, (CONFIG) => {
            CONFIG.headers["Content-Type"] = "multipart/form-data";
            return CONFIG;
          })
          .then(() => {
            const snackbar = {
              type: "success",
              message: this.$t("dialog.modify_successfully"),
              value: true,
              timeout: 4000
            };
            this.$store.commit("notification/SET_ALERT", snackbar);
          })
          .catch(() => {
            const snackbar = {
              type: "error",
              message: this.$t("dialog.modify_failed"),
              value: true,
              timeout: 4000
            };
            this.$store.commit("notification/SET_ALERT", snackbar);
          });

        await this.$API.api.bim.companies
          .putSingle(item.id, JSON.parse(JSON.stringify(item)))
          .then(() => {
            const snackbar = {
              type: "success",
              message: this.$t("dialog.modify_successfully"),
              value: true,
              timeout: 4000
            };
            this.$store.commit("notification/SET_ALERT", snackbar);
          })
          .catch(() => {
            const snackbar = {
              type: "error",
              message: this.$t("dialog.modify_failed"),
              value: true,
              timeout: 4000
            };
            this.$store.commit("notification/SET_ALERT", snackbar);
          });

        //create
      } else if (!remove) {
        updated = Object.assign({}, item);

        let createdDataItem = (({ name, concurrentUserNumber, isEnable }) => ({
          Name: name,
          ConcurrentUserNumber: concurrentUserNumber ? concurrentUserNumber : 0,
          IsEnable: isEnable ? isEnable : false
        }))(updated);

        //json to FormData
        let formData = new FormData();
        for (let key in createdDataItem) {
          formData.append(key, createdDataItem[key]);
        }

        await this.API.company
          .create(formData, (CONFIG) => {
            CONFIG.headers["Content-Type"] = "multipart/form-data";
            return CONFIG;
          })
          .then((res) => {
            this.setAlert("success", this.$t("dialog.modify_successfully"));
            let companyItem = res.data;
            this.$set(companyItem, "inEdit", undefined);
            data.unshift(companyItem);
            index = 0;
          })
          .catch((error) => {
            const dialog = {
              title: error.response.status,
              message: error.response.data,
              value: true
            };
            this.$store.commit("notification/SET_DIALOG", dialog);
          });
      }

      //remove
      if (remove) {
        data = data.splice(index, 1);
      }

      this.loading = false;
      return data[index];
    },
    cancel(e) {
      if (e.dataItem.id) {
        let originalItem = this.updatedData.find((p) => p.id === e.dataItem.id);
        let index = this.updatedData.findIndex((p) => p.id === originalItem.id);
        this.$set(originalItem, "inEdit", undefined);
        this.$set(this.dataItems.data, index, originalItem);
      } else {
        let index = this.dataItems.data.findIndex(
          (item) => JSON.stringify(item) === JSON.stringify(e.dataItem)
        );
        this.dataItems.data.splice(index, 1);
      }
    },
    remove(e) {
      e.dataItem.inEdit = undefined;
      this.delete(e.dataItem);
    },

    delete(item) {
      item.processing = true;
      this.API.company
        .delete(item.id)
        .then(() => {
          let index = this.dataItems.data
            .map((item) => item.id)
            .indexOf(item.id);
          this.dataItems.data.splice(index, 1);
        })
        .then(() => {
          item.dialog.delete = false;
          item.processing = false;
        })
        .then(() => {
          const snackbar = {
            type: "success",
            message: this.$t("dialog.delete_successfully"),
            value: true,
            timeout: 4000
          };
          this.$store.commit("notification/SET_ALERT", snackbar);
        })
        .catch((error) => {
          console.error(error);
          item.dialog.delete = false;
          item.processing = false;
          const snackbar = {
            type: "error",
            message: this.$t("dialog.delete_failed"),
            value: true,
            timeout: 4000
          };
          this.$store.commit("notification/SET_ALERT", snackbar);
        });
    },
    itemChange(e) {
      if (e.dataItem.id) {
        let item = this.dataItems.data.find((p) => p.id === e.dataItem.id);
        this.$set(item, e.field, e.value);
      } else {
        this.$set(e.dataItem, e.field, e.value);
      }
    },
    dataStateChange: function (event) {
      this.createAppState(event.data);
    },
    createAppState: function (dataState) {
      this.dataState = dataState;
      this.getData();
    },
    insert() {
      const dataItem = {
        inEdit: true
      };

      this.dataItems.data.splice(0, 0, dataItem);
    },
    getModules() {
      const type = "System";
      return this.API.module
        .getAll()
        .then((res) => res.data)
        .then((modules) => {
          this.modules = modules.map((module) => ({
            ...module,
            type: type,
            hash: `${module.id}-${type}`
          }));
        })
        .catch((err) => {
          const dialog = {
            title: err.response.status,
            message: err.response.data,
            value: true
          };
          this.$store.commit("notification/SET_DIALOG", dialog);
        });
    },
    onClickModules(props) {
      this.dialog.value = true;
      this.activeCompany = props.dataItem;
    },
    //驗證函數
    validateEditedItem(editedItem) {
      let isValidated = true;
      let keys = Object.keys(editedItem);
      let isExistValueEmpty =
        keys.filter((key) => {
          //驗證字串
          if (typeof editedItem[key] === "string")
            return editedItem[key].trim() === "" || null || undefined;
        }).length !== 0;
      if (keys.length === 1 || isExistValueEmpty) {
        isValidated = false;
        this.$store.commit(
          "notification/SET_ALERT",
          ("error", this.$t("formValidation.check_correction"))
        );
      }
      return isValidated;
    },
    setAlert(type, message) {
      const snackbar = { type, message, value: true, timeout: 4000 };
      this.$store.commit("notification/SET_ALERT", snackbar);
    }
  },

  mounted() {
    this.getModules();
    this.getData();
  }
};
</script>

<style scoped lang='scss'>
::v-deep .command-header {
  z-index: 0 !important;
}
</style>
