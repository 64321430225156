<i18n>
{
  "zh-Hant":{
    "users": "使用者資料",
    "accounts": "訂閱帳戶"
  },
  "en":{
    "users": "Users",
    "accounts": "Accounts"
  }
}
</i18n>
<template>
  <div>
    <header class='mb-4'>
        <router-link :to="{ name:'SystemUser' }">
          {{$t("users")}} {{systemUserName}} 
        </router-link>
        / 
        <router-link :to="{ name:'SystemUserAccounts', params:{ systemUserId } }">
          {{$t("accounts")}} {{accountName}} 
        </router-link>
      <span v-if="accountId">
        / 
        <router-link :to="{ name:'SystemUserAccountsSubscriptions', params:{ systemUserId, accountId } }">
          Subscriptions
        </router-link>
      </span>
      <!-- <span v-if="subscriptionId">
        ({{subscriptionId}})  /
        Licenses
      </span> -->
    </header>
    <template v-if='!accountId'>
      <accounts-list @select='onSelect'></accounts-list>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import AccountsList from "../../components/accounts/List.vue";
export default {
  name: "SystemUserAccounts",
  components: {
    AccountsList
  },
  data() {
    return {
      account: null
    };
  },
  computed: {
    systemUserId() {
      return this.$route.params.systemUserId;
    },
    systemUserName() {
      return this.$route.params.systemUserId
        ? `(danielhsieh@webim.com.tw)`
        : null;
    },
    accountId() {
      return this.$route.params.accountId;
    },
    accountName() {
      return this.$route.params.accountId ? `(研發部)` : null;
    },
    subscriptionId() {
      return this.$route.params.subscriptionId;
    }
  },
  methods: {
    onSelect(account) {
      this.account = account;
      this.$router.push({
        name: "SystemUserAccountsSubscriptions",
        params: { ...this.$route.params, accountId: account.id }
      });
    }
  }
};
</script>

<style>
</style>