
<template>
    <SystemVersionsMain></SystemVersionsMain>
</template>

<script>
import SystemVersionsMain from "@/components/systemVersions/Main.vue"
export default {
    name:"systemVersions",
    components:{
        SystemVersionsMain
    }
}
</script>