<i18n lang="json">
{
  "zh-Hant":{
    "name":"名稱",
    "createdDate": "建立日期",
    "add":"新增",
    "cancel":"取消",
    "save":"儲存",
    "ok":"確認",
    "accounts": "訂閱帳戶",
    "edit": "編輯",
    "hasAnySubscriptions": "訂閱服務",
    "delete":"刪除",
    "are_you_sure_to_delete":"您確定要刪除"
  },
  "en":{
    "name":"name",
    "createdDate": "Created Date",
    "add":"Add",
    "cancel":"Cancel",
    "save":"Save",
    "ok":"OK",
    "accounts": "Accounts",
    "edit": "Edit",
    "hasAnySubscriptions": "Any Subscriptions",
    "delete":"delete",
    "are_you_sure_to_delete":"Are you sure to delete"
  }
}
</i18n>

<template>
<v-card outlined>
  <v-data-table
    outlined
    :headers="headers"
    :items="items"
    :items-per-page="itemsPerPage"
    :loading='loading'
  >
  <template #top>
    <v-toolbar
        flat
        dense
      >
        <v-icon left>
          mdi-cash-multiple
        </v-icon>
        <v-toolbar-title>
          {{$t("accounts")}}</v-toolbar-title>
        <v-spacer></v-spacer>  
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              {{$t("add")}}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span >{{ formTitle }}</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class='pt-3'>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    lg='12'
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      outlined
                      v-model="editedItem.name"
                      :label="$t('name')"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="onClose"
              >
                {{$t('cancel')}}
              </v-btn>
              <v-btn
                color="primary"
                :loading="isEditDialogLoading"
                @click="onSave"
              >
                {{$t('save')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="500px">
          <v-card>
            <v-card-title>{{$t('delete')}}  <b class='px-1'>{{editedItem.name}}</b></v-card-title>
            <v-card-text> {{$t("are_you_sure_to_delete")}} <b class='px-1'>{{editedItem.name}}</b>  ?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="onCloseDeleteDialog">{{$t('cancel')}}</v-btn>
              <v-btn color="error" @click="onConfirmDelete" :loading='isDeleteLoading'>{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    <v-divider></v-divider>
  </template>
  <template #item.name="{ item }">
    <a href="javascript:void(0);" @click="onClick(item)">
      {{item.name}}
    </a>
  </template>
  <template #item.isAnySubscription="{ item }">
    <v-icon :color="item.isAnySubscription?'success':'error'">
      {{item.isAnySubscription?'mdi-check-circle':'mdi-close-circle'}}
    </v-icon>
  </template>
  <template v-slot:item.actions="{ item }">
    <v-icon
      class="mr-2"
      @click="onClickEdit(item)"
    >
      mdi-pencil
    </v-icon>
    <v-icon
      @click="onClickDelete(item)"
    >
      mdi-delete
    </v-icon>
  </template>
  </v-data-table>
</v-card>
</template>

<script>
export default {
  name: "AccountsList",
  props: {
    // TODO: waiting for back-end api, getUserAccount
    userId: {
      type: String,
      default: null
    },
    companyId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      itemsPerPage: 10,
      items: [],
      dialog: false,
      deleteDialog: false,
      editedItem: {
        id: "",
        companyId: "",
        createdUserId: null,
        createdDate: "",
        name: ""
      },
      defaultItem: {
        id: "",
        companyId: "",
        createdUserId: null,
        createdDate: "",
        name: ""
      },
      editedIndex: -1,
      loading: false,
      isDeleteLoading: false,
      isEditDialogLoading: false
    };
  },
  async created() {
    this.items = await this.getAccounts();
  },
  watch: {
    dialog: {
      handler(val) {
        val || this.onClose();
      }
    },
    deleteDialog: {
      handler(val) {
        val || this.onCloseDeleteDialog();
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t("add") : this.$t("edit");
    },
    headers() {
      return [
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "name"
        },
        // {
        //   text: this.$t("createdDate"),
        //   align: "end",
        //   sortable: true,
        //   value: "createdDate"
        // },
        {
          text: this.$t("hasAnySubscriptions"),
          align: "start",
          value: "isAnySubscription",
          width: 100
        },
        {
          align: "end",
          sortable: false,
          value: "actions",
          width: 100
        }
      ];
    }
  },
  methods: {
    async getAccounts() {
      let results = [];
      try {
        if (this.companyId) {
          this.loading = true;
          const response = await this.$API.api.main.companiesAccounts.get(
            this.companyId
          );
          results = response.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
      return results;
    },
    onClick(account) {
      this.$emit("select", account);
    },
    async onClose() {
      this.dialog = false;
      await this.$nextTick();
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    async onSave() {
      this.isEditDialogLoading = true;
      if (this.editedIndex > -1) {
        // update account
        // TODO: waiting for back-end API
        // Object.assign(this.items[this.editedIndex], this.editedItem);
        await this.patchAccount();
      } else {
        // create account
        await this.createAccount();
      }
      this.isEditDialogLoading = false;
      this.items = await this.getAccounts();
      this.onClose();
    },
    onClickEdit(editedItem) {
      this.editedIndex = this.items.findIndex(
        (item) => item.id === editedItem.id
      );
      this.editedItem = Object.assign({}, editedItem);
      this.dialog = true;
    },

    onClickDelete(editedItem) {
      this.editedIndex = this.items.findIndex(
        (item) => item.id === editedItem.id
      );
      this.editedItem = Object.assign({}, editedItem);
      this.deleteDialog = true;
    },
    async onCloseDeleteDialog() {
      this.deleteDialog = false;
      await this.$nextTick();
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    async onConfirmDelete() {
      // this.items.splice(this.editedIndex, 1);
      this.isDeleteLoading = true;
      await this.deleteAccount();
      this.isDeleteLoading = false;
      this.items = await this.getAccounts();
      this.deleteDialog = false;
    },
    async deleteAccount() {
      let results = null;
      const { id } = this.editedItem;
      const accountId = id;
      try {
        if (this.companyId) {
          const response = await this.$API.api.main.companiesAccounts.delete(
            this.companyId,
            accountId
          );
          results = response.data;
        }
      } catch (error) {
        console.error(error);
      }
      return results;
    },
    async patchAccount() {
      let results = null;
      const { id, name } = this.editedItem;
      const data = {
        Name: name
      };
      try {
        if (this.companyId) {
          const response = await this.$API.api.main.companiesAccounts.patch(
            this.companyId,
            id,
            data
          );
          results = response.data;
        }
      } catch (error) {
        console.error(error);
      }
      return results;
    },
    async createAccount() {
      let results = null;
      const { name } = this.editedItem;
      try {
        if (this.companyId) {
          const response = await this.$API.api.main.companiesAccounts.create(
            this.companyId,
            name,
            null
          );
          results = response.data;
        }
      } catch (error) {
        console.error(error);
      }
      return results;
    }
  }
};
</script>

<style>
</style>