<i18n lang="json">
    {
      "zh-Hant": {
        "id": "編號",
        "version": "版本",
        "description": "描述",
        "type": "類型"
      }
    }
    </i18n>
    
    <template>
      <v-dialog v-model="dialogComputed" width="450px" persistent>
        <v-card flat :loading="loading">
          <v-card-title>
            {{ $t("System.SystemVersionsEditDialog.edit") }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              :label="$t('id')"
              v-model="cloneDataItem.id"
              outlined
              class="mt-4"
              hide-details
              readonly
              dense
            ></v-text-field>
            <v-text-field
              :label="$t('version')"
              v-model="cloneDataItem.version"
              outlined
              class="mt-4"
              hide-details
              dense
            ></v-text-field>
            <v-textarea
              v-model="cloneDataItem.description"
              :label="$t('description')"
              outlined
              auto-grow
              hide-details
              rows="3"
              row-height="24"
              class="mt-4"
            ></v-textarea>
            <v-autocomplete
              :label="$t('type')"
              v-model="cloneDataItem.type"
              outlined
              dense
              class="mt-4"
              hide-details
              item-value="id"
              item-text="name"
              :items="typeItems"
            >
            </v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed @click="cancel">{{ $t("System.SystemVersionsEditDialog.cancel") }}</v-btn>
            <v-btn depressed color="primary" @click="edit" :loading="loading" :diasbled="loading">{{
              $t("System.SystemVersionsEditDialog.submit")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    
    <script>
    export default {
      name: "SystemVersionsEditDialog",
      props: {
        dialog: Boolean,
        dataItem: Object
      },
      data() {
        return {
          loading: false,
          cloneDataItem: {
            id: 0,
            version: "0.0.0.0",
            description: "",
            type: "MainSystem"
          },
          typeItems: []
        }
      },
      computed: {
        dialogComputed: {
          get() {
            return this.dialog
          },
          set(dialog) {
            this.$emit("update:dialog", dialog)
          }
        }
      },
      methods: {
        edit() {
          this.loading = true
          let data = this.cloneDataItem
          this.$API.api.bim.systemVersions
            .patch(data, (CONFIG) => {
              CONFIG.headers["content-type"] = "application/json"
              return CONFIG
            })
            .then(() => {
              let alert = {
                type: "success",
                message: this.$t("System.SystemVersionsEditDialog.update_successfully"),
                value: true
              }
              this.SET_ALERT(alert)
              this.dialogComputed = false
    
              this.$emit("edit:success")
            })
            .catch(() => {
              let alert = {
                type: "error",
                message: this.$t("System.SystemVersionsEditDialog.update_failed"),
                value: true
              }
              this.SET_ALERT(alert)
            })
            .finally(() => {
              this.loading = false
            })
        },
        cancel() {
          this.dialogComputed = false
        },
        async init() {
          try {
            this.typeItems = await this.$API.api.bim.systemVersions.types().then((res) => res.data)
          } catch (err) {
            console.error(err)
          }
        }
      },
      created() {
        this.init()
      },
      watch: {
        dialog(dialog) {
          if (dialog) {
            this.cloneDataItem = JSON.parse(JSON.stringify({ ...this.dataItem, type: this.dataItem.type.id }))
          } else {
            this.cloneDataItem = {
              id: 0,
              version: "0.0.0.0",
              description: "",
              type: 0
            }
          }
        }
      }
    }
    </script>
    
    <style scoped></style>
    