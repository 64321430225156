<template>
  <v-card>
    <!-- <v-card-title>{{$t('system.createUser.title')}}</v-card-title>
    <v-divider /> -->
    <v-card-text class="pt-4">{{$t('system.createUser.description')}}</v-card-text>
    <v-card flat class="pr-8 pl-7 pt-2">
      <v-text-field
        v-model="account.UserName"
        :label="$t('system.createUser.userName')"
        :placeholder="$t('system.createUser.userName')"
        prepend-icon="mdi-account"
        :rules="[rules.required]"
        :error-messages="errorMessages"
        outlined
        clearable
        class="mb-2"
      ></v-text-field>
      <v-text-field
        v-model="account.Email"
        :label="$t('system.createUser.email')"
        :placeholder="$t('system.createUser.email')"
        prepend-icon="mdi-email"
        :rules="[rules.required, rules.email]"
        :error-messages="errorMessages"
        outlined
        clearable
        required
        class="mb-2"
      ></v-text-field>
      <v-text-field
        v-model="account.Password"
        :label="$t('system.createUser.password')"
        :placeholder="$t('system.createUser.password')"
        :hint="$t('system.createUser.password_hint')"
        prepend-icon="mdi-key"
        :rules="[rules.required, rules.counter, rules.oneLowerCase]"
        :error-messages="errorMessages"
        outlined
        required
        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        class="mb-2"
      ></v-text-field>
      <v-text-field
        v-model="account.ConfirmPassword"
        :label="$t('system.createUser.password_confirm')"
        :placeholder="$t('system.createUser.password_confirm')"
        prepend-icon="mdi-key"
        :rules="[rules.required, rules.confirmPassword]"
        :error-messages="errorMessages"
        outlined
        required
        :append-icon="showPasswordConfirm ? 'mdi-eye-off' : 'mdi-eye'"
        :type="showPasswordConfirm ? 'text' : 'password'"
        @click:append="showPasswordConfirm = !showPasswordConfirm"
        class="mb-2"
      ></v-text-field>
      <v-autocomplete
        v-model="account.CompanyId"
        :label="$t('system.createUser.company')"
        prepend-icon="mdi-briefcase"
        :items="systemCompany"
        item-text="name"
        item-value="id"
        outlined
        chips
        small-chips
        class="mb-2"
      >
        <template #selection="{ item }">
          <v-chip small color="primary">{{item.name}}</v-chip>
        </template>
      </v-autocomplete>
      <v-autocomplete
        v-model="account.SystemRoles"
        :label="$t('system.createUser.role')"
        prepend-icon="mdi-account-cog"
        :items="systemRolesI18n"
        item-text="i18nName"
        item-value="id"
        hide-details
        outlined
        chips
        small-chips
        multiple
        class="mb-3"
      >
        <template #selection="{ item }">
          <v-chip small color="primary">{{item.i18nName}}</v-chip>
        </template>
      </v-autocomplete>
    </v-card>
    <v-card-actions class="pb-4 pr-4">
      <v-spacer></v-spacer>
      <!-- <v-btn outlined class="mr-2" @click="close()">{{ $t('system.createUser.cancel') }}</v-btn> -->
      <v-btn color="primary" @click="createUser()">{{ $t('system.createUser.create') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters("sbCreateUser", ["systemRoles", "systemCompany"]),
    systemRolesI18n() {
      return this.systemRoles.map((role) => ({
        ...role,
        i18nName: this.$t(role.name)
      }));
    }
  },
  data() {
    return {
      account: {
        UserName: null,
        Email: null,
        Password: null,
        ConfirmPassword: null,
        SystemRoles: [],
        CompanyId: null
      },
      rules: {
        required: (value) => !!value || this.$t("system.createUser.required"),
        counter: (value) =>
          value && value.length >= 8 || this.$t("system.createUser.password_too_short"),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (
            pattern.test(value) || this.$t("system.createUser.invalid_email")
          );
        },
        oneLowerCase: (value) => {
          const oneLCPattern = /[a-z]/;
          return (
            oneLCPattern.test(value) ||
            this.$t("system.createUser.password_requires_lower")
          );
        },
        confirmPassword: (value) =>
          value === this.account.Password ||
          this.$t("system.createUser.password_Error")
      },
      showPassword: false,
      showPasswordConfirm: false,
      errorMessages: ""
    };
  },
  methods: {
    ...mapMutations("sbCreateUser", ["SET_CREATEUSER_DIALOG"]),
    close() {
      this.SET_CREATEUSER_DIALOG({ value: false });
      this.clearData();
    },
    async createUser() {
      await this.$store.dispatch("sbCreateUser/postUser", this.account);
      this.close()
    },
    clearData() {
      this.account = {
        UserName: null,
        Email: null,
        Password: null,
        ConfirmPassword: null,
        SystemRoles: [],
        CompanyId: null
      };
      this.showPassword = false;
      this.showPasswordConfirm = false;
    }
  }
};
</script>
