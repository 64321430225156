<i18n>
{
  "zh-Hant":{
    "add_user_to_company_description":"請輸入系統中的email以新增成員。"
  },
  "en":{
    "add_user_to_company_description":"Please input the email of user in the system."
  }
}
</i18n>
<template>
  <div>
    <v-card-text>
      <p>{{ $t('add_user_to_company_description') }}</p>
      <v-text-field
        v-model.trim="state.input"
        :label="$t('company.autocomplete.label.add_system_user')"
        :placeholder="
          $t('company.autocomplete.placeholder.add_system_user_by_email')
        "
        validate-on-blur
        :rules="rules"
        :error="state.error"
        outlined
        required
        clearable
        dense
        @keyup.13="getMatchedItem"
      >
        <template #append>
          <v-progress-circular
            v-if="state.isLoading"
            indeterminate
            color="primary"
            size="24"
          ></v-progress-circular>
          <v-icon v-else-if="matchedItem" color="success"
            >mdi-check-circle</v-icon
          >
          <v-icon v-else-if="isError" color="error">mdi-close-circle</v-icon>
        </template>
        <template #append-outer>
          <v-btn color="primary" @click="getMatchedItem">
            <v-icon left small> mdi-magnify </v-icon>
            {{ $t('company.autocomplete.button.search') }}
          </v-btn>
        </template>
      </v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" :disabled="isDisabled" @click="submitUser">
        {{ $t('company.autocomplete.button.add') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: 'AddContent',
  props: {
    addUserState: {
      Type: Object,
      required: true,
    },
    submitFunction: {
      Type: Function,
    },
  },
  data() {
    return {
      matchedItem: null,
      isVisitor: true,
      isError: false,
    }
  },
  computed: {
    matchedDisplayText() {
      return `${
        this.matchedItem
          ? this.matchedItem.name + ' ( ' + this.matchedItem.email + ' )'
          : ''
      } `
    },
    state: {
      get() {
        return this.addUserState
      },
      set(val) {
        this.$emit('update:addUserState', val)
      },
    },
    isDisabled() {
      return this.matchedItem === null
    },
    rules() {
      return [
        (value) =>
          !!value || this.$t('company.validation.this_field_is_required'),
        (value) => {
          const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          return re.test(value) || this.$t('system.createUser.invalid_email')
        },
      ]
    },
  },
  methods: {
    getMatchedItem() {
      if (this.state.input.length === 0) {
        this.state.errMsg = this.$t(
          'company.validation.please_input_value_to_search'
        )
        return
      }
      this.state.error = false

      this.state.isLoading = true
      this.isError = false
      this.$API.api.main.userInfo
        .getByEmail(this.state.input)
        .then((res) => res.data)
        .then((users) => {
          this.state.isLoading = false
          if (users.length === 0) {
            this.setAlert('error', this.$t('dialog.no_data'))
            this.matchedItem = null
            return
          } else {
            this.setAlert('success', this.$t('dialog.search_successfully'))
            this.matchedItem = users[0]
            return this.matchedItem
          }
        })
        .catch(() => {
          this.matchedItem = null
          this.isError = true
        })
        .finally(() => {
          this.state.isLoading = false
        })
    },
    async submitUser() {
      if (this.matchedItem === null) {
        this.state.error = true
        this.state.errMsg = this.$t(
          'company.validation.please_input_value_to_search'
        )
        return
      }
      this.state.error = false

      let userId = this.matchedItem.id
      let companyId = this.state.submitObject.id

      this.state.isLoading = true
      const doesSetEmployedBy = !this.isVisitor
      this.submitFunction(userId, companyId, doesSetEmployedBy)

      this.state.isLoading = false
      this.state.input = ''
      this.state.submitObject.id = ''
      this.state.submitObject.name = ''
      this.matchedItem = null
    },
  },
}
</script>
