<template>
    <transition name="fade" mode="out-in">
        <router-view class="child-view"></router-view>
    </transition>
</template>

<script>
export default {
    name:"Menu"
}
</script>