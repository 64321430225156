<template>
    <v-card outlined>
        <v-card-title>
        <v-icon style="margin-right: 10px">mdi-history</v-icon>
        <div class="text-capitalize">歷史資訊</div>
        <v-spacer></v-spacer>
        <!-- <filter-search-bar :search.sync="search" :filter.sync="dataState.filter" :fields="['name']"></filter-search-bar> -->
        </v-card-title>
        <online-user-history-grid></online-user-history-grid>
    </v-card>
</template>

<script>
import OnlineUserHistoryGrid from "@/components/online/OnlineUserHistoryGrid.vue"

export default {
    components:{
        OnlineUserHistoryGrid
    }

}
</script>