<template>
    <v-dialog v-model="dialog.value" max-width="750px" persistent scrollable>
        <!-- <template #activator="{ on }">
            <v-btn
                color="primary"
                dark
                v-on='on'
            >
                新增選項
            </v-btn>
        </template> -->
        <v-card>
            <template v-if="!dialog.loading">
            <v-card-title>
                <span class="headline">{{ title }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text >
                <v-container>
                    <v-row>
                        <v-col cols="12" align-self="center" class="d-flex align-center">
                            <v-switch class='mt-0 mr-5' :label='`icon網址`' v-model='editItemData.isExternalIcon' hide-details ></v-switch>
                            <sb-ui-iconPicker v-if='!editItemData.isExternalIcon' :card-width="260" :card-height="'auto'" :icon.sync='editItemData.icon'>
                                <template #activator="{ on:onMenu, icon:icon  }">
                                    <v-tooltip bottom> 
                                        <template #activator="{ on:onTooltip}">
                                            <v-btn outlined color='rgb(117, 117, 117)' class="icon-button--py-26 flex-grow-1 text-left justify-start" v-on="{...onTooltip, ...onMenu}">
                                                <v-icon left >{{editItemData.icon}}</v-icon>  
                                                <span class='ml-2'> ( {{displayedIconText}} ) </span>
                                            </v-btn>
                                        </template>
                                        <span>{{icon}}</span>
                                    </v-tooltip> 
                                </template>
                            </sb-ui-iconPicker>
                            <v-text-field
                                v-else
                                v-model="editItemData.src"
                                placeholder='icon網址'
                                label="icon網址 (src)"
                                hide-details
                                outlined
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="editItemData.text"
                                :label="$t('company.app.menu_setting.text')"
                                hide-details
                                outlined
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-autocomplete
                                v-model="editItemData.meta.type"
                                :items="['zone','project','company']"
                                :label="'類型'"
                                hide-details
                                outlined
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12">
                            <v-switch v-model='isGrouped' :label="'子標題'" hide-details class='mt-0 mb-3'></v-switch>
                            <v-row>
                                <v-col cols="12" sm="12"  v-if='isGrouped'>
                                    <v-text-field
                                        :disabled="!isGrouped"
                                        v-model="editItemData.header"
                                        :label="$t('company.app.menu_setting.title')"
                                        hide-details
                                        outlined
                                        class='pt-0 mt-0'
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col :cols='12'>
                            <v-switch class='mt-0 mb-3' :label='`站外連結`' v-model='editItemData.isExternalLink' hide-details ></v-switch>
                            <v-row>
                                <template v-if="!editItemData.isExternalLink" >
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="editItemData.name"
                                            type="string"
                                            placeholder='內部路由名稱'
                                            label="內部路由名稱"
                                            hide-details
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            placeholder='權限'
                                            v-model="editItemData.meta.functions"
                                            :items="functions"
                                            :menu-props="{ auto: false, overflowY: true }"
                                            hide-details
                                            outlined
                                            clearable
                                            multiple
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="editItemData.altNamePath"
                                            type="string"
                                            placeholder='替代連結'
                                            label="替代連結"
                                            hide-details
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                </template>
                                
                                <v-col v-else cols="12">
                                    <v-text-field
                                        v-model="editItemData.href"
                                        type="string"
                                        placeholder='網址'
                                        label="網址 ( href )"
                                        hide-details
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col :cols='12'>
                            <v-switch class='mt-0'  :label='"徽章"' v-model='editItemData.isLabeled' hide-details ></v-switch>
                            <v-row v-if='editItemData.isLabeled && editItemData.label'>
                                <v-col :cols='4' class='d-flex flex-column'>
                                    <v-checkbox
                                        v-model="editItemData.label['x-small']"
                                        label="小徽章"
                                        class='mt-0'
                                        hide-details
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="editItemData.label['outlined']"
                                        label="邊框樣式"
                                        class='mt-0'
                                        hide-details
                                    ></v-checkbox>
                                </v-col>
                                <v-col :cols='8'>
                                    <v-text-field
                                        v-model="editItemData.label.text"
                                        type="string"
                                        placeholder='徽章文字'
                                        label="徽章文字"
                                        hide-details
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-card flat class="mt-5 grey darken-1 pb-4">
                        <v-card-title dense class='pa-2'>
                            {{$t("company.app.menu_setting.preview")}}
                        </v-card-title>
                        <v-card outlined class="mx-auto" width="240">
                            <sb-vertical-nav-menu
                                :key="`${editItemData.icon}-${editItemData.name}-${editItemData.altNamePath}-${editItemData.href}-${editItemData.isLabeled}-${JSON.stringify(editItemData.label)}`"
                                class='pt-2'
                                :sidebarMenu="[editItemData]"
                                :isI18n="true"
                                :isDispalySubheader="true"
                                :inactive="true"
                            ></sb-vertical-nav-menu>
                        </v-card>
                    </v-card>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="darken-1" text @click="close">{{ $t("general.cancel") }}</v-btn>
                <v-btn
                color="primary"
                @click="editItemData && editItemData.id ?updateMenuItem():createMenuItem()"
                :disabled="this.dialog.loading"
                >{{ editItemData && editItemData.id ? $t("general.save") : $t("general.create") }}</v-btn>
            </v-card-actions>
            </template>
            <v-skeleton-loader v-else type="article"></v-skeleton-loader>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name:"MenuItemEditDialog",
    props:{
        editItem:{
            type: Object,
            // default(){
            //     return this.defaultEditItem
            // } 
        }
    },
    computed:{
        isGrouped:{
            get(){
                return typeof(this.editItemData.header)==="string"? true:false;
            },
            set(val){
                if(!val){
                    this.editItemData.header=null;
                }else{
                    this.editItemData.header='';
                }
            }
        },
        sortRules(){
            return [
                v=>{
                    return !isNaN(parseInt(v))?true:this.$t('company.companyAppMenuSetting.hint.please_input_number')
                },
                v=>{
                    return parseInt(v)>=0?true:this.$t('company.companyAppMenuSetting.hint.please_input_number_larger_than_zero')
                },
            ]
        },
        title() {
            return this.editItem && this.editItem.id
                ? "修改選項"
                : "新增選項";
        },
        displayedIconText(){
            return this.editItemData.icon?this.editItemData.icon.replace('mdi-',''):""
        }
    },
    data(){
        return {
        editItemData: JSON.parse(JSON.stringify( this.editItem )),
        tempIconObj:{src:null,icon:null},
        functions:[],
        dialog:{
            value:false,
            loading:false
        }
    }},
    methods:{
        close(){
            this.dialog.value = false;
        },
        toggle(){
            this.dialog.value = !this.dialog.value;
        },
        async updateMenuItem(){
            await this.emit('update', this.editItemData)
            this.dialog.value = false;
        },
        async createMenuItem(){
            await this.emit('create', this.editItemData);
            this.dialog.value = false;
        },
        emit (eventName, value) {
            return new Promise((resolve) => {
                this.$emit(eventName, value)
                this.$nextTick(resolve)
            })
        },
    },
    async created(){
        this.functions = await this.$API.api.main.Function.get().then(res=>res.data.map(m=>m.name)).catch(err=>{console.error('Error:',err)})
    },
    watch:{
        editItem(editItem){
            this.editItemData = JSON.parse(JSON.stringify(editItem))
            if(this.editItemData && this.editItemData.isExternalIcon){
                this.tempIconObj.src = this.editItemData.src;
            }else{
                this.tempIconObj.icon = this.editItemData.icon;
            }
        },
        async "editItemData.isLabeled"(isLabeled){
            await this.$nextTick()
            if(!isLabeled){
                this.$set(this.editItemData, "label",  {text:"text", outlined:true, ['x-small']:true})
            }
        },
        async "editItemData.isExternalIcon"(isExternalIcon){
            await this.$nextTick()
            if(isExternalIcon){
                this.$set(this.editItemData, "src", this.tempIconObj.src )
                this.$set(this.editItemData, "icon", null )
            }else if(isExternalIcon===false){
                this.$set(this.editItemData, "icon", this.tempIconObj.icon ||"mdi-file-outline" )
            }
        }
    }
}
</script>

<style scoped>
::v-deep .v-input--switch .v-input__control, ::v-deep .v-input--checkbox .v-input__control{
    flex-grow:0;
    width:auto;
}

.icon-button--py-26{
    padding-top:26px !important;
    padding-bottom:26px !important;
}
</style>