<template>
  <v-card outlined>
    <v-card-title>
      <v-icon style="margin-right: 10px">mdi-account-multiple-outline</v-icon>
      <div class="text-capitalize">線上使用者</div>
      <v-spacer></v-spacer>
      <!-- <filter-search-bar :search.sync="search" :filter.sync="dataState.filter" :fields="['name']"></filter-search-bar> -->
    </v-card-title>
    <online-users-grid></online-users-grid>
  </v-card>
</template>

<script>
// @ is an alias to /src
import OnlineUsersGrid from "@/components/online/OnlineUsersGrid.vue"

export default {
  // name: 'online-users',
  components: {
    OnlineUsersGrid
  }
}
</script>

<style>

</style>