<template>
  <v-text-field
    v-model.trim="searchText"
    class="text-capitalize my-0 pa-0 mr-2"
    :style="{maxWidth:'250px'}"
    outlined
    dense
    append-icon="mdi-magnify"
    hide-details
    :label="$t('general.search')"
    single-line
    @keyup.13="updateFilters"
    @click:append="updateFilters"
  ></v-text-field>
</template>

<script>
export default {
  name: "SearchBar",
  props: {
    filter: {
      Type: Object,
      required: true
    },
    fields: {
      Type: Array,
      required: true
    },
    refreshFunction: {
      Type: Function
    }
  },
  data(){
    return {
      searchText: ""
    }
  },
  computed: {
    gridFilter: {
      get() {
        return this.filter;
      },
      set(val) {
        this.$emit("update:filter", val);
      }
    },
    // searchText: {
    //   get() {
    //     return this.search;
    //   },
    //   set(val) {
    //     this.$emit("update:search", val);
    //   }
    // }
  },
  methods: {
    updateFilters() {
      let filters = [];

      this.fields.forEach((field) => {
        let searchText = this.searchText.trim();
        if (searchText.length !== 0) {
          let filterObj = {
            field: field,
            operator: "contains",
            value: this.searchText.trim()
          };
          filters.push(filterObj);
        }
      });

      this.gridFilter = {
        filters: [
          {
            logic: "or",
            filters: filters
          }
        ],
        logic: "or"
      };

      this.refreshFunction ? this.refreshFunction() : "";
      this.$emit("search");
    }
  }
};
</script>

<style>
</style>